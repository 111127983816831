import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { setupManagementUrl } from "../../../../config";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../../data/Axios/DynamicService";
import {
  allInsightType,
} from "../../../../data/Queries/SetupManagement/FinSightsQueries";
import DynamicForm from "../../../../data/Axios/DynamicForm";

const base_url = setupManagementUrl.uri;

const TillNumbersForm = (props) => {
  const [insightData, setInsightData] = useState([]);

  const initialValues = {
    id: props.data ? props.data.id : "",
    tillNumber: props.data ? props.data.tillNumber : "",
    name: props.data ? props.data.name : "",
    insightTypeId: props.data ? props.data.insightTypeId : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { tillNumber, name, insightTypeId } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            tillNumber,
            name,
            insightTypeId,
          }
        : {
            tillNumber,
            name,
            insightTypeId,
          };

      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateTillNumbers" : "createTillNumbers",
        mutationData,
        "tillNumber",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Till Number ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    async function fetchAllData() {
      try {
        //const tillResponse = await graphqlQuery(allTillnumbers, base_url);
        const insightResponse = await graphqlQuery(allInsightType, base_url);

        // if (tillResponse) {
        //   setTillData(tillResponse || []);
        // }

        if (insightResponse) {
          setInsightData(insightResponse || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchAllData();
  }, []);

  const insightTypeOptions = insightData
    ? insightData.map((data) => ({
        value: data.id,
        label: data.description,
      }))
    : [];

  const fields = [
    {
      name: "tillNumber",
      label: "Till Number *",
      type: "number",
      gridColumn: "span 2",
      required: true,
    },
    {
      name: "name",
      label: "Name *",
      type: "text",
      gridColumn: "span 2",
      required: true,
    },
    {
      name: "insightTypeId",
      label: "Insight Type*",
      type: "select",
      options: insightTypeOptions,
      gridColumn: "span 2",
      required: true,
    },
  ];

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Till Number" : "Create Till Number"}
      subtitle={
        props.isEditing
          ? "Edit an Existing Till Number"
          : "Create a New Till Number"
      }
    />
  );
};

export default TillNumbersForm;
