export const allTillnumbers = ` {
    tillNumbersNew {
        id
        tillNumber
        name
        insightTypeId
        insightType
        createdDate
        createdBy
        updatedDate
        updatedBy
    }
}
`;

export const allPayBills = `
 {
    paybills {
        id
        paybill
        name
        insightTypeId
      
    }
}
`;

export const newAllPaybills = `{
    payBillNew {
        id
        paybill
        name
        insightTypeId
        insightType
        createdDate
        createdBy
        updatedDate
        updatedBy
    }
}`;

export const allTransactionType = ` {
    transactionType {
        id
        description
      
    }
}

`;
export const allInsightType = ` {
    insightTypes {
        id
        description
    
    }
}
`;
export const allInsightKeyWords = ` {
    insightKeywords {
        id
        keyWord
        insightTypeId
     
    }
}`;

export const newAllInsightKeyWords = `
{
    insightKeywordNew {
        id
        keyword
        insightTypeId
        insightType
        createdDate
        createdBy
        updatedDate
        updatedBy
    }
}`;

export const allTransactionKeywords = ` {
    transactionKeywords {
        id
        keyWord
        regexpression
        transactionTypeId
     
    }
}`;

export const allFSTitles = `
{
    allTitles {
        id
        title
        
    }
}

`;

export const allStatements = `
{
    allStatements {
        id
        userId
        speedPlanId
        customerName
        customerMobile
        emailAddress
        statementPeriod
        requestDate
        summary
        createdDate
        totalWork
        workDone
    }
}
`;

export const allBankStatements = `
{
    bankStatements {
        id
        userId
        typeId
        speedPlanId
        bankId
        dynamicIdentifier
        summary
        createdDate
        createdBy
        updatedDate
        updatedBy
    }
}
`;
