import React from "react";
import { Box } from "@mui/material";

import { RoleGroupColumns } from "../../../data/DynamicTable/Collumns";
import { allBankStatements, allRoleGroups } from "../../../data/Axios/queries";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { reportManagementUrl, roleManagemenUrl } from "../../../config";

import UploadStatementForm from "./form";
import { allStatements } from "../../../data/Queries/SetupManagement/FinSightsQueries";
import {
  allStatementsColumns,
  bankStatementsColumns,
} from "../../../data/Columns/FSColumns";
import UploadBankStatementForm from "./Bankform";

const base_url = reportManagementUrl.uri;

const UploadBankStatement = () => {
  const actions = {
    entity: "Upload Bank Statements",
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: false,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Upload Statement",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: false,
      permission: "ActivatePermission",
    },
    viewBankReport: {
      key: "MARK_TASK_DONE",
      button_name: "View Report",
      Show_Button: true,
      permission: "hasNoPermission",
      roleGroupId: [1, 2, 3, 4, 5],
    },
    isUploadingData: true,
    showStatus: true,
  };

  return (
    <Box m="20px">
      <DynamicTable
        title="Upload Bank Statement Management"
        subtitle="Upload or remove statements "
        columns={bankStatementsColumns}
        FormComponent={UploadBankStatementForm}
        query={allBankStatements}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default UploadBankStatement;
