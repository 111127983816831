import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { PulseLoader } from "react-spinners"; // Importing the loader
import DynamicTable from "../../data/DynamicTable/dynamicTable";
import { allCasesDynamic, allCasesView } from "../../data/Axios/queries";
import { caseCategory } from "../../data/Axios/queries";
import { caseManagementUrl } from "../../config";
import CasesForm from "./Form";
import { CasesColumns } from "../../data/DynamicTable/Collumns";
import DynamicEditableTable from "../../data/DynamicTable/EditableDataGrid";

const base_url = caseManagementUrl.uri;
const actions = {
  entity: "Cases",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "File New Case",
    Show_Button: true,
    permission: "CreatePermission",
    inputObjectName: "createCaseDynamic",
    InputObject: "newCase",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
    inputObjectName: "acivateDeactivateCase",
  },
  ViewCase: {
    key: "VIEWDETAILS",
    button_name: "View Details",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  updateCaseStatus: {
    key: "UPDATECASESTATUS",
    button_name: "Update Case Status",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  modelName: "Cases",
  showStatus: true,
};

function Cases() {
  




  return (
    <Box m="2px" p="1rem">
      {/* {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <PulseLoader size={15} color={"#3f51b5"} />
        </Box>
      ) : ( */}
        <DynamicTable
          title="Case Management"
          subtitle="View all cases, create edit or view details"
          columns={CasesColumns}
          FormComponent={CasesForm}
          query={allCasesView}
          base_url={base_url}
          actions={actions}
        />
      {/* //)} */}
    </Box>
  );
}

export default Cases;
