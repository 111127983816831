import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  List,
  ListItemButton,
  ListItemText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";
import { tokens } from "../../../../theme";
import { fetchDataEngine } from "../../../../data/Axios/DynamicService";
import { caseManagementUrl } from "../../../../config";
import MainCard from "../../../../components/MainCard";
import AnalyticsCard from "../../../../components/AnalyticsCard";
import PieChart from "../../../../components/PieChart";
import { format } from "date-fns";

const base_url = caseManagementUrl.uri;
// const company_url = companyManagementUrl.uri;

export default function CaseDashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [team, setTeam] = useState([]);
  const [caseData, setCaseData] = useState([]);
  const [cards, setCards] = useState([]);


  const [loading, setLoading] = useState(true);

  const [progress, setProgress] = useState(90);

  const Item = styled(Box)(({ theme }) => ({
    backgroundColor: `${colors.primary[400]}}`,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
  }));

  async function fetchData() {
    try {
      const returnFields = [
        "numberOfTasks",
        "numberOfDocuments",
        "tasksPending",
        "tasksInProgress",
        "tasksOnHold",
        "tasksCompleted",
        "tasksCancelled",
        "tasksFailed",
      ];

      const inputFields = {
        caseId: caseId,
      };
      const Facts = await fetchDataEngine(
        "caseSummary",
        "case",
        inputFields,
        returnFields,
        base_url
      );

      setData(Facts);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchUpcomingCaseEvents = async () => {
    try {
      const returnFields = ["eventDate", "eventType", "description"];

      const inputFields = {
        caseId: caseId,
      };

      const upcoming = await fetchDataEngine(
        "allCaseEventsByColumnValues",
        "caseEvent",
        inputFields,
        returnFields,
        base_url
      );

      setEvents(upcoming);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpcomingCaseEvents();
  }, []);

  async function fetchData() {
    try {
      const returnFields = [
        "id",
        "username",
        "statusFlag",
        "assignedDate",
        "firstName",
        "lastName",
        "email",
      ];
      const inputFields = {
        caseId: caseId,
      };
      const TeamAssignmentsData = await fetchDataEngine(
        "teamAssignmentViewsByColumnValues",
        "viewModel",
        inputFields,
        returnFields,
        base_url
      );
      setTeam(TeamAssignmentsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchCaseData() {
    try {
      const returnFields = [
        "id",
        "description",
        "caseNumber",
        "title",
        "createdDate",
        "caseCategory",
        "summary",
      ];
      const inputFields = { id: caseId };
      const Case = await fetchDataEngine(
        "casebyId",
        "caseModel",
        inputFields,
        returnFields,
        base_url
      );
      setCaseData(Case);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchCaseData();
  }, []);

  async function fetchCaseSummaryData() {
    try {
      const returnFields = [
        "numberOfTasks",
        "numberOfDocuments",
        "tasksPending",
        "tasksInProgress",
        "tasksOnHold",
        "tasksCompleted",
        "tasksCancelled",
        "tasksFailed",
      ];
      const inputFields = { caseId: caseId };
      const Cards = await fetchDataEngine(
        "caseSummary",
        "case",
        inputFields,
        returnFields,
        base_url
      );
      setCards(Cards);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchCaseSummaryData();
  }, []);

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid
          item
          lg={6}
          md={6}
          xs={16}
          sx={{
            backgroundColor: colors.primary[400],
            border: `1px solid ${colors.grey[600]}`,
            borderRadius: "0.3rem",
            padding: "1rem",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "1rem", color: colors.grey[100] }}
            >
              All Case Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Item>
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    color={colors.greenAccent[400]}
                  >
                    Case Number
                  </Typography>
                  <Typography variant="body2">{caseData.caseNumber}</Typography>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Item>
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    color={colors.greenAccent[400]}
                  >
                    Case Title
                  </Typography>
                  <Typography variant="body2">{caseData.title}</Typography>
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Item>
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    color={colors.greenAccent[400]}
                  >
                    Description
                  </Typography>
                  <Typography variant="body2">
                    {caseData.description}
                  </Typography>
                </Item>
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
                <Item>
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    color={colors.greenAccent[400]}
                  >
                    Category
                  </Typography>
                  <Typography variant="body2">
                    {caseData.caseCategory}
                  </Typography>
                </Item>
              </Grid> */}
              {/* <Grid item xs={12} sm={6} md={6}>
                <Item>
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    color={colors.greenAccent[400]}
                  >
                    Summary
                  </Typography>
                  <Typography variant="body2">{caseData.summary}</Typography>
                </Item>
              </Grid> */}
              <Grid item xs={12} sm={6} md={6}>
                <Item>
                  <Typography
                    variant="h6"
                    fontWeight="500"
                    color={colors.greenAccent[400]}
                  >
                    Created Date
                  </Typography>
                  <Typography variant="body2">
                    {caseData.createdDate}
                    {/* {format(new Date(caseData.createdDate), "MM/dd/yyyy")} */}
                  </Typography>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sx={{
            backgroundColor: `${colors.primary[400]}}`,
            border: `1px solid ${colors.grey[600]}`,
            borderRadius: "0.3rem",
          }}
        >
          <Item>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ marginBottom: "0.5rem" }}
              >
                Case Insights
              </Typography>
              <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} sx={{ borderRadius: '2px', color: "yellowgreen" }}>
    <AnalyticsCard
        title="number Of Tasks"
        count={cards.numberOfTasks}
        percentage={-9.3}
        extra="35,000"
        isLoss={true}
    />


                  {/* <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="10px"
                    padding="20px"
                    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                  >
                    <StatBox
                      title={data.tasksPending}
                      subtitle="Open Tasks"
                      progress={`+${data.tasksInProgress || 0}%`}
                      // increase=
                      icon={<AssignmentTurnedInIcon sx={{ color: colors.greenAccent[400], fontSize: '26px' }} />}
                    />
                  </Box> */}
                </Grid>
                <Grid item xs={12} sm={6} md={6}
               sx
                >
                  <AnalyticsCard
                    title="Tasks in Progress"
                    count={cards.tasksInProgress}
                    percentage={59.3}
                    extra="1"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <AnalyticsCard
                    title="Pending Tasks"
                    count={cards.tasksPending}
                    percentage={59.3}
                    extra="4"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <AnalyticsCard
                    title="Recently Uploaded Documents"
                    count={cards.numberOfDocuments}
                    percentage={3}
                    extra="35"
                  />
                </Grid>
              </Grid>
            </Box>
          </Item>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Item>
            <Box
              sx={{
                flexGrow: 1,
                padding: "0.5rem",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    overflow="auto"
                    borderRadius="10px"
                  >
                    <Typography variant="h5" fontWeight="600">
                      Team Assignment
                    </Typography>

                    <Box>
                      <TableContainer
                        component={Paper}
                        sx={{ bgcolor: colors.primary[400] }}
                      >
                        <Table sx={{ bgcolor: colors.primary[400] }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>First Name</TableCell>
                              <TableCell>Last Name</TableCell>
                              <TableCell>Date</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!loading && team.length > 0 ? (
                              team.map((team, index) => (
                                <TableRow key={index}>
                                  <TableCell>{team.firstName}</TableCell>
                                  <TableCell>{team.lastName}</TableCell>
                                  <TableCell>
                                    {format(
                                      new Date(team.assignedDate),
                                      "MM/dd/yyyy"
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={2} align="center">
                                  {loading ? "Loading..." : "No events found"}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Item>
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <Item>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "0.5rem" }}
            >
              Upcoming events
            </Typography>
            <Box
              sx={{
                bgcolor: colors.primary[400],
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box>
                <TableContainer
                  component={Paper}
                  sx={{ bgcolor: colors.primary[400] }}
                >
                  <Table sx={{ bgcolor: colors.primary[400] }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>S/N</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {events.length > 0 ? (
                        events.map((event, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {format(new Date(event.eventDate), "MM/dd/yyyy")}
                            </TableCell>
                            <TableCell>{event.description}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            {loading ? "Loading..." : "No events found"}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Item>
        </Grid>
        {/* <Grid item lg={6} md={6} xs={12}>
          <Item>
            <Typography variant="h5" fontWeight="600">
              Task Breakdown
            </Typography>
            <Box>
              <MainCard content={false}>
                <Box sx={{ pb: 0 }}>
                  <List>
                    <ListItemButton divider>
                      <ListItemText primary="Team Assignment" />
                      <Typography variant="h5">
                        {data.numberOfDocuments}
                      </Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Task Allocation" />
                      <Typography variant="h5">{data.numberOfTasks}</Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Event Recording " />
                      <Typography variant="h5">{data.tasksOnHold}</Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Judge Assignment" />
                      <Typography variant="h5">
                        {data.numberOfDocuments}
                      </Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Fact recording" />
                      <Typography variant="h5">
                        {data.tasksCompleted}
                      </Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Court Assignment" />
                      <Typography variant="h5">{data.numberOfTasks}</Typography>
                    </ListItemButton>
                    <ListItemButton divider>
                      <ListItemText primary="Document Upload" />
                      <Typography variant="h5">{data.tasksPending}</Typography>
                    </ListItemButton>
                  </List>
                </Box>
              </MainCard>
            </Box>
          </Item>
        </Grid> */}
      </Grid>
    </>
  );
}
