import { Box } from "@mui/material";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { caseManagementUrl } from "../../../../config";
import CasesForm from "./Form";
import { TeamAssignmentsColumns } from "../../../../data/DynamicTable/Collumns";
import { useEffect, useState } from "react";
import { fetchDataEngine } from "../../../../data/Axios/DynamicService";
import TeamAssignmentForm from "./Form";
import { PulseLoader } from "react-spinners";
import { allTeamAssignment } from "../../../../data/Axios/queries";
import DynamicEditableTable from "../../../../data/DynamicTable/EditableDataGrid";

const base_url = caseManagementUrl.uri;
const actions = {
  entity: "Team Assignments",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
    inputObjectName: "updateCaseDynamic",
    InputObject: "newCase",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
    inputObjectName: "createCaseDynamic",
    InputObject: "newCase",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  ViewCase: {
    key: "VIEWDETAILS",
    button_name: "View Details",
    Show_Button: false,
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
  showStatus: true,
  dataObject: {
    inputObjectName: "teamAssignmentViewsByColumnValues",
    inputSubObjectName: "viewModel",
    returnFields: [
      "id",
      "username",
      "statusFlag",
      "assignedDate",
      "firstName",
      "lastName",
      "email",
    ],
  },
};
function TeamAssignments() {
  // const caseId = JSON.parse(localStorage.getItem("CaseId"));
  // const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(true);

  // async function fetchData() {
  //   try {
  //     const returnFields = [
  //       "id",
  //       "username",
  //       "statusFlag",
  //       "assignedDate",
  //       "firstName",
  //       "lastName",
  //       "email",
  //     ]
  //     const inputFields = {
  //       caseId: caseId,
  //     };
  //     const TeamAssignmentsData = await fetchDataEngine(
  //       "teamAssignmentViewsByColumnValues",
  //       "viewModel",
  //       inputFields,
  //       returnFields,
  //       base_url
  //     );
  //     setData(TeamAssignmentsData);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <Box m="2px">
      <>
       
          <DynamicTable
            title="Team Assignments"
            subtitle="View all TeamAssignments, create or edit "
            columns={TeamAssignmentsColumns}
            FormComponent={TeamAssignmentForm}
      
            actions={actions}
            base_url={base_url}
         
          />
     
      </>
    </Box>
  );
}


export default TeamAssignments;