import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {
  CREATE_EVENT_TYPE,
  UPDATE_EVENT_TYPE,
  ALL_EVENT_TYPES,
} from "../../../../data/SetupManagementData";

const EventForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  
  const {
    loading: eventTypeLoading,
    error: eventTypeError,
    data: data,
  } = useQuery(ALL_EVENT_TYPES);

  const [eventTypeChosen, setEventType] = useState("");

  const [createEventType] = useMutation(CREATE_EVENT_TYPE, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal("Success!", "Event Type has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create event type, try again later", "error");
    },
  });

  const [updateEventType] = useMutation(UPDATE_EVENT_TYPE, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal("Success!", "Event Type has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update event type, try again later", "error");
    },
  });

  const initialValues = {
    eventType: props.data ? props.data.eventType : "",
    // companyId: props.data ? props.data.companyId : "",
    // company: props.data ? props.data.company : "",
  };

  const validationSchema = yup.object().shape({
    eventType: yup.string().required("Event Type is required"),
    // companyId: yup.number().required("Company ID is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { eventType} = values;

      if (props.isEditing) {
        await updateEventType({
          variables: {
            id: props.data.id,
            eventType,
            updatedBy: userId,
            // companyId,
            // company,
          },
        });
        console.log("Event Type updated successfully!");
      } else {
        await createEventType({
          variables: {
            eventType,
            createdBy: userId,
            // companyId,
            // company,
          },
        });
        console.log("Event Type created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Event Type" : "Create Event Type"}
        subtitle={
          props.isEditing
            ? "Edit an Existing Event Type"
            : "Create a New Event Type"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Event Type *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.eventType}
                name="eventType"
                error={touched.eventType && !!errors.eventType}
                helperText={touched.eventType && errors.eventType}
              />

              {/* <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Company ID"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyId}
                name="companyId"
                error={touched.companyId && !!errors.companyId}
                helperText={touched.companyId && errors.companyId}
              /> */}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default EventForm;
