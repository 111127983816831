import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { companyManagementUrl } from "../../../config";
import LogoUploadForm from "./Form";
import { useEffect, useState } from "react";

import { PulseLoader } from "react-spinners";
import {
  CompanyColumns,
  RegionsColumns,
} from "../../../data/DynamicTable/CompanyManagementColumns";
import { allCompanies, allRegions } from "../../../data/Axios/queries";
import RegionForm from "../Regions/form";

const actions = {
  entity: "Upload Logo",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
};
function UploadLogoTable() {
  return (
    <Box m="0.1rem" p="1rem">
      <LogoUploadForm />
      {/* <>
        <DynamicTable
          title="Region"
          subtitle="Create, view or edit account Region"
          columns={RegionsColumns}
          // FormComponent={RegionForm}
          query={allRegions}
          base_url={base_url}
          actions={actions}
        />
      </> */}
    </Box>
  );
}

export default UploadLogoTable;
