import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { allCorporateClients } from "../../../data/Axios/queries";
import { clientManagementUrl } from "../../../config";
import { CorporateClientsColumns } from "../../../data/DynamicTable/Collumns";
import CorporateClientsForm from "./form";


const base_url = clientManagementUrl.uri;
const actions = {
  entity: "Corporate Clients",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },

  ViewCorporateClientCases: {
    key: "ViewCases",
    button_name: "ViewCorporateClientCases",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",

    inputObjectName: "bulkActivateDeactivateCorporateClients",
  },

  dataObject: {
    inputFields: {
      isActive: null,
    },
    inputObjectName: "allCorporateClients",
    inputSubObjectName: "clientModel",
    returnFields: [
      "id",
      "contactNumber",
      "email",
      "address",
      "dateAdded",
      "notes",
      "companyName",
      "registrationNumber",
      "industrySector",
      "contactPersonName",
      "contactPersonPosition",
      "contactPersonEmail",
      "contactPersonPhone",
      "companyAddress",
      "billingAddress",
      "incorporationDate",
      "legalStructure",
      "countryOfIncorporation",
      "taxIdentificationNumber",
      "authorizedSignatoryName",
      "authorizedSignatoryPosition",
      "authorizedSignatoryEmail",
      "authorizedSignatoryPhone",
      "isActive",
      "statusFlag",
      "createdBy",
      "createdDate",
      "updatedBy",
      "updatedDate",
      "companyId",
      "company",
      "regionId",
      "branchId",
    ],
  },
};
function CorporateClients() {
  return (
    <Box m="2px">

      <DynamicTable
        title="Corporate Clients"
        subtitle="View all Corporate Clients, create edit or view details"
        columns={CorporateClientsColumns}
        FormComponent={CorporateClientsForm}
        //query={allCorporateClients}
        base_url={base_url}
        actions={actions}
        //entity="Corporate Clients"
      />
    </Box>
  );
}

export default CorporateClients;
