import { Box } from "@mui/material";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { caseManagementUrl } from "../../../../config";
import CasesForm from "./Form";
import {
  CaseTasksColumns,
  FactColumns,
  TeamAssignmentsColumns,
} from "../../../../data/DynamicTable/Collumns";
import { useEffect, useState } from "react";
import { fetchDataEngine } from "../../../../data/Axios/DynamicService";
import TeamAssignmentForm from "./Form";
import TaskForm from "./Form";
import { PulseLoader } from "react-spinners";
import { caseTasks } from "../../../../data/Axios/queries";

const base_url = caseManagementUrl.uri;
const actions = {
  entity: "Task Assignments",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },

  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  markTask: {
    key: "MARK_TASK_DONE",
    button_name: "Mark Task Done",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  showStatus: true,
  dataObject: {
    inputObjectName: "allCaseTasksByColumnValues",
    inputSubObjectName: "caseTask",
    returnFields: [
      "id",
      "caseId",
      "asigneeId",
      "description",
      "statusFlag",
      "done",
      "dueDate",
    ],
  },
};
function CaseTasks() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Case Tasks"
          subtitle="View all tasks related to a case, assign team a task"
          columns={CaseTasksColumns}
          FormComponent={TaskForm}
          actions={actions}
          base_url={base_url}
        />
      </>
    </Box>
  );
}

export default CaseTasks;
