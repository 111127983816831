import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { allIndividualClients } from "../../../data/Axios/queries";
import { clientManagementUrl } from "../../../config";
import IndividualClientsForm from "./form";
import { IndividualClientsColumns } from "../../../data/DynamicTable/Collumns";
const base_url = clientManagementUrl.uri;
const actions = {
  entity: "Individual Clients",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
    inputObjectName: "bulkActivateDeactivateIndividualClients",
  },

  dataObject: {
    inputFields: {
      isActive: null,
    },
    inputObjectName: "allIndividualClients",
    inputSubObjectName: "clientModel",
    returnFields: [
      "id",
      "firstName",
      "lastName",
      "dateOfBirth",
      "genderId",
      "gender",
      "nationality",
      "identificationType",
      "identificationNumber",
      "contactNumber",
      "email",
      "address",
      "occupation",
      "employer",
      "emergencyContactName",
      "emergencyContactNumber",
      "relationshipWithEmergencyContact",
      "dateAdded",
      "notes",
      "isActive",
      "statusFlag",
      "createdBy",
      "createdDate",
      "updatedBy",
      "updatedDate",
      "companyId",
      "regionId",
      "branchId",
      "company",
    ],
  },
};
function IndividualClients() {
  return (
    <Box m="2px">
      <DynamicTable
        title="Individual Clients"
        subtitle="View all Individual Clients, create edit or view details"
        columns={IndividualClientsColumns}
        FormComponent={IndividualClientsForm}
     //   query={allIndividualClients}
        base_url={base_url}
        actions={actions}
        entity={"Individual Clients"}
      />
    </Box>
  );
}

export default IndividualClients;
