import {
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  Badge,
  Stack,
  Popover,
  List,
  ListItem,
  Typography,
  Divider,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useNavigate } from "react-router-dom";
import { graphqlMutation, graphqlQuery } from "../../data/Axios/DynamicService";
import { notificationservice } from "../../config";
import { notificationsAndCount } from "../../data/Axios/queries";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Money } from "@mui/icons-material";
import { BalanceTopup } from "../../data/DynamicTable/Modal";
const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [balance, setBalanceData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [notifications, setNotifications] = useState({
    notifications: [],
    count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const roleGroupId = parseInt(decodedToken.RoleGroupId);
  useEffect(() => {
    let isMounted = true;
    async function fetchAllData() {
      try {
        const notificationsResponse = await graphqlQuery(
          notificationsAndCount,
          notificationservice.uri
        );
        if (isMounted && notificationsResponse) {
          setNotifications(notificationsResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    }
    fetchAllData();
    return () => {
      isMounted = false;
    };
  }, []);

  const fetchData = async () => {
    try {
      const notificationsResponse = await graphqlMutation(
        `query AccountBalance {
    accountBalance
}
`,
        notificationservice.uri
      );
      if (notificationsResponse) {
        setBalanceData(notificationsResponse);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    let isMounted = true;
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);
  const handleLogout = () => {
    // Clear localStorage
    localStorage.clear();
    // Clear sessionStorage
    // sessionStorage.clear();
    // Clear cache (if necessary)
    if (caches) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }

    // Navigate to the login page
    navigate("/login");
  };

  const handleNotificationClick = async (event) => {
    const newAnchorEl = anchorEl ? null : event.currentTarget;
    setAnchorEl(newAnchorEl);

    if (!anchorEl) {
      try {
        // Call the mutation to mark all notifications as read
        const result = await graphqlMutation(
          `mutation MarkNotificationsRead {
            markNotificationsRead {
              notificationId
              userId
              notificationType
              body
              templateId
              isRead
              createdDate
              companyId
              company
            }
          }`,
          notificationservice.uri
        );

        console.log("Mutation Result:", result);

        // Update the notifications state
        setNotifications((prevNotifications) => ({
          ...prevNotifications,
          notifications: prevNotifications.notifications.map(
            (notification) => ({ ...notification, isRead: true })
          ),
          count: 0,
        }));
      } catch (error) {
        console.error("Error marking notifications as read:", error);
        console.log(
          "Detailed Error Info:",
          error.response?.data || error.message
        );
      }
    }
  };
  const handleMcbSwitch = () => {
    navigate("/MCB-Switch");
  };
  const handlePersonDetailsOpen = () => {
    navigate("/profile");
    setIsProfileOpen(true);
  };

  const handlePersonDetailsClose = () => {
    navigate("/super-admin-dashboard");
    setIsProfileOpen(false);
  };

  const handleIconClick = () => {
    if (isProfileOpen) {
      handlePersonDetailsClose();
    } else {
      handlePersonDetailsOpen();
    }
  };

  const handleModalOpen = () => {
    setDialogOpen(true);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      display="flex"
      justifyContent={isSmallScreen ? "space-evenly" : "space-between"}
      p={2}
    >
      <Box display="flex" backgroundColor={colors.grey[800]} borderRadius="3px">
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" /> */}
        {/* <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>
      <Stack spacing={2} direction="row">
        <Tooltip title="Change Theme">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>

        <Tooltip title="Notifications">
          <IconButton aria-describedby={id} onClick={handleNotificationClick}>
            <Badge
              badgeContent={open ? 0 : notifications.count}
              color="secondary"
            >
              <NotificationsOutlinedIcon color="action" />
            </Badge>
          </IconButton>
        </Tooltip>

        <Tooltip title="Logout">
          <IconButton onClick={handleLogout}>
            <LogoutOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Account Balance">
          <IconButton onClick={handleModalOpen}>
            Balance: Ksh. {balance.accountBalance}
            {dialogOpen && (
              <BalanceTopup
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onAction={() => setRefresh(true)}
              />
            )}
          </IconButton>
        </Tooltip>
        {roleGroupId === 1 && (
          <Tooltip title="MCB Switch">
            <IconButton onClick={handleMcbSwitch}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Profile">
          <IconButton onClick={handleIconClick}>
            <PersonOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          p={2}
          sx={{
            width: "300px",
            borderRadius: "0.75rem",
            bgcolor:
              theme.palette.mode === "light"
                ? colors.blueAccent[900]
                : colors.grey[600],
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              color: colors.greenAccent[400],
            }}
            variant="h6"
          >
            Notifications
          </Typography>
          <List>
            {notifications.notifications?.map((notification) => (
              <div key={notification.notificationId}>
                <ListItem>
                  <ListItemText primary={notification.body} />
                </ListItem>
                <Divider
                  sx={{
                    color: colors.redAccent[400],
                  }}
                />
              </div>
            ))}
          </List>
        </Box>
      </Popover>
    </Box>
  );
};

export default Topbar;
