import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {
  ALL_APP_NOTIFICATION_TEMPLATES,
  CREATE_APP_NOTIFICATION_TEMPLATE,
  UPDATE_APP_NOTIFICATION_TEMPLATE,
} from "../../../../data/SetupManagementData";

const AppNotifTemplateForm = (props) => {
  const { loading, error } = useQuery(ALL_APP_NOTIFICATION_TEMPLATES);

  const [createAppNotifTemplate] = useMutation(
    CREATE_APP_NOTIFICATION_TEMPLATE,
    {
      onCompleted: () => {
        props.onClose();
        props.onAction();

        swal(
          "Success!",
          "App Notification Template created successfully.",
          "success"
        );
      },
      onError: () => {
        swal(
          "Error!",
          "Unable to create App Notification Template. Please try again later.",
          "error"
        );
      },
    }
  );

  const [updateAppNotifTemplate] = useMutation(
    UPDATE_APP_NOTIFICATION_TEMPLATE,
    {
      onCompleted: () => {
        props.onClose();
        props.onAction();

        swal(
          "Success!",
          "App Notification Template updated successfully.",
          "success"
        );
      },
      onError: () => {
        swal(
          "Error!",
          "Unable to update App Notification Template. Please try again later.",
          "error"
        );
      },
    }
  );

  const initialValues = {
    notificationType: props.data ? props.data.notificationType : "",
    messageTemplate: props.data ? props.data.messageTemplate : "",
  };

  const validationSchema = yup.object().shape({
    notificationType: yup.string().required("Notification Type is required"),
    messageTemplate: yup.string().required("Message Template is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { notificationType, messageTemplate } = values;

      if (props.isEditing) {
        await updateAppNotifTemplate({
          variables: {
            id: props.data.id,
            notificationType,
            messageTemplate,
          },
        });
        console.log("App Notification Template updated successfully!");
      } else {
        await createAppNotifTemplate({
          variables: {
            notificationType,
            messageTemplate,
          },
        });
        console.log("App Notification Template created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={
          props.isEditing
            ? "Edit App Notification Template"
            : "Create App Notification Template"
        }
        subtitle={
          props.isEditing
            ? "Edit an Existing App Notification Template"
            : "Create a New App Notification Template"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Notification Type *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.notificationType}
                name="notificationType"
                error={touched.notificationType && !!errors.notificationType}
                helperText={touched.notificationType && errors.notificationType}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Message Template *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.messageTemplate}
                name="messageTemplate"
                error={touched.messageTemplate && !!errors.messageTemplate}
                helperText={touched.messageTemplate && errors.messageTemplate}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default AppNotifTemplateForm;
