import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { TitlesColumns } from "../../../../data/Columns/SystemSetupColumns";
import {
  InsightTypeColumns,
  TitleFSColumns,
} from "../../../../data/Columns/FSColumns";
import {
  allFSTitles,
  allInsightType,
  newAllInsightKeyWords,
} from "../../../../data/Queries/SetupManagement/FinSightsQueries";
import InsightTypeForm from "./form";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Insight Types",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },

  showStatus: true,
};
function InsightTypeTable() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="All Insight Types"
          subtitle="View, create or edit Insight Types "
          columns={InsightTypeColumns}
          FormComponent={InsightTypeForm}
          query={allInsightType}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default InsightTypeTable;
