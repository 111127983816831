import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { TitlesColumns } from "../../../../data/Columns/SystemSetupColumns";
import { TitleFSColumns, TransactionKeywordsColumns, TransactionTypeColumns } from "../../../../data/Columns/FSColumns";
import { allFSTitles, allTransactionType } from "../../../../data/Queries/SetupManagement/FinSightsQueries";
import TransactionTypeForm from "./form";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Transaction Types",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  showStatus: true,
};
function TransactionTypeTable() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="All Transaction Types"
          subtitle="View, create or edit Transaction Types "
          columns={TransactionTypeColumns}
          FormComponent={TransactionTypeForm}
          query={allTransactionType}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default TransactionTypeTable;
