import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { PulseLoader } from "react-spinners"; // Importing the loader
import { allSubRights } from "../../../data/Axios/queries";
import { roleManagemenUrl } from "../../../config";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { subrightColumns } from "../../../data/DynamicTable/CompanyManagementColumns";
import SubRightsForm from "./form";
const base_url = roleManagemenUrl.uri;
const actions = {
  entity: "SubRight",
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
    inputObjectName: "createCaseDynamic",
    InputObject: "newCase",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    inputObjectName: "bulkActivateDeactivateSubRightsFn",
    Show_Button: true,
    permission: "ActivatePermission",
  },
  ViewCase: {
    key: "VIEWDETAILS",
    button_name: "View Details",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
};

function SubRightTable() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Box m="2px">
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <PulseLoader size={15} color={"#3f51b5"} />
        </Box>
      ) : (
        <DynamicTable
          title="Subright Table"
          subtitle="View all Subrights, create edit or view"
          columns={subrightColumns}
          FormComponent={SubRightsForm}
          query={allSubRights}
          base_url={base_url}
          actions={actions}
        />
      )}
    </Box>
  );
}

export default SubRightTable;
