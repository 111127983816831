import { Box } from "@mui/material";

import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { AccountsandFinanceurl, setupManagementUrl} from "../../../../config";
import { AccountTypesColumns } from "../../../../data/DynamicTable/Collumns";
import {
  allAccountCategories,
  allAccountTypes,
  allAccounts,
  allBanks,
  allBanksTransactionTypes,
  allPaymentTypes,
} from "../../../../data/Axios/queries";
import {
  AccountCategoriesColumns,
  AccountSubCategoriesColumns,
  AccountsColumns,
  BanksColumns,
  BankstatementstypesColumns,
  PaymentTypesColumns,
} from "../../../../data/DynamicTable/AccountsColumns";
import AccountCategoryForm from "./form";
import AccountsForm from "./form";
import PaymentTypeForm from "./form";
import BankTransactionTypesForm from "./form";
const base_url = setupManagementUrl.uri;
const actions = {
  entity: "BankTransactionTypes",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  showStatus: true,
};
function BankTransactionTypesTable() {
  return (
    <Box m="2px">
      <DynamicTable
        title="Bank Transaction Types"
        subtitle="Create, view or edit bank Transaction Types
        "
        columns={BankstatementstypesColumns}
        FormComponent={BankTransactionTypesForm}
        query={allBanksTransactionTypes}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
}

export default BankTransactionTypesTable;
