import React from "react";
import { Box } from "@mui/material";

import { RoleGroupColumns } from "../../../data/DynamicTable/Collumns";
import { allRoleGroups } from "../../../data/Axios/queries";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { roleManagemenUrl } from "../../../config";
import RoleGroupForm from "./Forms";
import Filter from "./Filter";

const base_url = roleManagemenUrl.uri;

const ReportBrowser = () => {
  const actions = {
    entity: "Role Group",
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: true,
      permission: "ActivatePermission",
    },
    
  };

  return (
    <Box m="10px">
      <Filter />
      {/* <DynamicTable
        title="Reports"
        subtitle="Reports Browser"
        columns={RoleGroupColumns}
        FormComponent={RoleGroupForm}
        query={allRoleGroups}
        base_url={base_url}
        actions={actions}
      /> */}
    </Box>
  );
};

export default ReportBrowser;
