import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { CircularProgress, useTheme } from "@mui/material";
import { fetchDataEngine, graphqlQuery } from "../data/Axios/DynamicService";
import { companyManagementUrl, reportManagementUrl } from "../config";
import { getStorageData } from "../data/Axios/queries";

const PieChart = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const RoleId = parseInt(decodedToken.RoleGroupId);
  const base_url = reportManagementUrl.uri;

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        setLoading(true); // Ensure loading state is set to true while data is being fetched

        const recentTaskFields = ["id", "label", "value", "color"];
        const recentTaskInputFields = { roleGroupId: RoleId, userId };
        const recentTasks = await fetchDataEngine(
          "recentStatementsCountsPieChart",
          "functionParams",
          recentTaskInputFields,
          recentTaskFields,
          base_url
        );

        setData(recentTasks); // Update the state with the transformed data
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Also set loading to false in case of an error
        setError(error); // Optional: You may also want to handle the error state
      }
    };

    fetchSummaryData();
  }, []);

  if (loading)
    return (
      <p>
        <CircularProgress />
      </p>
    );
  if (error) return <p>Error loading data</p>;

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      colors={{ datum: "data.color" }} // Use the color property from the data
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "ruby",
          },
          id: "dots",
        },
        {
          match: {
            id: "c",
          },
          id: "dots",
        },
        {
          match: {
            id: "go",
          },
          id: "dots",
        },
        {
          match: {
            id: "python",
          },
          id: "dots",
        },
        {
          match: {
            id: "scala",
          },
          id: "lines",
        },
        {
          match: {
            id: "lisp",
          },
          id: "lines",
        },
        {
          match: {
            id: "elixir",
          },
          id: "lines",
        },
        {
          match: {
            id: "javascript",
          },
          id: "lines",
        },
      ]}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 14,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: colors.greenAccent[400],
              },
            },
          ],
        },
      ]}
      tooltip={({ datum }) => (
        <div style={{ padding: "12px 16px", color: colors.blueAccent }}>
          <strong>{datum.id}</strong>
        </div>
      )}
    />
  );
};

export default PieChart;
