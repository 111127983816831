import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { TitlesColumns } from "../../../../data/Columns/SystemSetupColumns";
import {
  TillNumbersColumns,
  TitleFSColumns,
} from "../../../../data/Columns/FSColumns";
import {
  allFSTitles,
  allTillnumbers,
} from "../../../../data/Queries/SetupManagement/FinSightsQueries";
import TillNumbersForm from "./form";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Till Numbers",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  showStatus: true,
};
function TillNumbersTable() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="All Till Numbers"
          subtitle="View, create or edit Till Numbers "
          columns={TillNumbersColumns}
          FormComponent={TillNumbersForm}
          query={allTillnumbers}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default TillNumbersTable;
