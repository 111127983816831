import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import DynamicForm from "../../../data/Axios/DynamicForm";
import Swal from "sweetalert2";
import {
  allReportMinorClassifications,
  allReportsMajorClassifications,
} from "../../../data/Axios/queries";
import { setupManagementUrl } from "../../../config";
import { generateAndExecuteMutation } from "../../../data/Axios/DynamicService";
import { graphqlQuery } from "../../../data/Axios/DynamicService";

const ReportTypesForm = (props) => {
  const base_url = setupManagementUrl.uri;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [filteredMinorOptions, setFilteredMinorOptions] = useState([]);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const Response = await graphqlQuery(
          allReportMinorClassifications,
          setupManagementUrl.uri
        );
        if (Response) {
          setData(Response);
        }
        const MajorResponse = await graphqlQuery(
          allReportsMajorClassifications,
          setupManagementUrl.uri
        );
        if (MajorResponse) {
          setMajor(MajorResponse);
        }
        const MinorResponse = await graphqlQuery(
          allReportMinorClassifications,
          setupManagementUrl.uri
        );
        if (MinorResponse) {
          setMinor(MinorResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);

  const majorOptions = major
    ? major.map((item) => ({
        parent_key: item.id,
        value: item.id,
        label: item.majorClassification,
      }))
    : [];

  const minorOptions = minor
    ? minor.map((item) => ({
        parent_key: item.majorClassificationID,
        value: { id: item.id, minorClassification: item.minorClassification },
        label: item.minorClassification,
      }))
    : [];

  const fields = [
    {
      name: "majorClassificationID",
      label: "Major Classification",
      type: "select",
      options: majorOptions,
      required: true,
      parent: "main",
      gridColumn: "span 2",
    },
    {
      name: "minorClassificationID",
      label: "Minor Classification",
      type: "select",
      options: filteredMinorOptions,
      required: true,
      gridColumn: "span 2",
    },
    {
      name: "reportName",
      label: "Report Name",
      type: "text",
      required: true,
      gridColumn: "span 2",
    },
  ];

  const initialValues = {
    majorClassificationID: props.data ? props.data.majorClassificationID : "",
    minorClassificationID: props.data ? props.data.minorClassificationID : "",
    reportName: props.data ? props.data.reportName : "",
  };

  const onFieldChange = async (field, value) => {
    if (field.name === "majorClassificationID") {
      // Filter minor options based on the selected major classification ID
      const filteredOptions = minor
        .filter((item) => item.majorClassificationID === value)
        .map((item) => ({
          parent_key: item.majorClassificationID,
          value: item.id,
          label: item.minorClassification,
        }));
      setFilteredMinorOptions(filteredOptions);
    }
    return {};
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { majorClassificationID, minorClassificationID, reportName } =
        values;

      const majorClassification =
        major.find((item) => item.id === majorClassificationID)
          ?.majorClassification || "";
      const minorClassification =
        minor.find((item) => item.id === minorClassificationID)
          ?.minorClassification || "";

      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            majorClassificationID: props.data.majorClassificationID,
            minorClassificationID: props.data.minorClassificationID,
            reportName,
            majorClassification,
            minorClassification,
          }
        : {
            majorClassificationID,
            minorClassificationID,
            reportName,
            majorClassification,
            minorClassification,
          };
      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateReportTypes" : "createReportTypes",
        mutationData,
        props.isEditing ? "reportType" : "reportType",
        base_url
      );
      if (response) {
        props.onClose();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Record ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        }).then(() => {
          props.refetchData(); // Close the modal after the success message
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <DynamicForm
      fields={fields}
      onSubmit={handleSubmit}
      title="Report Types"
      subtitle="Fill in the details for the Report Types"
      initialValues={initialValues}
      onFieldChange={onFieldChange}
    />
  );
};

export default ReportTypesForm;