import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Box, useTheme } from "@mui/material";
import { features } from "../../../components/LandingPage";
import { tokens } from "../../../theme";

const FeatureSection = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // State to track the scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  // Update scroll position
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Determine the background color based on scroll position
  const backgroundColor =
    scrollPosition > 300 ? colors.blueAccent[900] : colors.blueAccent[700];

  return (
    <Container
      className="mt-20"
      id="features"
      sx={{
        backgroundColor: backgroundColor,
        transition: "background-color 0.6s ease", // Smooth transition
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <Box textAlign="center" mb={3}>
        <Box
          component="span"
          variant="h2"
          mt={10}
          bgcolor="primary.main"
          color="secondary.main"
          p={1}
          borderRadius="8px"
          display="inline-block"
          fontSize="x-large"
        >
          Features
        </Box>
        <Typography variant="h2" mt={3}>
          Streamline Your Financial AnalyticsPractice{" "}
          <Box
            component="span"
            sx={{
              background: "linear-gradient(to right, #FFA726, #FB8C00)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            with Our Financial Analytics System
          </Box>
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                transition: "transform 0.6s ease, background-color 0.6s ease", // Smooth transition for both transform and background-color
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                  transform: "scale(1.05)", // Slightly scale up on hover
                  borderRadius: 2,
                },
              }}
            >
              <Box
                mr={2}
                p={2}
                bgcolor="primary.main"
                borderRadius="50%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={56}
                height={56}
              >
                {feature.icon}
              </Box>
              <Box>
                <Typography variant="h6">{feature.text}</Typography>
                <Typography variant="body1" color="textSecondary">
                  {feature.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FeatureSection;
