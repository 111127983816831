export const clientsField = [
  {
    name: "firstName",
    label: "First Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "lastName",
    label: "Last Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "dateOfBirth",
    label: "Date of Birth *",
    type: "date",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "contactNumber",
    label: "Contact Number * *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "email",
    label: "Email *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
