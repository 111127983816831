import axios from "axios";
import {
  caseManagementUrl,
  deleteDocumentService,
  statementUploader,
} from "../../config";
import { generateAndExecuteMutation } from "../Axios/DynamicService";

const uploadDocumentsAuthHeaders = () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  return {
    Authorization: formattedToken,
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*", // Allows requests from any origin
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allows specific HTTP methods
    "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allows specific headers
  };
};

const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  return {
    Authorization: formattedToken,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*", // Allows requests from any origin
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allows specific HTTP methods
    "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allows specific headers
  };
};

export async function markTaskDone(id) {
  try {
    const mutationData = {
      id,
    };
    const response = await generateAndExecuteMutation(
      "markTaskDone",
      mutationData,
      "task",
      caseManagementUrl.uri
    );
    if (Object.values(response)[0] != null) {
    }
    return true;
  } catch (error) {
    return false;
  }
}

export async function deleteDocument(fileName, documentId) {
  const baseurldelete = deleteDocumentService.uri;
  const headers = getAuthHeaders();
  const postData = {
    fileName,
    documentId,
  };

  try {
    const response = await axios.post(baseurldelete, postData, { headers });
    return response;
  } catch (error) {
    return error.code;
  }
}
export async function UploadStatement(values) {
  const { file, password } = values;
  if (!file) throw new Error("No file provided for upload.");

  const formData = new FormData();

  formData.append("file", file);
  if (password) {
    formData.append("password", password);
  } else {
    throw new Error("password is missing.");
  }

  try {
    const token = localStorage.getItem("token");
    const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

    const response = await axios.post(statementUploader.uri, formData, {
      headers: {
        Authorization: formattedToken,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*", // Allows requests from any origin
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allows specific HTTP methods
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        Origin: "https://finsights.mcb.co.ke/", // Allows specific headers
      },
    });
    return response.status;
  } catch (error) {
    console.error("Error uploading the document:", error);
    throw error;
  }
}

export async function UploadBankStatement(values) {
  const { file, password, bankid, bankstatemanttypeid } = values;

  // Check if the necessary fields are provided
  if (!file) throw new Error("No file provided for upload.");
  if (!password) throw new Error("Password is missing.");
  if (!bankid) throw new Error("Bank ID is missing.");
  if (!bankstatemanttypeid)
    throw new Error("Bank statement type ID is missing.");

  const formData = new FormData();

  // Append the necessary fields to the form data
  formData.append("file", file);
  formData.append("password", password);
  formData.append("bankid", bankid);
  formData.append("bankstatementtypeid", bankstatemanttypeid);

  try {
    const token = localStorage.getItem("token");
    const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

    const response = await axios.post(statementUploader.bank_url, formData, {
      headers: {
        Authorization: formattedToken,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*", // Allows requests from any origin
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allows specific HTTP methods
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        Origin: "https://finsights.mcb.co.ke/", // Allows specific headers
      },
    });
    return response.status;
  } catch (error) {
    console.error("Error uploading the document:", error);
    throw error;
  }
}

export async function balanceAdding(AmountToPay, PhoneNumber) {
  const headers = getAuthHeaders();
  const base_url = "https://fsbe.mcb.co.ke/FinsightsGateway/STKPushAttempt";
  const postData = {
    AmountToPay,
    PhoneNumber,
  };

  try {
    const response = await axios.post(base_url, postData, { headers });
    return response;
  } catch (error) {
    return error.code;
  }
}

export async function generateReports(parameters, reportUrl) {
  const headers = getAuthHeaders();
  const base_url = reportUrl;
  const postData = parameters;

  try {
    const response = await axios.post(base_url, postData, { headers });
    return response;
  } catch (error) {
    return error.code;
  }
}

export async function generateReport(formData, reportUrl, setLoading) {
  const token = localStorage.getItem("token");
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;

  const headers = {
    "Content-Type": "application/json",
    // Uncomment the following lines if you need these headers
    Authorization: formattedToken,
    "Access-Control-Allow-Headers": "authorization,content-type",
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Allow-Origin": "*",
    Origin: "https://finsights.mcb.co.ke",
    referrerPolicy: "no-referrer-when-downgrade",
  };

  try {
    // Start the loading animation
    setLoading(true);

    const response = await fetch(reportUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response;
  } catch (error) {
    console.error("Error:", error);
    return error;
  } finally {
    // Stop the loading animation
    setLoading(false);
  }
}
