import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@mui/material";
import CasesRoundedIcon from "@mui/icons-material/CasesRounded";
import EventIcon from "@mui/icons-material/Event";
import AssignmentTurnedInSharpIcon from "@mui/icons-material/AssignmentTurnedInSharp";
import AddTaskIcon from "@mui/icons-material/AddTask";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GavelIcon from "@mui/icons-material/Gavel";
import TeamAssignments from "../CaseManagement/CaseDetails/TeamAssignments";
import CaseFacts from "../CaseManagement/CaseDetails/Facts";
import CaseTasks from "../CaseManagement/CaseDetails/CaseTasks";
import CaseEvents from "../CaseManagement/CaseDetails/EventRecording";
import CourtAssignments from "../CaseManagement/CaseDetails/CourtRoomAssignment";
import DocumentUploads from "../CaseManagement/CaseDetails/DocumentUploads";
import JudgeAssignments from "../CaseManagement/CaseDetails/JudgeAssignments";
import CaseDashboard from "../CaseManagement/CaseDetails/CaseDashBoard";
import { tokens } from "../../theme";
import ChargeLines from "../CaseManagement/CaseDetails/chargelines";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: "1rem",
            bgcolor: `${colors.primary[400]}`,
            minHeight: "80vh",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CaseDetails() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const rights = JSON.parse(localStorage.getItem("rights"));
  const rightId = localStorage.getItem("RightIdFromSidebar");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectChange = (event) => {
    handleChange(event, event.target.value);
  };

  const getComponentByName = (componentName) => {
    switch (componentName) {
      case "Case Dashboard":
        return <CaseDashboard />;
      case "Team Assignments":
        return <TeamAssignments />;
      case "Task Assignments":
        return <CaseTasks />;
      case "Event Recording":
        return <CaseEvents />;
      case "Judge Assignments":
        return <JudgeAssignments />;
      case "Fact Recording":
        return <CaseFacts />;
      case "Court Assignments":
        return <CourtAssignments />;
      case "Document Uploads":
        return <DocumentUploads />;
      case "Charge Lines":
        return <ChargeLines />;
      default:
        return null;
    }
  };

  const getIconByName = (componentName) => {
    switch (componentName) {
      case "Case Dashboard":
        return <CasesRoundedIcon />;
      case "Team Assignments":
        return <AssignmentTurnedInSharpIcon />;
      case "Task Assignments":
        return <AddTaskIcon />;
      case "Event Recording":
        return <EventIcon />;
      case "Judge Assignments":
        return <GavelIcon />;
      case "Fact Recording":
        return <FactCheckIcon />;
      case "Court Assignments":
        return <AssignmentTurnedInIcon />;
      case "Document Uploads":
        return <FolderSharedIcon />;
      case "Charge Lines":
        return <AccountTreeIcon />;
      default:
        return null;
    }
  };

  const [subRightComponents, setSubRightComponents] = useState([]);
 
  useEffect(() => {
    if (rights) {
      try {
        const cleanedData = rights
          ? JSON.parse(rights.replace(/\\/g, ""))
          : null;
        const importedComponents = cleanedData
          .filter((child) =>
            child.children?.some(
              (subChild) => subChild.id === parseInt(rightId, 10)
            )
          )
          .flatMap(
            (child) =>
              child.children
                .find((subChild) => subChild.id === parseInt(rightId, 10))
                ?.subrights?.sort((a, b) => a.id - b.id) // Sort subrights by id in ascending order
                .map((subRight) => ({
                  label: subRight.title,
                  content: getComponentByName(subRight.title),
                  icon: getIconByName(subRight.title),
                })) || []
          );
        setSubRightComponents(importedComponents);
      } catch (error) {
        console.error("Failed to parse rights JSON:", error);
      }
    }
  }, [rights, rightId]);

  const memoizedComponents = useMemo(
    () => subRightComponents,
    [subRightComponents]
  );

  const getTabStyle = (isSelected) => ({
    mx: 0.5,
    color: theme.palette.mode === "dark" ? "white" : "black",
    bgcolor: isSelected
      ? theme.palette.mode === "light"
        ? colors.greenAccent[300]
        : colors.greenAccent[700]
      : "inherit",
    "&.Mui-selected": {
      color: theme.palette.mode === "dark" ? "white" : colors.primary[900],
    },
    borderRadius: "0.75rem",
    border:
      theme.palette.mode === "dark" ? "1px solid #70d8bd" : "1px solid #1F2A40",
  });

  return (
    <Box
      sx={{
        marginLeft: 2,
        marginRight: 2,
        bgcolor: `${colors.primary[400]}`,
        height: "100vh",
      }}
    >
      {isSmallScreen ? (
        <Select
          value={value}
          onChange={handleSelectChange}
          fullWidth
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{ bgcolor: `${colors.primary[400]}` }}
        >
          {memoizedComponents.map((tab, index) => (
            <MenuItem
              key={index}
              value={index}
              sx={{ bgcolor: `${colors.primary[400]}` }}
            >
              {tab.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{ p: 1 }}
        >
          {memoizedComponents.map((tab, index) => (
            <Tab
              key={index}
              sx={getTabStyle(value === index)}
              icon={tab.icon}
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      )}

      {memoizedComponents.map((tab, index) => (
        <CustomTabPanel
          key={index}
          value={value}
          index={index}
          className="w-100"
        >
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}

export default CaseDetails;
