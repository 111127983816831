import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {
  CREATE_PHONE_CODE,
  UPDATE_PHONE_CODE,
  ALL_PHONE_CODES,
} from "../../../../data/SetupManagementData"; 

const PhoneCodeForm = (props) => {
  
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const {
    loading: phoneCodeLoading,
    error: phoneCodeError,
    data: phoneCodeData,
  } = useQuery(ALL_PHONE_CODES);
  
  const { loading, error, data } = useQuery(ALL_PHONE_CODES);
  const [phoneCodeChosen, setPhoneCode] = useState("");
  const [createPhoneCode] = useMutation(CREATE_PHONE_CODE, {
    onCompleted: (data) => {
      props.onClose();
      props.refetch();//edited
      swal("Success!", "Phone code has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create phone code, try again later", "error");
    },
  });

  const [updatePhoneCode] = useMutation(UPDATE_PHONE_CODE, {
    onCompleted: () => {
      props.onClose();
      props.refetch();//edited
      swal("Success!", "Phone code has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update phone code, try again later", "error");
    },
  });

  const initialValues = {
    phoneCode: props.phoneCodeData ? props.phoneCodeData.phoneCode : "",
  };

  const validationSchema = yup.object().shape({
    phoneCode: yup.string().required("Phone code is required"),
  });
  
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        phoneCode,
      } = values;
  
      if (props.isEditing) {
        await updatePhoneCode({
          variables: {
            id: props.phoneCodeData.id,
            phoneCode,
          },
        });
        console.log("Phone code updated successfully!");
      } else {
        await createPhoneCode({
          variables: {
            phoneCode,
          },
        });
        console.log("Phone code created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  
    setSubmitting(false);
  };
  

  const handlePhoneCodeChange = (event, setFieldValue) => {
    const selectedPhoneCodeId = event.target.value;
    const selectedPhoneCode = phoneCodeData.allPhoneCodes.find(
      (phoneCode) => phoneCode.id === selectedPhoneCodeId
    );
    if (selectedPhoneCode) {
      setPhoneCode(selectedPhoneCode.phoneCode);
    }
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Phone Code" : "Create Phone Code"}
        subtitle={
          props.isEditing ? "Edit an Existing Phone Code" : "Create a New Phone Code"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Phone Code *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phoneCode}
                name="phoneCode"
                error={touched.phoneCode && !!errors.phoneCode}
                helperText={touched.phoneCode && errors.phoneCode}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default PhoneCodeForm;
