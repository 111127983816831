import React from "react";
import { Box } from "@mui/material";
import { ReportTypesColumns, RoleGroupColumns } from "../../../data/DynamicTable/Collumns";
import { allReportTypes, allRoleGroups } from "../../../data/Axios/queries";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { roleManagemenUrl, setupManagementUrl } from "../../../config";


import ReportTypesForm from "./Forms";

const base_url = setupManagementUrl.uri;

const ReportTypes = () => {
  const actions = {
    entity: "Rights",
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: true,
      permission: "ActivatePermission",
    },
    showStatus: true,
  };

  return (
    <Box m="10px">
      <DynamicTable
        title="Reports"
        subtitle="Reports Types"
        columns={ReportTypesColumns}
        FormComponent={ReportTypesForm}
        query={allReportTypes}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default ReportTypes;