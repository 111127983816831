import { Box } from "@mui/material";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { caseManagementUrl } from "../../../../config";
import CasesForm from "./Form";
import {
  FactColumns,
  TeamAssignmentsColumns,
} from "../../../../data/DynamicTable/Collumns";
import { useEffect, useState } from "react";
import { fetchDataEngine } from "../../../../data/Axios/DynamicService";
import TeamAssignmentForm from "./Form";
import { PulseLoader } from "react-spinners";

const base_url = caseManagementUrl.uri;
const actions = {
  entity: "Fact Recording",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  ViewCase: {
    key: "VIEWDETAILS",
    button_name: "View Details",
    Show_Button: false,
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
  showStatus: true,
  dataObject: {
    inputObjectName: "allFactsByColumnValues",
    inputSubObjectName: "factModel",
    returnFields: [
      "id",
      "factDescription",
  
    ],
  },
};
function CaseFacts() {
  

  return (
    <Box m="2px">
      <>
      
          <DynamicTable
            title="Facts"
            subtitle="View all Facts related to a case, create or edit  a fact"
            columns={FactColumns}
            FormComponent={TeamAssignmentForm}
            base_url={base_url}
            actions={actions}
          />
   
      </>
    </Box>
  );
}

export default CaseFacts;
