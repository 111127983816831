import React, { useState, useEffect } from "react";
import { Box, Card, Typography } from "@mui/material";
import { PulseLoader } from "react-spinners"; // Importing the loader

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { CasesColumns } from "../../../data/DynamicTable/Collumns";
import { caseManagementUrl } from "../../../config";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";

const base_url = caseManagementUrl.uri;

function CasesByCorporateClients(props) {
  const data = JSON.parse(localStorage.getItem("CorporateclientData"));
  const totalData = JSON.parse(localStorage.getItem("totalItems"));
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const actions = {
    entity: "Cases",
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: false,
      permission: "EditPermission",
    },

    add: {
      key: "ADD",
      button_name: "File New Case",
      Show_Button: false,
      permission: "CreatePermission",
      inputObjectName: "createCaseDynamic",
      InputObject: "newCase",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: false,
      permission: "ActivatePermission",
      inputObjectName: "acivateDeactivateCase",
    },
    ViewCase: {
      key: "VIEWDETAILS",
      button_name: "View Details",
      Show_Button: true,
      permission: "hasNoPermission",
      roleGroupId: [1, 2, 3, 4],
    },
    updateCaseStatus: {
      key: "UPDATECASESTATUS",
      button_name: "Update Case Status",
      Show_Button: true,
      permission: "hasNoPermission",
      roleGroupId: [1, 2, 3, 4],
    },
    modelName: "Cases",
    showStatus: true,
    dataObject: {
      inputFields: {
        clientId: data.id,
      },
      inputObjectName: "allCasesByColumnValues",
      inputSubObjectName: "casesViewModel",
      returnFields: [
        "id",
        "clientType",
        "clientTypeId",
        "caseNumber",
        "title",
        "description",
        "summary",
        "categoryName",
        "caseCategoryId",
        "caseSubCategoryId",
        "subcategoryName",
        "caseStatus",
        "dateOpened",
        "dateClosed",
        "priority",
        "isActive",
        "statusFlag",
        "createdBy",
        "createdDate",
        "updatedBy",
        "updatedDate",
        "isInvoiced",
        "companyId",
        "company",
        "regionId",
        "branchId",
        "individualFirstName",
        "individualLastName",
        "corporateCompanyName",
        "workFlow",
        "name",
        "clientId",
      ],
    },
  };
  const title = ` ${data.companyName} Files`;
  const subtitle = `View All Files For ${data.companyName}`;

  return (
    <Box m="2px" p="1rem">
      <Box
        // m="2px"
        //p="1rem"
        sx={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          // height: "100vh",
        }}
      >
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: colors.primary[400],
          }}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 18 }}
              color={colors.greenAccent[500]}
              gutterBottom
            >
              Total Number of files for {data.companyName} is :{''}
              <Box component="span" color={colors.redAccent[400]}>
                {totalData}
              </Box>
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <DynamicTable
        title={title}
        subtitle={subtitle}
        columns={CasesColumns}
        // FormComponent={CasesForm}
        //query={allCasesView}
        base_url={base_url}
        actions={actions}
      />
      {/* //)} */}
    </Box>
  );
}

export default CasesByCorporateClients;
