import * as React from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners"; // Importing the loader
import login from "../../Assets/Images/FinsightLogInPage.jpeg";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { loginCallApi } from "../../data/userData";
import swal from "sweetalert";
import Swal from "sweetalert2";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { CircularProgress, useTheme } from "@mui/material";

import { tokens } from "../../theme";
function saveTokenToStorage(decodedToken) {
  localStorage.setItem("decodedToken", JSON.stringify(decodedToken)); // Example: Using local storage
}

async function saveDataToLocalStorage(response) {
  const token = response.data.token;
  localStorage.setItem("token", JSON.stringify(token));

  const filePath = response.data.companyLogoPath
    ? `${response.data.companyLogoPath}`
    : null;
  localStorage.setItem("filePath", filePath);

  console.log("file path", localStorage.getItem("filePath"));
  const expirationTime = new Date().getTime() + 5 * 60 * 60 * 1000;
  //const expirationTime = new Date().getTime() + 1 * 60 * 1000;
  localStorage.setItem("tokenExpiration", expirationTime);
  localStorage.setItem("rights", JSON.stringify(response.data.rights));
  const decodedToken = jwtDecode(token);
  console.log("Token", decodedToken);
  saveTokenToStorage(decodedToken);
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mcb.co.ke/">
        MCB Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignUpSide({ onLogin }) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = "https://fsbe.mcb.co.ke/FinSightsGateway/signup";
  React.useEffect(() => {
    // Load saved credentials if "Remember Me" was checked
    const savedUsername = sessionStorage.getItem("username");
    const savedPassword = sessionStorage.getItem("password");
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false); // Adding a loading state
  const formInitialDetails = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    firmSize: "",
    message: "",
  };

  const firmSizes = [
    "1-5 branches",
    "6-10 branches",
    "11-20 branches",
    "More than 20 branches",
  ];

  const validationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    username: Yup.string().required("Username is required"),
    phone: Yup.string().required("Phone number is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  async function signup(values) {
    const { email, firstname, lastname, username, phone } = values;
    const postData = {
      email,
      firstname,
      lastname,
      username,
      phone,
    };
    try {
      const response = await axios.post(base_url, postData);
      return response;
    } catch (error) {
      return error;
    }
  }
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await signup(values);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response.data.message,
        });
          navigate("/login");
      } else if (response.response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: response.response.data,
        });
      }
    } catch (error) {
      // Show error message
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    // Set submitting to false
    setSubmitting(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <PulseLoader size={15} color={"#3f51b5"} />
        </Box>
      )}
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            position: "relative",
            backgroundImage: `url(${login})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.1)", // 30% transparent white
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <Formik
              initialValues={formInitialDetails}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,

                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: "span 2",
                      },
                      marginTop: "40px",
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="First Name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstname}
                      name="firstname"
                      error={touched.firstname && !!errors.firstname}
                      helperText={touched.firstname && errors.firstname}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Last Name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastname}
                      name="lastname"
                      error={touched.lastname && !!errors.lastname}
                      helperText={touched.lastname && errors.lastname}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Username *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.username}
                      name="username"
                      error={touched.username && !!errors.username}
                      helperText={touched.username && errors.username}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Phone *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      name="phone"
                      error={touched.phone && !!errors.phone}
                      helperText={touched.phone && errors.phone}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Email *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 2" }}
                    />
                  </Box>

                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </Box>
                  <Grid container>
                    <Grid item xs>
                      <Link href="/Login" variant="body2">
                        Login
                      </Link>
                    </Grid>
                    {/* <Grid item>
                      <Link href="/SignUp" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid> */}
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
