// src/App.js
import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ApolloProvider } from "@apollo/client";
import SignInSide from "./scenes/Login/LoginPage";
import ChangePasswordForm from "./scenes/users/changepassword";
import Dashboard from "./scenes/dashboard";
import Modules from "./scenes/RoleManagement/Modules";
import Rights from "./scenes/RoleManagement/Rights";
import RoleGroups from "./scenes/RoleManagement/RoleGroup";
import Role from "./scenes/RoleManagement/Roles";
import AssignRight from "./scenes/RoleManagement/Roles/AssignRoleRights";
import AccountAndFinanceModule from "./scenes/AccountsAndFinanceManagement/Accounts";
import Users from "./scenes/users";
import UsersForm from "./scenes/users/users-form";
import { Reduxstore } from "./store/store";
import { Provider } from "react-redux";
// import CourtSetups from "./scenes/Setup Management/CourtSetups/CourtSetups";
import GeographySetups from "./scenes/Setup Management/GeographySetups/GeographySetups";
import LeadSetups from "./scenes/Setup Management/LeadSetups/LeadSetups";
import OtherSetups from "./scenes/Setup Management/OtherSetups/OtherSetups";
import MessageTemplates from "./scenes/Setup Management/MessageTemplates/Template";
import Cases from "./scenes/CaseManagement/Case";

import MajorClassification from "./scenes/Reports/MajorClassification";
import MinorClassification from "./scenes/Reports/MinorClassification";
import ReportTypes from "./scenes/Reports/ReportTypes";
import ReportBrowser from "./scenes/Reports/ReportBrowser";

import CaseDetails from "./scenes/Case";
import CorporateClients from "./scenes/clientManagement/corporateClients";
import IndividualClients from "./scenes/clientManagement/individualClients";
import LandingPage from "./scenes/LandingPage";

// Apollo clients
import {
  createRoleClient,
  createUsersClient,
  createSetupClient,
  createClientManagementClient,
} from "./config";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import withLayout from "./components/HOCApp";
import ChartOfAccounts from "./scenes/AccountsAndFinanceManagement/chartofaccounts";
import { Line, Pie } from "react-chartjs-2";
import BarChart from "./components/BarChart";
import CompanyManagementModule from "./scenes/CompanyManagement/CompanyManagement";
import axios from "axios";
import {
  AbilityContext,
  defineAbilitiesFor,
  transformRoleRights,
} from "./data/RBAC/transformRoleRights";
import PersonDetails from "./scenes/Profile";
import SystemSetups from "./scenes/Setup Management/SystemSetups/SystemSetups";

import Calendar from "./scenes/calendar";
import ForgotPasswordForm from "./scenes/users/ForgotPassword";
import McbSwitch from "./scenes/Login/mcbswitch";
import AdhocMain from "./scenes/adhoc/adhoc";
import SubRightTable from "./scenes/RoleManagement/SubRight";
import CasesByCorporateClients from "./scenes/clientManagement/corporateClients/CaseByClient";
import VerticalTabComponent from "./data/DynamicTable/VerticalTabComponent";
import UploadStatement from "./scenes/UploadStatement/UploadStatement";
import ViewReport from "./scenes/UploadStatement/ViewReport";
import SignUpSide from "./scenes/Login/SignUp";
import OTP from "./scenes/Login/OTP";
import ApiKey from "./scenes/users/ApiKey";
import Meta from "./Meta/meta";
import UploadBankStatement from "./scenes/UploadStatement/UploadStatement/Banks";
import ViewBankReport from "./scenes/UploadStatement/ViewReport/bank";

const setupcaseclient = new ApolloClient({
  uri: "https://localhost:7266/graphql/",
  cache: new InMemoryCache(),
});

let hasRefreshed = false;

function App() {
  const userManagementClient = createUsersClient();
  const roleManagementClient = createRoleClient();
  const clientManagementClient = createClientManagementClient();
  const setupManagementClient = createSetupClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      const expirationTime = localStorage.getItem("tokenExpiration");
      const currentTime = new Date().getTime();

      if (
        !token ||
        !expirationTime ||
        currentTime > parseInt(expirationTime, 10)
      ) {
        // Token is missing or expired, log out the user
        if (
          location.pathname === "/forgot-password" ||
          location.pathname === "/" ||
          location.pathname === "/SignUp"
        ) {
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          setIsAuthenticated(false);
          navigate("/Login");
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 60000); // Check every minute

    // Initial check when component mounts
    checkTokenExpiration();

    return () => clearInterval(interval);
  }, [navigate, setIsAuthenticated, location.pathname]);

  const INACTIVITY_LIMIT = 15 * 60 * 1000; // 15 minutes
  useEffect(() => {
    let inactivityTimer;

    const resetInactivityTimer = () => {
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
      inactivityTimer = setTimeout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        localStorage.clear();
        setIsAuthenticated(false);
        navigate("/Login");
      }, INACTIVITY_LIMIT);
    };

    const handleActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    resetInactivityTimer(); // Initialize timer

    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
    };
  }, [navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAuthenticated(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  // axios.interceptors.request.use(
  //   (config) => {
  //     const unformatedtoken = localStorage.getItem("token");
  //     const token = unformatedtoken ? unformatedtoken.replace(/"/g, "") : null;
  //     if (token) {
  //       config.headers.Authorization = `Bearer ${token}`;
  //     }
  //     return config;
  //   },
  //   (error) => Promise.reject(error)
  // );
  // axios.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     if (error.response && error.response.status === 401 && !hasRefreshed) {
  //       const isLoginPage =
  //         window.location.pathname === "/Login" ||
  //         window.location.pathname === "/MCB-SWITCH";
  //       if (!isLoginPage) {
  //         hasRefreshed = true;
  //         window.location.reload();
  //       }
  //     }
  //     return Promise.reject(error);
  //   }
  // );
  return (
    <ColorModeContext.Provider value={colorMode}>
      {/* <Provider store={Reduxstore}> */}
      <ThemeProvider theme={theme}>
        <Meta />
        <CssBaseline />{" "}
        <ApolloProvider client={userManagementClient}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/Login"
              element={<SignInSide onLogin={() => setIsAuthenticated(true)} />}
            />
            <Route path="/SignUp" element={<SignUpSide />} />
            <Route path="/changepassword" element={<ChangePasswordForm />} />
            <Route path="/forgot-password" element={<ForgotPasswordForm />} />
            <Route path="/profile" element={withLayout(PersonDetails)()} />
            <Route path="/MCB-SWITCH" element={<McbSwitch />} />
            <Route path="/OTP" element={<OTP />} />
          </Routes>
        </ApolloProvider>
        {isAuthenticated && (
          <>
            <ApolloProvider client={roleManagementClient}>
              <Routes>
                <Route path="/module" element={withLayout(Modules)()} />
                <Route path="/rights" element={withLayout(Rights)()} />
                <Route path="/role-group" element={withLayout(RoleGroups)()} />
                <Route path="/role" element={withLayout(Role)()} />
                <Route path="/subright" element={withLayout(SubRightTable)()} />
                <Route
                  path="/role-Rights"
                  element={withLayout(AssignRight)()}
                />
                <Route
                  path="/accounts-setups"
                  element={withLayout(AccountAndFinanceModule)()}
                />
              </Routes>
            </ApolloProvider>
            <ApolloProvider client={userManagementClient}>
              <Routes>
                <Route path="/users" element={withLayout(Users)()} />
                <Route path="/usersform" element={withLayout(UsersForm)()} />
                <Route path="/api-key" element={withLayout(ApiKey)()} />
              </Routes>
            </ApolloProvider>
            <ApolloProvider client={setupManagementClient}>
              <Routes>
                <Route path="/Lead" element={withLayout(LeadSetups)()} />
                <Route
                  path="/Geography"
                  element={withLayout(GeographySetups)()}
                />
                <Route
                  path="/othersetups"
                  element={withLayout(OtherSetups)()}
                />
                <Route
                  path="/chart-of-accounts"
                  element={withLayout(ChartOfAccounts)()}
                />
                <Route
                  path="/manage-companies"
                  element={withLayout(CompanyManagementModule)()}
                />
                <Route
                  path="/messagetemplates"
                  element={withLayout(MessageTemplates)()}
                />
                <Route
                  path="/finsights-setups"
                  element={withLayout(VerticalTabComponent)()}
                />
                <Route
                  path="/banks-setups"
                  element={withLayout(VerticalTabComponent)()}
                />
              </Routes>
            </ApolloProvider>
            <ApolloProvider client={setupcaseclient}>
              <Routes>
                <Route path="/Cases" element={withLayout(Cases)()} />
                <Route path="/diaries" element={withLayout(Calendar)()} />
                <Route
                  path="/case-details"
                  element={withLayout(CaseDetails)()}
                />
                <Route
                  path="/Corporate-Client-Cases"
                  element={withLayout(CasesByCorporateClients)()}
                />
              </Routes>
            </ApolloProvider>
            <ApolloProvider client={clientManagementClient}>
              <Routes>
                <Route
                  path="/corporate-clients"
                  element={withLayout(CorporateClients)()}
                />
                <Route
                  path="/individual-clients"
                  element={withLayout(IndividualClients)()}
                />
                <Route path="/view-report" element={withLayout(ViewReport)()} />
                <Route
                  path="/view-bank-report"
                  element={withLayout(ViewBankReport)()}
                />
              </Routes>
            </ApolloProvider>
            {/* Reports  */}
            <Routes>
              <Route
                path="/reports-major-classification"
                element={withLayout(MajorClassification)()}
              />
              <Route
                path="/reports-minor-classification"
                element={withLayout(MinorClassification)()}
              />
              <Route path="/report-types" element={withLayout(ReportTypes)()} />
              <Route
                path="/report-browser"
                element={withLayout(ReportBrowser)()}
              />
              <Route path="/bar" element={withLayout(BarChart)()} />
              <Route
                path="/super-admin-dashboard"
                element={withLayout(Dashboard)()}
              />
              <Route path="/adhoc" element={withLayout(AdhocMain)()} />
              <Route path="/adhoc" element={withLayout(AdhocMain)()} />
              <Route
                path="/upload-statement"
                element={withLayout(UploadStatement)()}
              />
              <Route
                path="/uploadBankStatements"
                element={withLayout(UploadBankStatement)()}
              />
            </Routes>
          </>
        )}
        <Routes>
          <Route path="/signin" element={<SignInSide />} />
        </Routes>
      </ThemeProvider>
      {/* </Provider> */}
    </ColorModeContext.Provider>
  );
}

export default App;
