import { Box } from "@mui/material";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { caseManagementUrl } from "../../../../config";
import CasesForm from "./Form";
import {
  JudgeAssignmentColumns,
  judgColumns,
} from "../../../../data/DynamicTable/Collumns";
import { useEffect, useState } from "react";
import { fetchDataEngine } from "../../../../data/Axios/DynamicService";
import JudgeAssignmentForm from "./Form";
import { PulseLoader } from "react-spinners";

const base_url = caseManagementUrl.uri;
const actions = {
  entity: "Judge Assignments",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  ViewCase: {
    key: "VIEWDETAILS",
    button_name: "View Details",
    Show_Button: false,
  },

  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
  showStatus: true,
  dataObject: {
    inputObjectName: "judgeAssignmentViewsByColumnValues",
    inputSubObjectName: "viewModel",
    returnFields: [
      "id",
      "name",
      "statusFlag",
      "caseNumber",
      "caseTitle",
      "caseDescription",
      "assignedDate",
    ],
  },
};
function JudgeAssignments() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Judge Assignments"
          subtitle="View all JudgeAssignments, create or edit "
          columns={JudgeAssignmentColumns}
          FormComponent={JudgeAssignmentForm}
          actions={actions}
          base_url={base_url}
        />
      </>
    </Box>
  );
}

export default JudgeAssignments;
