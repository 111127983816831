import { Box } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { companyManagementUrl } from "../../../config";

import { useEffect, useState } from "react";

import { PulseLoader } from "react-spinners";
import { BranchColumns } from "../../../data/DynamicTable/CompanyManagementColumns";
import { allBranches, allRegions } from "../../../data/Axios/queries";
import BranchForm from "./form";
import { graphqlQuery } from "../../../data/Axios/DynamicService";
import DynamicEditableTable from "../../../data/DynamicTable/EditableDataGrid";

const base_url = companyManagementUrl.uri;
// const setup_url = setupManagementUrl.uri;

const actions = {
  entity: "Company Branches",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
    // inputObjectName: "updateBranch",
    // InputObject: "updatedBranch",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
    // inputObjectName: "createBranch",
    // InputObject: "newBranch",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
};
function BranchTable() {
  const [regionData, setRegionData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const regionDataResponse = await graphqlQuery(allRegions, base_url);
        if (regionDataResponse) {
          setRegionData(regionDataResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);

  const regionOptions = regionData
    ? regionData.map((region) => ({
        value: region.id,
        label: region.regionName,
      }))
    : [];

  const columns = [
    ...BranchColumns,
    {
      field: "region",
      headerName: "Regions",
      type: "select",
      valueOptions: regionOptions,
      // valueFormatter: (params) => {
      //   const region = regionOptions.find(
      //     (option) => option.value === params.value
      //   );
      //   return region ? region.label : "";
      // },
      // renderCell: (params) => {
      //   const region = regionOptions.find(
      //     (option) => option.value === params.value
      //   );
      //   return <div>{region ? region.label : ""}</div>;
      // },
      flex: 1,
      editable: false,
    },
  ];
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Branch"
          subtitle="Create, view or edit account Branch"
          columns={BranchColumns}
          FormComponent={BranchForm}
          query={allBranches}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default BranchTable;
