import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";

import DynamicForm from "../../../../data/Axios/DynamicForm";

import { caseManagementUrl } from "../../../../config";
import Swal from "sweetalert2";
import {
  accountCategoryFields,
  chargeLineFields,
} from "../../../../data/DynamicTable/AccountsDynamicForms";
import { generateAndExecuteMutation } from "../../../../data/Axios/DynamicService";

const base_url = caseManagementUrl.uri;

const ChargeLineForm = (props) => {
  const initialValues = {
    id: props.data ? props.data.id : null,
    description: props.data ? props.data.description : "",
    price: props.data ? props.data.price : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const caseId = JSON.parse(localStorage.getItem("CaseId"));
      const { description, price } = values;

      const mutationData = props.isEditing
        ? {
            id: initialValues.id,
            description,
            caseId,
            price: parseFloat(price),
          }
        : {
            description,
            caseId,
            price: parseFloat(price),
          };
      const response = await generateAndExecuteMutation(
        "insertChargeLine",
        mutationData,
        "chargeLineInput",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Charge line ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={chargeLineFields}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Charge Line" : "Create Charge Line"}
      subtitle={
        props.isEditing
          ? "Edit an Existing Charge Line"
          : "Create a New Charge Line"
      }
    />
  );
};

export default ChargeLineForm;
