import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { roleManagemenUrl } from "../../../config";
import DynamicForm from "../../../data/Axios/DynamicForm";
import { subRightsFields } from "../../../data/DynamicTable/AccountsDynamicForms";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";
import { allRights } from "../../../data/Axios/queries";

const base_url = roleManagemenUrl.uri;

const SubRightsForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const companyId = parseInt(decodedToken.CompanyId);
  const [rightsData, setRightsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const initialValues = {
    id: props.data ? props.data.id : "",
    title: props.data ? props.data.title : "",
    icon: props.data ? props.data.icon : "",
    action: props.data ? props.data.action : "",
    rightId: props.data ? props.data.rightId : "",
    companyId: props.data ? props.data.companyId : "",
    updatedDate: props.data
      ? props.data.updatedDate
      : new Date().toISOString().split("T")[0],
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { id, title, icon, action, rightId } = values;
  
      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            title,
            icon, // Ensure this is being used if required
            action, // Ensure this is being used if required
            rightId,
            isActive: 1,
            companyId: props.data.companyId, // You might need to include userId here as well
            //updatedBy: userId,
            createdBy: userId, // Include updatedBy if needed
          }
        : {
            title,
            icon, // Ensure this is being used if required
            action, // Ensure this is being used if required
            rightId,
            isActive: 1,
            companyId: companyId, // Ensure the companyId is included when creating a new sub-right
            createdBy: userId, // Include createdBy if needed
          };
  
      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateSubRight" : "createSubRight",
        mutationData,
        props.isEditing ? "updatedSubRight" : "newSubRight",
        base_url
      );
  
      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
  
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Sub Right ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  
    setSubmitting(false);
  };
  
  useEffect(() => {
    async function fetchAllData() {
      try {
        const rightsResponse = await graphqlQuery(allRights, base_url);

        if (rightsResponse) {
          setRightsData(rightsResponse || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);
  const rightOptions = rightsData
    ? rightsData.map((branch) => ({
        value: branch.id,
        label: branch.title,
      }))
    : [];
  const fields = [
    ...subRightsFields,

    {
      name: "rightId",
      label: "Right *",
      type: "select",
      options: rightOptions,
      gridColumn: "span 2",
      required: true,
    },
  ];
  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Sub Right" : "Create Sub Right"}
      subtitle={
        props.isEditing
          ? "Edit an Existing Sub Right"
          : "Create a New Sub Right"
      }
    />
  );
};

export default SubRightsForm;
