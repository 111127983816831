import { Box } from "@mui/material";

import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { AccountsandFinanceurl, setupManagementUrl } from "../../../../config";
import { AccountTypesColumns } from "../../../../data/DynamicTable/Collumns";
import {
  allAccountCategories,
  allAccountTypes,
  allAccounts,
  allBankStatements,
  allBanks,
  allBankstatementsTypes,
  allPaymentTypes,
} from "../../../../data/Axios/queries";
import {
  AccountCategoriesColumns,
  AccountSubCategoriesColumns,
  AccountsColumns,
  BankStatementsColumns,
  BanksColumns,
  BankstatementstypesColumns,
  PaymentTypesColumns,
} from "../../../../data/DynamicTable/AccountsColumns";
import AccountCategoryForm from "./form";
import AccountsForm from "./form";
import PaymentTypeForm from "./form";
import BankStatementTypes from "./form";
const base_url = setupManagementUrl.uri;
const actions = {
  entity: "BankStatementsTypes",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  showStatus: true,
};
function BankStatementsTypesTable() {
  return (
    <Box m="2px">
      <DynamicTable
        title="Bank Statements Types"
        subtitle="Create, view or edit bank Statements Types
        "
        columns={BankstatementstypesColumns}
        FormComponent={BankStatementTypes}
        query={allBankstatementsTypes}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
}

export default BankStatementsTypesTable;
