import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_MODULE, UPDATE_MODULE } from "../../../data/RoleManagementData"; // Import your GraphQL mutations for creating and updating a module
import swal from "sweetalert";
import Header from "../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { generateMutation } from "../../../data/Axios/DynamicService";
import {
  graphqlMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";
import { roleManagemenUrl, setupManagementUrl } from "../../../config";
import { allBanks, allBankstatementsTypes } from "../../../data/Axios/queries";
import Swal from "sweetalert2";
import {
  UploadBankStatement,
  UploadStatement,
} from "../../../data/DynamicTable/DynamicFunctions";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";

const base_url = setupManagementUrl.uri;

const UploadBankStatementForm = (props) => {
  const initialValues = {};

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [bankData, setBankData] = useState([]);
  const [bankStatementtype, setTransactionType] = useState([]);
  useEffect(() => {
    fetchBankData();
  }, []);
  const fetchBankData = async () => {
    try {
      const response = await graphqlQuery(allBanks, base_url);
      if (response) {
        setBankData(response);
      }
      const statement = await graphqlQuery(allBankstatementsTypes, base_url);
      if (statement) {
        setTransactionType(statement);
      }
    } catch (error) {
      console.error("Error:", error.message);
      throw error;
    }
  };
  const validationSchema = yup.object().shape({
    file: yup
      .mixed()
      .required("Please select a file to upload!")
      .test("fileSize", "The file has exceeded the 50mb limit", (value) => {
        return value && value.size <= 52428800; // 1MB
      }),
    bankid: yup.string().required("Bank name is required"),
    bankstatemanttypeid: yup.string().required("Transaction type is required"),
    // .test("fileType", "Unsupported file format", value => {
    //   return value && ['image/jpeg', 'image/png', 'application/pdf', ].includes(value.type);
    // }),
    password: yup.string().required("Please enter statement password"),
  });
  const BankOptions = bankData.map((item) => ({
    key: item.id,
    value: item.id,
    label: item.bankName,
  }));
  const BankStatementypeOptions = bankStatementtype.map((item) => ({
    key: item.id,
    value: item.id,
    label: item.type,
  }));

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log("Submitting values:", values); // Debugging statement

    try {
      const response = await UploadBankStatement(values);

      if (response == 200) {
        console.log("Statement uploaded successfully");
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Statement uploaded successfully.`,
        });
      }
      setSubmitting(false);
    } catch (error) {
      console.error("Error during submission:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error.response?.data?.error || "An error occurred.",
      });
      setSubmitting(false);
    }
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Remove document" : "Upload Statement"}
        subtitle={
          props.isEditing
            ? "Remove the document from the case"
            : "Add a statement to be crunched"
        }
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit} // Ensure you're passing your custom handleSubmit here
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit, // This is Formik's handleSubmit
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* File Upload Button */}
              <Button
                variant="contained"
                component="label"
                sx={{
                  gridColumn: "span 2",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  bgcolor: `${colors.grey[400]}`,
                }}
              >
                Select a statement to upload
                <input
                  id="file"
                  name="file"
                  type="file"
                  hidden
                  onChange={(event) =>
                    setFieldValue("file", event.currentTarget.files[0])
                  }
                />
              </Button>
              {touched.file && errors.file ? (
                <Typography color="error">{errors.file}</Typography>
              ) : null}
              {values.file && (
                <Typography sx={{ margin: "1rem" }}>
                  {values.file.name}
                </Typography>
              )}

              {/* Statement Password Input */}
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Statement Password *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>

            {/* Bank Select Dropdown */}
            <FormControl
              fullWidth
              variant="filled"
              error={touched.bankid && !!errors.bankid}
              style={{ marginBottom: "20px" }}
            >
              <InputLabel id="bank-select-label">Choose the bank *</InputLabel>
              <Select
                labelId="bank-select-label"
                value={values.bankid || ""} // Ensure value is always controlled
                name="bankid"
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {BankOptions.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {touched.bankid && errors.bankid && (
                <p style={{ color: "red" }}>{errors.bankid}</p>
              )}
            </FormControl>

            {/* Statement Type Select Dropdown */}
            <FormControl
              fullWidth
              variant="filled"
              error={
                touched.bankstatemanttypeid && !!errors.bankstatemanttypeid
              }
            >
              <InputLabel id="transaction-select-label">
                Bank Statement Type *
              </InputLabel>
              <Select
                labelId="transaction-select-label"
                value={values.bankstatemanttypeid || ""} // Ensure value is always controlled
                name="bankstatemanttypeid"
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {BankStatementypeOptions.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {touched.bankstatemanttypeid && errors.bankstatemanttypeid && (
                <p style={{ color: "red" }}>{errors.bankstatemanttypeid}</p>
              )}
            </FormControl>

            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting} // This disables the button during submission
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UploadBankStatementForm;
