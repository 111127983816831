import { Box } from "@mui/material";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { caseManagementUrl } from "../../../../config";
import CourtAssignmentsForm from "./Form";
import { CourtAssignmentsColumns } from "../../../../data/DynamicTable/Collumns";
import { useEffect, useState } from "react";
import { fetchDataEngine } from "../../../../data/Axios/DynamicService";
import { PulseLoader } from "react-spinners";
import { allCourtAssignments } from "../../../../data/Axios/queries";

const base_url = caseManagementUrl.uri;

const actions = {
  entity: "Court Assignments",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
  documentAccess: {
    key: "ASSIGN_ACCESS",
    button_name: "Manage Document Access",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  deleteDocument: {
    key: "DELETE_DOCUMENT",
    button_name: "Delete Document",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  showStatus: true,
  dataObject: {
    inputObjectName: "courtAssignmentViewsByColumnValues",
    inputSubObjectName: "viewModel",
    returnFields: [
      "id",
      "statusFlag",
      "caseNumber",
      "caseTitle",
      "caseDescription",
      "assignedDate",
      "court",
      "county",
      "subCounty",
    ],
  },
};

function CourtAssignments() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Court Assignments"
          subtitle="View all Court Assignments, create or edit"
          columns={CourtAssignmentsColumns}
          FormComponent={CourtAssignmentsForm}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}
export default CourtAssignments;
