import React from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import { CREATE_COURT_TYPE, UPDATE_COURT_TYPE } from "../../../../data/SetupManagementData";
import { graphqlQuery } from "../../../../data/Axios/DynamicService";
// import { allCompanies } from "../../../../data/Axios/queries";
import { setupManagementUrl } from "../../../../config";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../../../../theme";


const setup_url = setupManagementUrl.uri;

const CourtTypeForm = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [company, setCompanies] = useState([]);
  const [createCourtType] = useMutation(CREATE_COURT_TYPE, {
    onCompleted: () => {
      props.onClose()
      swal("Success!", "Court type has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create court type, try again later", "error");
    },
  });

  const [updateCourtType] = useMutation(UPDATE_COURT_TYPE, {
    onCompleted: () => {
      props.onClose()
      swal("Success!", "Court type has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update court type, try again later", "error");
    },
  });
  // async function fetchCompanies() {
  //   try {
  //     const data = await graphqlQuery(allCompanies, setup_url);
  //     if (data !== null) {
  //       setCompanies(data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching companies:", error);
  //     throw error;
  //   }
  // }

  // useEffect(() => {
  //   fetchCompanies();
  // }, []);

  const initialValues = {
    courtType: props.courtTypeData ? props.courtTypeData.courtType : "",
  };

  const validationSchema = yup.object().shape({
    courtType: yup.string().required("Court type is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { courtType } = values;

      if (props.isEditing) {
        props.onClose();
        await updateCourtType({
          variables: {
            id: props.courtTypeData.id,
            courtType,
          },
        });
        console.log("Court type updated successfully!");
      } else {
        await createCourtType({
          variables: {
            courtType,
          },
        });
        console.log("Court type created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));


  return (
    <Box m="20px" sx={{ bgcolor: `${colors.primary[400]}` }}>
      <Header
        title={props.isEditing ? "Edit Court Type" : "Create Court Type"}
        subtitle={
          props.isEditing ? "Edit an Existing Court Type" : "Create a New Court Type"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px" gridTemplateColumns={isMobile ? "1fr" : "repeat(2, 1fr)"} sx={{ bgcolor: `${colors.primary[400]}` }}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Court Type *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.courtType}
                name="courtType"
                error={touched.courtType && !!errors.courtType}
                helperText={touched.courtType && errors.courtType}
              />
              {/* <FormControl variant="filled" fullWidth error={touched.companyId && Boolean(errors.companyId)}>
                <InputLabel id="companyId-label">Select Company</InputLabel>

                <Select
                  variant="filled"
                  fullWidth
                  labelId="companyId-label"
                  id="companyId"
                  name="companyId"
                  label="Select Company"
                  value={values.companyId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.companyId && Boolean(errors.companyId)}
                  helperText={touched.companyId && errors.companyId}
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="" disabled>
                    Select Company
                  </MenuItem>
                  {company.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.company}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Box>
            <Box display="flex" justifyContent={isMobile ? "center" : "flex-end"} mt="20px"  >
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default CourtTypeForm;
