import { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useTheme,
  Autocomplete,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  generateAndExecuteMutation,
  generateMutation,
  graphqlMutation,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";
import {
  caseManagementUrl,
  clientManagementUrl,
  setupManagementUrl,
} from "../../../config";
import {
  allCorporateClients,
  allIndividualClients,
  caseCategory,
  allGenders,
} from "../../../data/Axios/queries";
import { fetchDataEngine } from "../../../data/Axios/DynamicService";
import { useState } from "react";
import Header from "../../../components/Header";
import { clientsField } from "../../../data/DynamicTable/clientsDynamicForms";
import DynamicForm from "../../../data/Axios/DynamicForm";

const base_url = clientManagementUrl.uri;

const IndividualClientsForm = (props) => {
  const [genderData, setGenderData] = useState([]);
  const [loading, setLoading] = useState(true);

  const initialValues = {
    id: props.data ? props.data.id : null,
    firstName: props.data ? props.data.firstName : null,
    lastName: props.data ? props.data.lastName : null,
    dateOfBirth: props.data ? props.data.dateOfBirth : null,
    genderId: props.data ? props.data.genderId : null,
    nationality: props.data ? props.data.nationality : null,
    identificationType: props.data ? props.data.identificationType : null,
    identificationNumber: props.data ? props.data.identificationNumber : null,
    contactNumber: props.data ? props.data.contactNumber : null,
    email: props.data ? props.data.email : null,
    address: props.data ? props.data.address : null,
    occupation: props.data ? props.data.occupation : null,
    employer: props.data ? props.data.employer : null,
    isActive: props.data ? props.data.isActive : 1,
    emergencyContactName: props.data ? props.data.emergencyContactName : null,
    emergencyContactNumber: props.data
      ? props.data.emergencyContactNumber
      : null,
    relationshipWithEmergencyContact: props.data
      ? props.data.relationshipWithEmergencyContact
      : null,
    notes: props.data ? props.data.notes : null,
  };

  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const genderResponse = await graphqlQuery(
          allGenders,
          setupManagementUrl.uri
        );
        if (genderResponse) {
          setGenderData(genderResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);
  
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required").nullable(),
    contactNumber: yup
      .string()
      .required("Contact Number is required")
      .nullable(),
    email: yup.string().required("Email is required").nullable(),
    genderId: yup.string().required("Gender is required").nullable(),
  });

  const genderOptions = genderData
    ? genderData.map((gender) => ({
        parent_key: gender.id,
        value: gender.id,
        label: gender.gender,
      }))
    : [];

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const {
        firstName,
        lastName,
        dateOfBirth,
        email,
        contactNumber,
        nationality,
        identificationType,
        identificationNumber,
        genderId,
      } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            firstName,
            lastName,
            dateOfBirth,
            email,
            contactNumber,
            nationality,
            identificationType,
            identificationNumber,
            genderId,
          }
        : {
            firstName,
            lastName,
            dateOfBirth,
            email,
            contactNumber,
            nationality,
            identificationType,
            identificationNumber,
            genderId,

            createdBy: userId,
          };

      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateIndividualClient" : "createIndividualClient",
        mutationData,
        props.isEditing ? "updatedIndividualClient" : "newIndividualClient",
        base_url
      );

      if (response) {
        props.onClose();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Client ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        }).then(() => {
          props.refetchData(); // Close the modal after the success message
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  const fields = [
    ...clientsField,
    {
      name: "genderId",
      label: "Gender *",
      type: "select",
      options: genderOptions,
      gridColumn: "span 2",
      required: true,
    },
  ];
  return (
    <div>
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={props.isEditing ? "Edit Individual Client" : "Create Individual Client"}
      subtitle={
        props.isEditing ? "Edit an Existing Individual client" : "Create a New Individual Client"
      }
    />
  </div>
  );
};

export default IndividualClientsForm;
