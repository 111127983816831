import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { setupManagementUrl } from "../../../../config";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../../data/Axios/DynamicService";
import DynamicForm from "../../../../data/Axios/DynamicForm";

const base_url = setupManagementUrl.uri;

const TransactionTypeForm = (props) => {
  const initialValues = {
    id: props.data ? props.data.id : "",
    description: props.data ? props.data.description : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { description } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            description,
          }
        : {
            description,
          };

      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateTransactionType" : "createTransactionType",
        mutationData,
        "type",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Transaction Type ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  const fields = [
    {
      name: "description",
      label: "Description *",
      type: "text",
      gridColumn: "span 2",
      required: true,
    },
  ];

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={
        props.isEditing ? "Edit Transaction Type" : "Create Transaction Type"
      }
      subtitle={
        props.isEditing
          ? "Edit an Existing Transaction Type"
          : "Create a New Transaction Type"
      }
    />
  );
};

export default TransactionTypeForm;
