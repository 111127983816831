import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation,useQuery } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import { CREATE_GENDER, UPDATE_GENDER, ALL_GENDERS } from "../../../../data/SetupManagementData";

const GenderForm = (props) => {
  const {
    loading: genderLoading,
    error: genderError,
    data: genderData,
  } = useQuery(ALL_GENDERS);

  const [createGender] = useMutation(CREATE_GENDER, {
    onCompleted: () => {
      props.onClose();
      props.refetch();//edited
      swal("Success!", "Gender has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create gender, try again later", "error");
    },
  });

  const [updateGender] = useMutation(UPDATE_GENDER, {
    onCompleted: (data/*why*/) => {
      props.onClose();
      props.refetch();//edited
      swal("Success!", "Gender has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update gender, try again later", "error");
    },
  });

  const initialValues = {
    gender: props.genderData ? props.genderData.gender : "",
  };

  const validationSchema = yup.object().shape({
    gender: yup.string().required("Gender is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { gender } = values;

      if (props.isEditing) {
        await updateGender({
          variables: {
            id: props.genderData.id,
            gender,
          },
        });
        console.log("Gender updated successfully!");
      } else {
        await createGender({
          variables: {
            gender,
          },
        });
        console.log("Gender created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Gender" : "Create Gender"}
        subtitle={
          props.isEditing ? "Edit an Existing Gender" : "Create a New Gender"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Gender *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.gender}
                name="gender"
                error={touched.gender && !!errors.gender}
                helperText={touched.gender && errors.gender}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default GenderForm;
