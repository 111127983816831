export const NotificationTemplateColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "messageTemplate", headerName: "Message Template", flex: 1 },
  { field: "notificationType", headerName: "Notification Type", flex: 1 },
];

export const AllEmailTemplatesColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "messageTemplate", headerName: "Message Template", flex: 1 },
];

export const AllSmsTemplateColumn = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "messageTemplate", headerName: "Message Template", flex: 1 },
  { field: "status", headerName: "Status", flex: 1 },
];

export const EmployeePaymentTypesColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "paymentType", headerName: "Payment Type", flex: 1 },
];
