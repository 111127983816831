import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { TitlesColumns } from "../../../../data/Columns/SystemSetupColumns";
import { TitleFSColumns } from "../../../../data/Columns/FSColumns";
import { allFSTitles } from "../../../../data/Queries/SetupManagement/FinSightsQueries";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Title",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
};
function AllTitles() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="All Titles"
          subtitle="View, create or edit titles "
          columns={TitleFSColumns}
          //FormComponent={}
          query={allFSTitles}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default AllTitles;
