import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { tokens } from "../../../theme";
import { Avatar, CircularProgress, Divider, Grid } from "@mui/material";
import avatorImage from "../../../Assets/Images/profile-placeholder.png";
import {
  fetchDataEngine,
  fetchDataEngineCustomized,
  graphqlQuery,
} from "../../../data/Axios/DynamicService";
import {
  reportManagementUrl,
  setupManagementUrl,
  userManagementUrl,
} from "../../../config";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  allInsightType,
  allTransactionType,
} from "../../../data/Queries/SetupManagement/FinSightsQueries";
import {
  AccordionExpandDefault,
  InsightAccordionExpandDefault,
} from "./Accordion";
import PropTypes from "prop-types";
import { SummaryTable, TransactionsTable } from "./tabletest";

import { generateReport } from "../../../data/DynamicTable/DynamicFunctions";
import Swal from "sweetalert2";
import { allBankStatements } from "../../../data/Axios/queries";
const base_url = reportManagementUrl.uri;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          sx={{ backgroundColor: colors.primary[400], minHeight: "80vh" }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function ViewBankReport() {
  const [tabIndex, setTabIndex] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [insightTypes, setInsightTypes] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [statementBYIdData, setStatementBYIdData] = useState([]);
  const [creditScore, setCreditScore] = useState([]);
  const [opoData, setOpoData] = useState([]);

  const statementdata = JSON.parse(localStorage.getItem("statementData"));
  const [transactionTypesSummary, setTransactionTypesSummary] = useState([]);
  const [insightTypesSummary, setInsightTypesSummary] = useState([]);
  const [otherDataSummary, setOtherDataSummary] = useState([]);
  const [cleanedSummary, setCleanedSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };
  useEffect(() => {
    async function fetchStatementData() {
      const token = localStorage.getItem("token");
      const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;
  
      const headers = {
        "Content-Type": "application/json",
        Authorization: formattedToken,
        "Access-Control-Allow-Headers": "authorization,content-type",
        "Access-Control-Allow-Methods": "POST",
        "Access-Control-Allow-Origin": "*",
        Origin: "https://finsights.mcb.co.ke",
        referrerPolicy: "no-referrer-when-downgrade",
      };
  
      try {
        // Fetch data from your GraphQL API
        const response = await fetch(base_url, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            query: allBankStatements,
          }),
        });
  
        const jsonResponse = await response.json();
        const facts = jsonResponse.data.bankStatementsView;
  
        // Set the raw statement data
        setStatementBYIdData(facts[0]);
  
        // Parse and clean the dynamic identifier
        if (facts[0] && facts[0].dynamicIdentifier) {
          let parsedDynamicIdentifier = {};
          try {
            parsedDynamicIdentifier = JSON.parse(facts[0].dynamicIdentifier); // Correct reference to facts[0]
          } catch (error) {
            console.error("Error parsing dynamicIdentifier:", error);
          }
  
          // Use parsed data if needed
          setCleanedSummary(parsedDynamicIdentifier); // Store parsed dynamicIdentifier in cleanedSummary
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchStatementData();
  }, [base_url]);
  
  useEffect(() => {
    async function fetchCreditScore() {
      try {
        const returnFields = [
          "creditscore",
          "totalScore",
          "riskCategory",
          "loanableMonthly",
          "loanableWeekly",
        ];
        const OpoReturnFields = [
          "binnedScore",
          "creditScore",
          "riskCategory",
          "activeLoansCount",
          "activeLoansScore",
          "binnedScoreAverage",
          "nonPerformingLoansCount",
          "nonPerformingLoansScore",
        ];
        const inputFields = { pStatementId: statementdata.id };
        const Facts = await fetchDataEngine(
          "creditScore",
          "functionParams",
          inputFields,
          returnFields,
          base_url
        );
        setCreditScore(Facts);
        const inputOpoFields = { paramstatementid: statementdata.id };
        const OOpoData = await fetchDataEngine(
          "opoData",
          "functionParams",
          inputOpoFields,
          OpoReturnFields,
          base_url
        );
        setOpoData(OOpoData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchCreditScore();
  }, []);
  const fetchData = async () => {
    try {
      const inputFields = { p_StatementId: statementdata.id };
      const returnFields = ["category", "name", "transactionsData"];

      const transactions = await fetchDataEngine(
        "bankCombinedFrequentData",
        "functionParams",
        inputFields,
        returnFields,
        base_url
      );

      return transactions;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  // Clean and structure the data
  const cleanData = (transactions) => {
    return transactions.map((item) => {
      let cleanedTransactions = [];

      try {
        // Parse transactionsData JSON string
        const transactionsList = JSON.parse(item.transactionsData);

        // Example of cleaning: removing null or empty fields
        cleanedTransactions = transactionsList.map((transaction) => ({
          ...transaction,
          Names: transaction.Names || "N/A",
          Account: transaction.Account || "N/A",
          Total: transaction.Total !== null ? transaction.Total : 0,
          Highest: transaction.Highest !== null ? transaction.Highest : 0,
        }));
      } catch (error) {
        console.error("Error parsing transactionsData:", error);
      }

      return {
        category: item.category,
        name: item.name,
        transactionsData: cleanedTransactions,
      };
    });
  };

  // Separate data based on category and store in different states
  const categorizeData = (cleanedData) => {
    const transactions = cleanedData.filter(
      (item) => item.category === "TransactionType"
    );
    const insights = cleanedData.filter((item) => item.category === "Insight");
    const others = cleanedData.filter(
      (item) =>
        item.category !== "TransactionType" && item.category !== "Insight"
    );

    setTransactionTypes(transactions);
    setInsightTypes(insights);
    setOtherData(others);
  };

  // Fetch and clean data on component mount or when dependencies change
  useEffect(() => {
    async function fetchDataAndProcess() {
      const fetchedData = await fetchData();
      const cleanedData = cleanData(fetchedData);
      categorizeData(cleanedData);
    }

    fetchDataAndProcess();
  }, [statementdata.id, base_url]);

  const fetchSummaryData = async () => {
    try {
      const inputFields = { statementId: statementdata.id };
      const returnFields = ["category", "name", "summary"];

      const data = await fetchDataEngine(
        "bankCombinedSummary",
        "functionParams",
        inputFields,
        returnFields,
        base_url
      );

      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  // Function to clean and structure the data
  const cleanedSummaryData = (data) => {
    return data.map((item) => {
      let cleanedSummary = {};

      try {
        // Parse summary JSON string
        if (item.summary) {
          cleanedSummary = JSON.parse(item.summary);
        } else {
          cleanedSummary = {
            totalCount: cleanedSummary.TotalCount || 0,
            lowestTotalToAccount: cleanedSummary.LowestTotalToAccount || 0,
            highestTotalToAccount: cleanedSummary.HighestTotalToAccount || 0,
            lowestTotalFromAccount: cleanedSummary.LowestTotalFromAccount || 0,
            highestTotalFromAccount:
              cleanedSummary.HighestTotalFromAccount || 0,
            lowestSingleTransaction:
              cleanedSummary.LowestSingleTransaction || 0,
            highestSingleTransaction:
              cleanedSummary.HighestSingleTransaction || 0,
            highestTotalToAccountName:
              cleanedSummary.HighestTotalToAccountName || "N/A",
            highestTotalFromAccountName:
              cleanedSummary.HighestTotalFromAccountName || "N/A",
            highestSingleTransactionName:
              cleanedSummary.HighestSingleTransactionName || "N/A",
            highestTotalToAccountAccount:
              cleanedSummary.HighestTotalToAccountAccount || "N/A",
            highestTotalFromAccountAccount:
              cleanedSummary.HighestTotalFromAccountAccount || "N/A",
            highestSingleTransactionAccount:
              cleanedSummary.HighestSingleTransactionAccount || "N/A",
          };
        }
        // Example of cleaning: setting default values for null or missing fields
      } catch (error) {
        console.error("Error parsing summary:", error);
      }

      return {
        category: item.category,
        name: item.name,
        summary: cleanedSummary,
      };
    });
  };

  // Function to separate data based on category
  const categorizeSummaryData = (cleanedData) => {
    const transactions = cleanedData.filter(
      (item) => item.category === "transactiontype"
    );
    const insights = cleanedData.filter((item) => item.category === "insight");
    const others = cleanedData.filter(
      (item) =>
        item.category !== "transactiontype" && item.category !== "insight"
    );

    setTransactionTypesSummary(transactions);
    setInsightTypesSummary(insights);
    setOtherDataSummary(others);
  };

  // useEffect to fetch and process data on component mount or when dependencies change
  useEffect(() => {
    const processData = async () => {
      const rawData = await fetchSummaryData();
      const cleanedData = cleanedSummaryData(rawData);
      categorizeSummaryData(cleanedData);
    };

    processData();
  }, []);
  const handleChangePassword = async (event) => {
    event.preventDefault();
    // Add your logic to handle password change here
    console.log("Password changed");
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? colors.blueAccent[900] : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: "start",
    height: "100%",
    color: theme.palette.mode === "dark" ? "#fff" : "#000",
  }));

  const getTabStyle = (isSelected) => ({
    borderRadius: "0.75rem",
    border:
      theme.palette.mode === "dark" ? "1px solid #70d8bd" : "1px solid #1F2A40",
    margin: "0.3rem",
    color: isSelected
      ? theme.palette.mode === "dark"
        ? colors.primary[900]
        : theme.palette.getContrastText(colors.primary[400])
      : "inherit",
    backgroundColor: isSelected ? colors.greenAccent[400] : "inherit",
    "&.Mui-selected": {
      color:
        theme.palette.mode === "dark"
          ? colors.primary[400]
          : colors.primary[900],
    },
    paddingLeft: "1rem",
    paddingRight: "1rem",
  });
  const DetailItem = ({ label, value, backgroundColor, textColor }) => (
    <p style={{ marginLeft: "10px", fontSize: "16px" }}>
      {label}:{" "}
      <span
        style={{
          backgroundColor,
          color: textColor,
          padding: "2px 4px",
          borderRadius: "4px",
        }}
      >
        {value}
      </span>
    </p>
  );
  const getColorByRisk = (creditScore) => {
    if (creditScore >= 800) {
      return { backgroundColor: "#FFFFFF", textColor: "#00C853" };
    } else if (creditScore >= 700) {
      return { backgroundColor: "#FFFFFF", textColor: "#64DD17" };
    } else if (creditScore >= 600) {
      return { backgroundColor: "#FFFFFF", textColor: "#FFD600" };
    } else if (creditScore >= 500) {
      return { backgroundColor: "#FFFFFF", textColor: "#FFD600" };
    } else {
      return { backgroundColor: "#FFFFFF", textColor: "#D50000" };
    }
  };

  const colorScheme = getColorByRisk(creditScore.creditScore);
  // Usage in the component
  const handleSubmit = async () => {
    try {
      const mutationData = {
        StatementId: statementdata.id,
      };

      // Open a new tab immediately to avoid the pop-up blocker
      const newTab = window.open("", "_blank");

      // Write a simple loading message or animation to the newly opened tab
      newTab.document.write(`
        <html>
          <head>
            <title>Loading...</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
                background-color: #f0f0f0;
              }
              .loading {
                font-size: 24px;
                color: #333;
              }
            </style>
          </head>
          <body>
            <div class="loading">Loading, please wait...</div>
          </body>
        </html>
      `);
      newTab.document.close(); // Necessary to complete the document creation

      const response = await generateReport(
        mutationData,
        reportManagementUrl.fs2_engine_uri,
        setIsLoading
      );

      if (response.ok) {
        console.log("response", response);
        const htmlContent = await response.text();
        console.log("htmlContent", htmlContent);

        // Replace the loading message with the actual content
        newTab.document.open(); // Reopen the document to overwrite it
        newTab.document.write(htmlContent);
        newTab.document.close();
      } else {
        console.error("Error:", response.statusText);
        // Close the tab if there was an error in the response
        newTab.close();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  return (
    <Box
      m="20px"
      p="20px"
      sx={{
        bgcolor: `${colors.primary[400]}`,
        border:
          theme.palette.mode === "dark"
            ? "1px solid #70d8bd"
            : "1px solid #1F2A40",
        borderRadius: "0.75rem",
      }}
    >
      <Box
        m={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" gutterBottom>
          Report
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isLoading && <CircularProgress size={24} sx={{ marginRight: 2 }} />}
          <Button
            variant="contained"
            size="md"
            sx={{
              backgroundColor: colors.greenAccent[500],
              borderRadius: "4px",
            }}
            onClick={handleSubmit}
            disabled={isLoading} // Optional: Disable the button while loading
          >
            <Typography sx={{ color: colors.primary[400], mx: 1 }}>
              {isLoading ? "Generating..." : "Generate Report"}
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box sx={{ position: "relative", bottom: 0 }}>
        <Grid container spacing={1} direction="row">
          <Grid container spacing={1}>
            {/* First row: Basic Details and Credit Score */}
            <Grid container item spacing={1} xs={12}>
            <Grid item xs={6} md={6}>
  <Item>
    <Typography
      color={colors.greenAccent[500]}
      variant="h4"
      gutterBottom
      sx={{ fontSize: "24px" }}
    >
      Basic Details
    </Typography>
    <Divider color={colors.grey[500]} style={{ width: "100%", marginBottom: "10px" }} />

    {/* Display customer details */}
    <h6 style={{ marginLeft: "10px", fontSize: "18px" }}>
      Account Name: {cleanedSummary?.["Account Name"]} {/* Parsed from dynamicIdentifier */}
    </h6>
    <p style={{ marginLeft: "10px", fontSize: "16px" }}>
    Account Number: {cleanedSummary?.["Account"]} {/* Parsed */}
    </p>
    <p style={{ marginLeft: "10px", fontSize: "16px" }}>
      Available Balance: {cleanedSummary?.["Available Balance"]} {/* Parsed */}
    </p>
    <p style={{ marginLeft: "10px", fontSize: "16px" }}>
    Period: {cleanedSummary?.["Period"]} {/* Parsed */}
    </p>
    <p style={{ marginLeft: "10px", fontSize: "16px" }}>
      Bank Name: {statementBYIdData.bankName}
    </p>
  </Item>
</Grid>
              <Grid item xs={6} md={6}>
                <Item>
                  <Typography
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      fontSize: "24px",
                    }} // Adjusted font size
                    color={colors.greenAccent[500]}
                    variant="h4" // Changed from h5 to h4 for larger text
                    gutterBottom
                  >
                    Credit Score
                  </Typography>

                  <Divider color={colors.grey[500]} />
                  <DetailItem
                    label="Credit Score"
                    value={creditScore.creditscore}
                    // backgroundColor={colorScheme.backgroundColor}
                    textColor={colorScheme.textColor}
                  />

                  <DetailItem
                    label="Total Score"
                    value={creditScore.totalScore}
                    textColor="#5d9fc5"
                  />
                  <DetailItem
                    label="Risk Category"
                    value={creditScore.riskCategory}
                    textColor={colorScheme.textColor}
                  />

                  <DetailItem
                    label="Loanable Monthly"
                    value={creditScore.loanableMonthly}
                    textColor="#5d9fc5"
                  />
                  <DetailItem
                    label="Loanable Weekly"
                    value={creditScore.loanableWeekly}
                    textColor="#5d9fc5"
                  />
                </Item>
              </Grid>
            </Grid>

            {/* Second row: Summary */}
            <Grid container spacing={1} marginTop={"1px"} marginLeft={"1px"}>
              <Grid item xs={6} md={6}>
                <Item>
                  <Typography
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      fontSize: "24px",
                    }} // Adjusted font size
                    color={colors.greenAccent[500]}
                    variant="h4" // Changed from h5 to h4 for larger text
                    gutterBottom
                  >
                    OPO Data
                  </Typography>

                  <Divider color={colors.grey[500]} />

                  <DetailItem
                    label="Binned Score"
                    value={opoData.binnedScore}
                    textColor="#5d9fc5"
                  />
                  <DetailItem
                    label="Credit Score"
                    value={opoData.creditScore}
                    textColor={colorScheme.textColor}
                  />

                  <DetailItem
                    label="Risk Category"
                    value={opoData.riskCategory}
                    textColor="#5d9fc5"
                  />
                  <DetailItem
                    label="Active Loan Counts"
                    value={opoData.activeLoansCount}
                    textColor="#5d9fc5"
                  />
                  <DetailItem
                    label="Active Loan Score"
                    value={opoData.activeLoansScore}
                    textColor="#5d9fc5"
                  />
                  <DetailItem
                    label="Binned Score Average"
                    value={opoData.binnedScoreAverage}
                    textColor="#5d9fc5"
                  />

                  <DetailItem
                    label="non Performing Loans Count"
                    value={opoData.nonPerformingLoansCount}
                    textColor="#5d9fc5"
                  />

                  <DetailItem
                    label="Non Performing Loans Score"
                    value={opoData.nonPerformingLoansScore}
                    textColor="#5d9fc5"
                  />
                </Item>
              </Grid>
              <Grid item xs={6} md={6}>
                <Item>
                  <Typography
                    color={colors.greenAccent[500]}
                    variant="h4" // Changed from h5 to h4 for larger text
                    gutterBottom
                    sx={{ fontSize: "24px" }} // Added custom font size
                  >
                    Summary 2
                  </Typography>
                  <Divider
                    style={{ width: "100%", marginBottom: "10px" }}
                    color={colors.grey[500]}
                  />
                  <SummaryTable data={cleanedSummary} />
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        aria-label="person details tabs"
        sx={{ borderBottom: 1, borderColor: "divider", p: 1 }}
      >
        <Tab
          label="Transactions"
          style={getTabStyle(tabIndex === 0)}
          sx={{ p: 1 }}
        />
        <Tab label="Insights " style={getTabStyle(tabIndex === 1)} />
      </Tabs>
      <TabPanel className="w-100" value={tabIndex} index={0}>
        <TransactionsTable
          data={transactionTypes}
          summary={transactionTypesSummary}
        />
      </TabPanel>
      <TabPanel className="w-100" value={tabIndex} index={1}>
        <Typography
          variant="h6"
          style={{ paddingLeft: "4px", backgroundColor: colors.primary[400] }}
        ></Typography>
        <TransactionsTable data={insightTypes} summary={insightTypesSummary} />
      </TabPanel>
    </Box>
  );
}

export default ViewBankReport;
