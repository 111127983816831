import React from "react";
import { Box, Button, TextField, Select, MenuItem, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import { CREATE_IDENTIFICATION_TYPE, UPDATE_IDENTIFICATION_TYPE, ALL_IDENTIFICATION_TYPES } from "../../../../data/SetupManagementData";

const IdentificationForm = (props) => {
  const {
    loading: identificationLoading,
    error: identificationError,
    data: identificationData,
  } = useQuery(ALL_IDENTIFICATION_TYPES);

  const [createIdentificationType] = useMutation(CREATE_IDENTIFICATION_TYPE, {
    onCompleted: (data) => {
      props.onClose()
      props.refetch()
      swal("Success!", "Identification Type has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create identification type, try again later", "error");
    },
  });

  const [updateIdentificationType] = useMutation(UPDATE_IDENTIFICATION_TYPE, {
    onCompleted: () => {
      props.onClose()
      props.refetch()
      swal("Success!", "Identification Type has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update identification type, try again later", "error");
    },
  });

  const initialValues = {
    identificationType: props.identificationData ? props.identificationData.identificationType : "",
    // companyId: props.identificationData ? props.identificationData.companyId : "",
    // company: props.identificationData ? props.identificationData.company : "",
  };

  const validationSchema = yup.object().shape({
    identificationType: yup.string().required("Identification Type is required"),
    // companyId: yup.number().required("Company ID is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { identificationType } = values;

      if (props.isEditing) {
        await updateIdentificationType({
          variables: {
            id: props.identificationData.id,
            identificationType,
            // companyId,
            // company,
          },
        });
        console.log("Identification Type updated successfully!");
      } else {
        await createIdentificationType({
          variables: {
            identificationType,
            // companyId,
            // company,
          },
        });
        console.log("Identification Type created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Identification Type" : "Create Identification Type"}
        subtitle={
          props.isEditing ? "Edit an Existing Identification Type" : "Create a New Identification Type"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Identification Type *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.identificationType}
                name="identificationType"
                error={touched.identificationType && !!errors.identificationType}
                helperText={touched.identificationType && errors.identificationType}
              />

              {/* <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Company ID"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.companyId}
                name="companyId"
                error={touched.companyId && !!errors.companyId}
                helperText={touched.companyId && errors.companyId}
              /> */}

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default IdentificationForm;
