import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { generateMutation, graphqlMutation } from "../../../data/Axios/DynamicService";
import { setupManagementUrl } from "../../../config";

const base_url = setupManagementUrl.uri;

const MajorClassificationForm = (props) => {
  const initialValues = {
    majorClassification: props.data ? props.data.majorClassification : "",
    id: props.data ? props.data.id : "",
  };

  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const validationSchema = yup.object().shape({
    majorClassification: yup.string().required("Major classification is required"),
  });

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { majorClassification } = values;
      const mutationName = props.isEditing ? "updateReportsMajorClassification" : "createReportsMajorClassification";
      const inputObjectName = props.isEditing ? "updateReportsMajorClassification" : "createReportsMajorClassification";
      const InputObject = props.isEditing ? "majorClassification" : "majorClassification";
      const mutationData = props.isEditing
        ? { id: props.data.id, majorClassification, createdBy: userId }
        : { majorClassification, createdBy: userId };

      const mutation = generateMutation(
        mutationName,
        inputObjectName,
        mutationData,
        InputObject
      );

      if (mutation) {
        await graphqlMutation(mutation, base_url);
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Major report ${props.isEditing ? "updated" : "created"} successfully.`,
        }).then(() => {
          props.refetchData();
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  return (
    <Box m="10px">
      <Header
        title={props.isEditing ? "Edit Major Classification" : "Create Major Classification"}
        subtitle={props.isEditing ? "Edit an Existing Major Classification" : "Create a New Major Classification"}
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Major classification *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.majorClassification}
                name="majorClassification"
                error={touched.majorClassification && !!errors.majorClassification}
                helperText={touched.majorClassification && errors.majorClassification}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default MajorClassificationForm;
