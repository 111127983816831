import { Box } from "@mui/material";
import { caseManagementUrl } from "../../../../config";
import { useEffect, useState } from "react";
import { fetchDataEngine } from "../../../../data/Axios/DynamicService";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { CaseEventColumns } from "../../../../data/DynamicTable/Collumns";
import CaseEventForm from "./Form";
import { PulseLoader } from "react-spinners";

const base_url = caseManagementUrl.uri;

const actions = {
  entity: "Event Recording",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
  showStatus: true,
  dataObject: {
    inputObjectName: "allCaseEventsByColumnValues",
    inputSubObjectName: "caseEvent",
    returnFields: [
      "id",
      "caseId",
      "eventTypeId",
      "eventType",
      "eventDate",
      "outcome",
      "hearingDate",
      "description",
      "createdBy",
      "createdDate",
      "companyId",
      "company",
    ],
  },
};

function CaseEvents() {
 

  return (
    <Box m="2px">
      <>
      
          <DynamicTable
            title="Case Events"
            subtitle="View all Case Events, create or edit"
            columns={CaseEventColumns}
            FormComponent={CaseEventForm}
           base_url={base_url}
            actions={actions}
          
          />
  
      </>
    </Box>
  );
}

export default CaseEvents;
