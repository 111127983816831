import { Box, Typography, useTheme, Grid, Chip } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 10px">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Box sx={{ margin: "0 1rem 0 0" }}>{icon} </Box>
          <Typography variant="h6" sx={{ color: colors.grey[200] }}>
            {subtitle}
          </Typography>

          <Box>{/* <ProgressCircle progress={progress} /> */}</Box>
        </Box>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ color: colors.grey[100], margin: "1px" }}
        >
          {title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="end" mt="2px">
        <Grid item>
          <Chip
            variant="outlined"
            size="medium"
            sx={{ color: colors.blueAccent[200] }}
            label={
              <Typography
                // variant="h6"
                fontStyle="italic"
                sx={{ color: colors.greenAccent[400] }}
              >
                {increase}
              </Typography>
            }
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default StatBox;
