import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { setupManagementUrl } from "../../../../config";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../../data/Axios/DynamicService";
import { allInsightType } from "../../../../data/Queries/SetupManagement/FinSightsQueries";
import DynamicForm from "../../../../data/Axios/DynamicForm";

const base_url = setupManagementUrl.uri;

const InsightKeywordForm = (props) => {
  const [insightData, setInsightData] = useState([]);

  const initialValues = {
    id: props.data ? props.data.id : "",
    keyWord: props.data ? props.data.keyWord : "",
    insightTypeId: props.data ? props.data.insightTypeId : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { keyWord, insightTypeId } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            keyWord,
            insightTypeId,
          }
        : {
            keyWord,
            insightTypeId,
          };

      const response = await generateAndExecuteMutation(
        props.isEditing ? "updateInsightKeywords" : "createInsightKeywords",
        mutationData,
        "insightKeyword",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Insight Keyword ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };
  useEffect(() => {
    async function fetchAllData() {
      try {
        const response = await graphqlQuery(allInsightType, base_url);

        if (response) {
          setInsightData(response || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchAllData();
  }, []);
  const insightTypeOptions = insightData
    ? insightData.map((data) => ({
        value: data.id,
        label: data.description,
      }))
    : [];
  const fields = [
    {
      name: "keyWord",
      label: "Keyword *",
      type: "text",
      gridColumn: "span 2",
      required: true,
    },

    {
      name: "insightTypeId",
      label: "Insight Type*",
      type: "select",
      options: insightTypeOptions,
      gridColumn: "span 2",
      required: true,
    },
  ];
  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={
        props.isEditing ? "Edit Insight Keyword" : "Create Insight Keyword"
      }
      subtitle={
        props.isEditing
          ? "Edit an Existing Insight Keyword"
          : "Create a New Insight Keyword"
      }
    />
  );
};

export default InsightKeywordForm;
