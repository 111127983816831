
import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { allFactCategories } from "../../../../data/Queries/SetupManagement/OtherSetupsQueries";
import {FactCategoryCoumn } from "../../../../data/Columns/otherSetupColumns";
import FactCategoryForm from "./Form";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Fact Category",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: true,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
};
function FactCategories() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Fact Category"
          subtitle="Manage Fact Category"
          columns={FactCategoryCoumn}
          FormComponent={FactCategoryForm}
          query={allFactCategories}
          base_url={base_url}
          actions={actions}
          getRowId={(row) => row.categoryId}
        />
      </>
    </Box>
  );
}

export default FactCategories;