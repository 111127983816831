export const EmailsByCompanyColumns = [
  { field: "messageId", headerName: "ID", flex: 1 },
  { field: "recipientEmail", headerName: "Recipient Email", flex: 1 },
  { field: "subjectTitle", headerName: "Subject Title", flex: 2},

];
export const SmsByCompanyColumns = [
  { field: "messageId", headerName: "ID", flex: 1 },
  { field: "phoneNumber", headerName: "Phone Number", flex: 1 },

];
