import React, { useState, useEffect } from "react";
import { CircularProgress, useTheme } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { tokens } from "../theme";

import { companyManagementUrl, reportManagementUrl } from "../config";
import { fetchDataEngine, graphqlQuery } from "../data/Axios/DynamicService";

const BarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const base_url = reportManagementUrl.uri;

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        setLoading(true); // Ensure loading state is set to true while data is being fetched

        const recentTaskFields = ["x", "y", "color"];
        const recentTaskInputFields = { userId };
        const recentTasks = await fetchDataEngine(
          "recentStatementsCountsLineChart",
          "functionParams",
          recentTaskInputFields,
          recentTaskFields,
          base_url
        );

        // Transform data to fit the format required by ResponsiveBump
        const transformedData = [
          {
            id: "statements",
            data: recentTasks.map((task) => ({
              x: task.x,
              y: task.y,
            })),
          },
        ];

        console.log("Fetched and Transformed Data:", transformedData); // Log the transformed data
        setData(transformedData); // Update the state with the transformed data
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Also set loading to false in case of an error
        setError(error); // Optional: You may also want to handle the error state
      }
    };

    fetchSummaryData();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <p>Error loading data</p>;

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      curve="monotoneX" // Smooth lines
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Month",
        legendOffset: 36,
        legendPosition: "middle",
        format: (value) => new Date(value).getDate(), // Display only the date
        tickValues: "every 1 days", // Show tick marks for every day
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "count",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      lineWidth={2} // Slightly thicker line for visibility
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="data.yFormatted"
      pointLabelYOffset={-12}
      enableCrosshair={true} // Ensure crosshair is enabled for better touch responsiveness
      useMesh={true} // Enhance touch interaction
      enableSlices="x" // Display tooltip across the entire x-axis slice
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: "#333333",
              padding: "9px 12px",
              borderRadius: "4px",
              color: "#ffffff",
            }}
          >
            <div>{`Date: ${slice.points[0].data.x}`}</div>
            <div>{`Count: ${slice.points[0].data.yFormatted}`}</div>
          </div>
        );
      }}
      theme={{
        background: "transparent", // No background
        textColor: colors.grey[100], // Set text color to white
        axis: {
          domain: {
            line: {
              stroke: "#ffffff", // White lines for the axis
            },
          },
          ticks: {
            line: {
              stroke: "#ffffff", // White tick lines
            },
            text: {
              fill: "#ffffff", // White tick text
            },
          },
          legend: {
            text: {
              fill: "#ffffff", // White legend text
            },
          },
        },
        grid: {
          line: {
            stroke: "transparent", // Hide grid lines
          },
        },
        tooltip: {
          container: {
            background: "#333333", // Dark background for the tooltip
            color: "#ffffff", // White text for the tooltip
          },
        },
      }}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(255, 255, 255, .5)", // Adjusted for white border in dark mode
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(255, 255, 255, .1)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default BarChart;
