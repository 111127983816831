import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import DynamicForm from "../../../data/Axios/DynamicForm";
import Swal from "sweetalert2";
import {
  allReportMinorClassifications,
  allReportsMajorClassifications,
} from "../../../data/Axios/queries";
import { setupManagementUrl } from "../../../config";
import {
  generateAndExecuteMutation,
  generateMutation,
  graphqlMutation,
} from "../../../data/Axios/DynamicService";
import { graphqlQuery } from "../../../data/Axios/DynamicService";

const MinorReportForm = (props) => {
  const base_url = setupManagementUrl.uri;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [major, setMajor] = useState([]);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const Response = await graphqlQuery(
          allReportMinorClassifications,
          setupManagementUrl.uri
        );
        if (Response) {
          setData(Response);
        }
        const MajorResponse = await graphqlQuery(
          allReportsMajorClassifications,
          setupManagementUrl.uri
        );
        if (MajorResponse) {
          setMajor(MajorResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);

  const majorOptions = major
    ? major.map((item) => ({
        parent_key: item.id,
        value: item.id,
        label: item.majorClassification,
      }))
    : [];

  const fields = [
    {
      name: "majorClassificationID",
      label: "Major Classification",
      type: "select",
      options: majorOptions,
      required: false,
      parent: "main",
      gridColumn: "span 2",
    },
    {
      name: "minorClassification",
      label: "Minor Classification",
      type: "text",
      required: true,
      gridColumn: "span 2",
    },
  ];

  const initialValues = {
    majorClassificationID: props.data ? props.data.majorClassificationID : "",
    minorClassification: props.data ? props.data.minorClassification : "",
  };

  const onFieldChange = async (field, value) => {
    if (field.name === "majorReport") {
      // Simulate fetching dependent options based on the selected major report
      const dependentOptions = [
        { value: "subReport1", label: "Sub Report 1" },
        { value: "subReport2", label: "Sub Report 2" },
      ];
      return { minorReport: dependentOptions };
    }
    return {};
  };
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { majorClassificationID, minorClassification } = values;
      const majorClassification =
        major.find((item) => item.id === majorClassificationID)
          ?.majorClassification || null;
      const mutationName = props.isEditing
        ? "updateReportsMinorClassification"
        : "createReportsMinorClassification";
      const inputObjectName = props.isEditing
        ? "updateReportsMinorClassification"
        : "createReportsMinorClassification";
      const InputObject = props.isEditing
        ? "minorClassification"
        : "minorClassification";
      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            majorClassificationID,
            majorClassification,
            minorClassification,
            createdBy: userId,
          }
        : {
            minorClassification,
            majorClassificationID,
            majorClassification,
            createdBy: userId,
          };

      const mutation = generateMutation(
        mutationName,
        inputObjectName,
        mutationData,
        InputObject
      );
      console.log("mutation", mutation);

      if (mutation) {
        await graphqlMutation(mutation, base_url);
        props.onAction();
        props.onClose();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Module ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };
  return (
    <DynamicForm
      fields={fields}
      onSubmit={handleSubmit}
      title="Minor Report Form"
      subtitle="Fill in the details for the minor report"
      initialValues={initialValues}
      onFieldChange={onFieldChange}
    />
  );
};

export default MinorReportForm;
