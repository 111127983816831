import React from "react";
import {
  Box,
  Button,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation ,useQuery } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {ALL_DEPARTMENTS,
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "../../../../data/SetupManagementData"; 

const DepartmentForm = (props) => {
  
  const {
    loading,
    error,
  } = useQuery(ALL_DEPARTMENTS);

  const [createDepartment] = useMutation(CREATE_DEPARTMENT, {
    onCompleted: (data) => {
      props.onClose();
      props.onAction();
      swal("Success!", "Department has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create department, try again later", "error");
    },
  });

  const [updateDepartment] = useMutation(UPDATE_DEPARTMENT, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal("Success!", "Department has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update department, try again later", "error");
    },
  });

  const initialValues = {
    department: props.data ? props.data.department : "",
  };

  const validationSchema = yup.object().shape({
    department: yup.string().required("Department is required"),
  });
  
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { department } = values;
  
      if (props.isEditing) {
        await updateDepartment({
          variables: {
            id: props.data.id,
            department,
          },
        });
        console.log("Department updated successfully!");
      } else {
        await createDepartment({
          variables: {
            department,
          },
        });
        console.log("Department created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  
    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Department" : "Create Department"}
        subtitle={
          props.isEditing ? "Edit an Existing Department" : "Create a New Department"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Department *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.department}
                name="department"
                error={touched.department && !!errors.department}
                helperText={touched.department && errors.department}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default DepartmentForm;
