import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import Swal from "sweetalert2";
import DynamicForm from "../../../../data/Axios/DynamicForm";
import { setupManagementUrl } from "../../../../config";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import * as yup from "yup";
import { Formik } from "formik";
import {
  CREATEBANKSTATEMENTSTYPES,
  CREATETRANSACTIONTYPE,
  UPDATEBANKSTATEMENTTYPES,
  UPDATEBANKTRANSACTIONTYPE,
} from "../../../../data/SetupManagementData";
import { allBanks } from "../../../../data/Axios/queries";
import { graphqlQuery } from "../../../../data/Axios/DynamicService";
import Header from "../../../../components/Header";

const base_url = setupManagementUrl.uri;

const BankTransactionTypesForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const [bankData, setBankData] = useState([]);

  useEffect(() => {
    fetchBankData();
  }, []); // Add an empty dependency array to ensure it only runs once

  const [createBankTransactionTypes] = useMutation(CREATETRANSACTIONTYPE, {
    onCompleted: (data) => {
      props.onClose();
      props.onAction();
      swal("Success!", "Bank has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create bank, try again later", "error");
    },
  });

  const [updateBankTransactionTypes] = useMutation(UPDATEBANKTRANSACTIONTYPE, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal(
        "Success!",
        "Bank Transaction Type has been updated successfully",
        "success"
      );
    },
    onError: () => {
      swal(
        "Error!",
        "Unable to update Bank Transaction Type, try again later",
        "error"
      );
    },
  });

  const fetchBankData = async () => {
    try {
      const response = await graphqlQuery(allBanks, base_url);
      if (response) {
        setBankData(response);
      }
    } catch (error) {
      console.error("Error:", error.message);
      throw error;
    }
  };

  const initialValues = {
    bankId: props.isEditing ? props.data.bankId : "",
    type: props.isEditing ? props.data.type : "",
  };

  const validationSchema = yup.object().shape({
    bankId: yup.string().required("Bank name is required"),
    type: yup.string().required("Type is required"),
  });

  const BankOptions = bankData.map((item) => ({
    key: item.id,
    value: item.id,
    label: item.bankName,
  }));

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { type, bankId } = values;
      const companyId = props.isEditing
        ? props.data.companyId
        : parseInt(decodedToken.CompanyId);

      if (props.isEditing) {
        await updateBankTransactionTypes({
          variables: {
            id: props.data.id,
            bankId,
            type,
            updatedBy: userId,
          },
        });
      } else {
        await createBankTransactionTypes({
          variables: {
            bankId,
            type,
            createdBy: userId,
          },
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
      swal("Error!", `${error.message}`, "error");
    }
    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={
          props.isEditing
            ? "Edit Bank Transaction Type"
            : "Create Bank Transaction Type"
        }
        subtitle={
          props.isEditing
            ? "Edit an Existing Bank Transaction Type"
            : "Create a New Bank Transaction Type"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <FormControl
                fullWidth
                variant="filled"
                error={touched.bankId && !!errors.bankId}
              >
                <InputLabel id="bank-select-label">
                  Choose the bank *
                </InputLabel>
                <Select
                  labelId="bank-select-label"
                  value={values.bankId}
                  name="bankId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {BankOptions.map((option) => (
                    <MenuItem key={option.key} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {touched.bankId && errors.bankId && (
                  <p style={{ color: "red" }}>{errors.bankId}</p>
                )}
              </FormControl>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Type *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                name="type"
                error={touched.type && !!errors.type}
                helperText={touched.type && errors.type}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default BankTransactionTypesForm;
