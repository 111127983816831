import { Box } from "@mui/material";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { TitlesColumns } from "../../../../data/Columns/SystemSetupColumns";
import {
  TitleFSColumns,
  TransactionKeywordsColumns,
} from "../../../../data/Columns/FSColumns";
import {
  allFSTitles,
  allTransactionKeywords,
} from "../../../../data/Queries/SetupManagement/FinSightsQueries";
import TransactionKeywordsForm from "./form";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Transaction Keywords",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  showStatus: true,
};
function TransactionKeywordTable() {
  return (
    <Box m="2px">
      <>
        <DynamicTable
          title="Transaction Keyword"
          subtitle="View, create or transaction Keyword"
          columns={TransactionKeywordsColumns}
          FormComponent={TransactionKeywordsForm}
          query={allTransactionKeywords}
          base_url={base_url}
          actions={actions}
        />
      </>
    </Box>
  );
}

export default TransactionKeywordTable;
