import { useState } from "react";
import { Box, Button, TextField } from "@mui/material";

import { setupManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { InsightKeywordsColumns } from "../../../../data/Columns/FSColumns";
import { allInsightKeyWords, newAllInsightKeyWords } from "../../../../data/Queries/SetupManagement/FinSightsQueries";
import InsightKeywordForm from "./form";

const base_url = setupManagementUrl.uri;
const actions = {
  entity: "Insight Keywords",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: true,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  showStatus: true,
};

function InsightKeywordTable() {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    // Implement search logic, this could involve filtering the query or making a new query with the search term
    console.log("Searching for:", searchTerm);
    // You might want to call a query or filter the current dataset based on searchTerm
  };

  return (
    <Box m="2px">
      <DynamicTable
        title="All Insight keyword"
        subtitle="View, create or edit insight keyword"
        searchTitle={InsightKeywordsColumns.field}
        columns={InsightKeywordsColumns}
        FormComponent={InsightKeywordForm}
        query={newAllInsightKeyWords} // Modify this to reflect the search if needed
        base_url={base_url}
        actions={actions}
        isFunction={false}
      />
    </Box>
  );
}

export default InsightKeywordTable;
