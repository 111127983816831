import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { setupManagementUrl } from "../../../../config";
import {
  generateAndExecuteMutation,
  graphqlQuery,
} from "../../../../data/Axios/DynamicService";
import {
  allTransactionKeywords,
  allTransactionType,
} from "../../../../data/Queries/SetupManagement/FinSightsQueries";
import DynamicForm from "../../../../data/Axios/DynamicForm";

const base_url = setupManagementUrl.uri;

const TransactionKeywordsForm = (props) => {
  const [transactionData, setTransactionData] = useState([]);

  const initialValues = {
    id: props.data ? props.data.id : "",
    keyWord: props.data ? props.data.keyWord : "",
    regexpression: props.data ? props.data.regexpression : "",
    transactionTypeId: props.data ? props.data.transactionTypeId : "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { keyWord, regexpression, transactionTypeId } = values;

      const mutationData = props.isEditing
        ? {
            id: props.data.id,
            keyWord,
            regexpression,
            transactionTypeId,
          }
        : {
            keyWord,
            regexpression,
            transactionTypeId,
          };

      const response = await generateAndExecuteMutation(
        props.isEditing
          ? "updateTransactionKeywords"
          : "createTransactionKeywords",
        mutationData,
        "keyword",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();

        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Transaction Keyword ${
            props.isEditing ? "updated" : "created"
          } successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }

    setSubmitting(false);
  };

  useEffect(() => {
    async function fetchAllData() {
      try {
        const transactionTypeResponse = await graphqlQuery(
          allTransactionType,
          base_url
        );

        if (transactionTypeResponse) {
          setTransactionData(transactionTypeResponse || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchAllData();
  }, []);

  const transactionTypeOptions = transactionData
    ? transactionData.map((data) => ({
        value: data.id,
        label: data.description,
      }))
    : [];

  const fields = [
    {
      name: "keyWord",
      label: "Keyword *",
      type: "text",
      gridColumn: "span 2",
      required: true,
    },
    {
      name: "regexpression",
      label: "Regular Expression *",
      type: "text",
      gridColumn: "span 2",
      required: true,
    },
    {
      name: "transactionTypeId",
      label: "Transaction Type*",
      type: "select",
      options: transactionTypeOptions,
      gridColumn: "span 2",
      required: true,
    },
  ];

  return (
    <DynamicForm
      initialValues={initialValues}
      fields={fields}
      onSubmit={handleSubmit}
      title={
        props.isEditing
          ? "Edit Transaction Keyword"
          : "Create Transaction Keyword"
      }
      subtitle={
        props.isEditing
          ? "Edit an Existing Transaction Keyword"
          : "Create a New Transaction Keyword"
      }
    />
  );
};

export default TransactionKeywordsForm;
