import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import logo from "../../../Assets/Images/LogoFinsight.png";
import { navItems } from "../../../components/LandingPage";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme } from "@emotion/react";

const Navbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="sticky"
      style={{ backgroundColor: colors.blueAccent[700] }}
    >
      <Toolbar>
        <img src={logo} alt="Logo" style={{ height: 40, marginRight: 10 }} />
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          FinSights
        </Typography>
        <div className="hidden lg:flex">
          {navItems.map((item, index) => (
            <Button key={index} color="inherit" href={item.href}>
              {item.label}
            </Button>
          ))}
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/login"
          >
            Sign In
          </Button>

          <Button
            variant="outlined"
           
            component={Link}
            to="/SignUp"
            sx={{
              marginLeft: "10px",
              color: "#000",
             backgroundColor: colors.greenAccent[200],
            }}
          >
            Sign Up
          </Button>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          className="lg:hidden"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {navItems.map((item, index) => (
            <MenuItem key={index} onClick={handleMenuClose} href={item.href}>
              {item.label}
            </MenuItem>
          ))}
          <MenuItem onClick={handleMenuClose} component={Link} to="/Login">
            Sign In
          </MenuItem>
          {/* <MenuItem onClick={handleMenuClose} href="#">
            Create an account
          </MenuItem> */}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
