import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Paper,
} from "@mui/material";
import { caseManagementUrl } from "../../../../config";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";

import {
  fetchDataEngine,
  generateAndExecuteMutation,
} from "../../../../data/Axios/DynamicService";
import { ChargeLinesColumns } from "../../../../data/DynamicTable/Collumns";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CustomizedSteppers from "./Stepper";
import EditIcon from "@mui/icons-material/Edit";
import { tokens } from "../../../../theme";
import AnchorTemporaryDrawer from "../../../../components/Drawer";
import ChargeLineForm from "./form";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Invoice from "./invoice";
import { MarkPaid } from "../../../../data/DynamicTable/Modal";
const base_url = caseManagementUrl.uri;

const ChargeLines = () => {
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const [isEditing, setIsEditing] = useState(false);
  const [EditData, SetEdetData] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [OpenModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [caseData, setCaseData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const invoiceRef = useRef();

  const handleOpenMarkPaidModal = () => {
  setOpenModal(true);
}
  const handleEdit = (id) => {
    const userToEdit = data.find((user) => user.id === id);
    setEditData(userToEdit);
    setIsEditing(true);
    setDrawerOpen(true);
    // if (isMobile) {
    //   setDialogOpen(true);
    // } else {
    //   setDrawerOpen(true);
    // }
  };

  const handleAddUser = () => {
    setIsEditing(false);
    setEditData(null);
    setDrawerOpen(true);
    // if (isMobile) {
    //   setDialogOpen(true);
    // } else {
    //   setDrawerOpen(true);
    // }
  };
  const steps = [
    "Quotation Open",
    "Quotation Close",
    "Invoice Open",
    "Invoice Close",
    "Receipt Open",
    "Receipt Close",
  ];

  const stepMapping = [0, 1, 2, 3, 4, 5]; // Mapping of processWorkFlow to step index

  useEffect(() => {
    if (
      caseData &&
      caseData.processWorkFlow >= 1 &&
      caseData.processWorkFlow <= 6
    ) {
      setActiveStep(stepMapping[caseData.processWorkFlow - 1]);
    }
  }, [caseData]);

  const handleSubmit = () => {
    const stepText = getStepText(activeStep);
    Swal.fire({
      title: "Submit Options",
      text: `Do you want to submit only or submit and convert the ${stepText}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Submit Only",
      cancelButtonText: "Submit and Convert",
      showDenyButton: true,
      denyButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Submit only
        const workflowFlag = getWorkflowFlag(activeStep, "submitOnly");
        updateProcessWorkflow(workflowFlag, "submitOnly");
      } else if (
        result.isDismissed &&
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // Submit and Convert
        const workflowFlag = getWorkflowFlag(activeStep, "submitAndConvert");
        updateProcessWorkflow(workflowFlag, "submitAndConvert");
      }
    });
  };

  const handleEditQuotation = () => {
    updateProcessWorkflow(1, "editQuotation"); // Set to Quotation Open without advancing stepper
    setActiveStep(0); // Move stepper to Quotation Open
  };

  const updateProcessWorkflow = async (workflowFlag, action) => {
    try {
      const mutationData = {
        caseId: caseId,
        newProcessWorkFlow: parseInt(workflowFlag),
      };
      const response = await generateAndExecuteMutation(
        "updateCaseProcessWorkFlow",
        mutationData,
        "workFlowInput",
        base_url
      );

      if (Object.values(response)[0]) {
        Swal.fire(
          "Success",
          "Process workflow updated successfully",
          "success"
        );

        if (action === "submitOnly") {
          setActiveStep((prevStep) => getNextStep(prevStep, "submitOnly"));
        } else if (action === "submitAndConvert") {
          setActiveStep((prevStep) =>
            getNextStep(prevStep, "submitAndConvert")
          );
        } else if (action === "editQuotation") {
          setActiveStep(0); // Move to Quotation Open
        }
      } else {
        Swal.fire(
          "Error",
          "There was an error updating the process workflow",
          "error"
        );
      }
    } catch (error) {
      console.error("Error updating process workflow:", error);
      Swal.fire(
        "Error",
        "There was an error updating the process workflow",
        "error"
      );
    }
  };

  useEffect(() => {
    async function fetchAllData() {
      try {
        const returnFields = ["id", "processWorkFlow"];
        const inputFields = { id: caseId };
        const Facts = await fetchDataEngine(
          "casebyId",
          "caseModel",
          inputFields,
          returnFields,
          base_url
        );
        setCaseData(Facts);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);

  async function fetchData() {
    try {
      const returnFields = ["id", "description", "price", "processWorkFlow"];
      const inputFields = { caseId: caseId };
      const Facts = await fetchDataEngine(
        "charges",
        "caseCategoryModel",
        inputFields,
        returnFields,
        base_url
      );
      setData(Facts);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setRefreshTable(true);
    fetchData();
    setRefreshTable(false);
  }, [refreshTable]);

  const getStepText = (step) => {
    switch (step) {
      case 0:
      case 1:
        return "quotation";
      case 2:
      case 3:
        return "invoice";
      case 4:
      case 5:
        return "receipt";
      default:
        return "";
    }
  };

  const getWorkflowFlag = (step, action) => {
    if (step === 0 || (step === 1 && action === "submitOnly")) {
      return 2;
    }
    // else if (step === 1 && action === "submitOnly" ) {
    //    return 2;
    // }
    else if (step === 2 || (step === 3 && action === "submitOnly")) {
      return 4;
    }
    // else if (step === 3 && action === "submitOnly")
    // {
    //   return 4;
    //   }
    else if (step === 4 && action === "submitOnly") {
      return 5;
    } else if (step === 0 || (step === 1 && action === "submitAndConvert")) {
      return 3;
    } else if (step === 2 || (step === 3 && action === "submitAndConvert")) {
      return 5;
    } else if (step === 4 || (step === 5 && action === "submitAndConvert")) {
      return 6;
    }
  };

  const getNextStep = (currentStep, action) => {
    const stepMapping = {
      submitOnly: {
        0: 1,
        1: 1,
        2: 3,
        3: 3,
        4: 5,
        5: 5,
      },
      submitAndConvert: {
        0: 2,
        1: 2,
        2: 4,
        3: 4,
        4: 6,
        5: 6,
      },
    };
    return stepMapping[action][currentStep];
  };

  const handleActionSelect = (event, id) => {
    const action = event.target.value;

    switch (action) {
      case "edit":
        handleEdit(id);
        break;

      default:
        break;
    }
  };
  const columns = [
    ...ChargeLinesColumns,
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row: { id, isActive } }) => {
        return (
          <Box>
            <Select
              value=""
              onChange={(e) => handleActionSelect(e, id)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Actions
              </MenuItem>
              {activeStep === 0 && (
                <MenuItem value="edit">
                  <EditIcon color={colors.grey[100]} />
                  <Typography variant="body1" color={colors.blueAccent[100]}>
                    Edit
                  </Typography>
                </MenuItem>
              )}
            </Select>
          </Box>
        );
      },
    },
  ];
  const handlePreviewInvoice = () => {
    setInvoiceModalOpen(true);
  };

  const convertToPDF = () => {
    html2canvas(invoiceRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("invoice.pdf");
      // Preview the converted PDF
      const pdfBlob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    });
  };
 const totalPrice = data.reduce((acc, item) => acc + item.price, 0);

 // Add the total row to the data
 const dataWithTotal = [
   ...data,
   { id: "Total", description: "Total", price: totalPrice },
 ];
  const handleCloseInvoiceModal = () => {
    setInvoiceModalOpen(false);
  };
  return (
    <Box m="2px">
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <PulseLoader size={15} color={"#3f51b5"} />
        </Box>
      ) : (
        <>
          <CustomizedSteppers
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
          <Box
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <Box display="d-flex" justifyContent="flex-end" mt={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colors.greenAccent[500],
                  borderRadius: "4px",
                  m: "10px",
                }}
                onClick={handleSubmit}
              >
                <Typography
                  style={{ color: colors.primary[400], margin: "0 8px" }}
                >
                  {activeStep === 0 || activeStep === 1
                    ? "Submit Quotation"
                    : activeStep === 2 || activeStep === 3
                    ? "Submit Invoice"
                    : activeStep === 4 || activeStep === 5
                    ? "Submit Receipt"
                    : "Submit"}
                </Typography>
              </Button>
              {activeStep === 3 && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.greenAccent[500],
                    borderRadius: "4px",
                    m: "10px",
                  }}
                  onClick={handleOpenMarkPaidModal}
                >
                  <Typography
                    style={{ color: colors.blueAccent[400], margin: "0 8px" }}
                  >
                    Mark Paid
                  </Typography>
                </Button>
              )}
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colors.greenAccent[500],
                    borderRadius: "4px",
                    m: "10px",
                  }}
                  onClick={handleEditQuotation}
                >
                  <Typography
                    style={{ color: colors.primary[400], margin: "0 8px" }}
                  >
                    Edit Quotation
                  </Typography>
                </Button>
              )}
              {activeStep === 0 && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: colors.greenAccent[500],
                    borderRadius: "4px",
                  }}
                  onClick={handleAddUser}
                >
                  <Typography
                    style={{ color: colors.primary[400], margin: "0 8px" }}
                  >
                    Add Charge Line
                  </Typography>
                </Button>
              )}

              <Button
                variant="contained"
                style={{
                  backgroundColor: colors.blueAccent[500],
                  borderRadius: "4px",
                }}
                onClick={handlePreviewInvoice}
              >
                <Typography
                  style={{ color: colors.primary[400], margin: "0 8px" }}
                >
                  {activeStep === 0 || activeStep === 1
                    ? "Preview Quotation"
                    : activeStep === 2 || activeStep === 3
                    ? "Preview Invoice"
                    : activeStep === 4 || activeStep === 5
                    ? "Preview Receipt"
                    : "Submit"}
                </Typography>
              </Button>

              {activeStep === 4 && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: colors.blueAccent[500],
                    borderRadius: "4px",
                  }}
                  //onClick={handleAddUser}
                >
                  <Typography
                    style={{ color: colors.primary[400], margin: "0 8px" }}
                  >
                    Submit Fee Note
                  </Typography>
                </Button>
              )}
            </Box>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
              open={invoiceModalOpen}
              onClose={handleCloseInvoiceModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={invoiceModalOpen}>
                <Paper
                  style={{
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows[5],
                    //  padding: theme.spacing(2, 4, 3),
                    maxHeight: "90vh",
                    overflowY: "auto",
                    maxWidth: "90vw",
                    width: "70%",
                  }}
                >
                  <Box p={2}>
                    {/* Replace with your Invoice component */}
                    <Typography variant="h6" gutterBottom>
                      Fee Note Preview
                    </Typography>
                    <div ref={invoiceRef}>
                      <Invoice
                        caseData={caseData}
                        chargeLines={data}
                        activeStep={activeStep}
                        convertToPdf={() => convertToPDF()}
                      />
                    </div>
                    {/* Example content */}
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: colors.blueAccent[500],
                        borderRadius: "4px",
                        m: "10px",
                      }}
                      onClick={convertToPDF}
                    >
                      <Typography
                        style={{ color: colors.primary[400], margin: "0 8px" }}
                      >
                        Convert & Download Fee Note
                      </Typography>
                    </Button>
                  </Box>
                </Paper>
              </Fade>
            </Modal>
            <DataGrid
              checkboxSelection
              rows={dataWithTotal}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              getRowClassName={(params) =>
                params.id === "toTotaltal" ? "total-row" : ""
              }
              sx={{
                "& .total-row": {
                  // backgroundColor: colors.blueAccent[500],
                  color: colors.greenAccent[500],
                  fontWeight: "bold",
                },
              }}
            />
            <MarkPaid
              open={OpenModal}
              onClose={() => setOpenModal(false)}
              onAction={() => setRefreshTable(true)}
            />
            <AnchorTemporaryDrawer
              anchor="right"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              FormComponent={() => (
                <ChargeLineForm
                  onClose={() => setDrawerOpen(false)}
                  isEditing={isEditing}
                  data={editData}
                  onAction={() => setRefreshTable(true)}
                />
              )}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ChargeLines;
