import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import { CREATEBANK, UPDATEBANK } from "../../../../data/SetupManagementData";

const BankForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

  const [createBank] = useMutation(CREATEBANK, {
    onCompleted: (data) => {
      props.onClose();
      props.onAction();
      swal("Success!", "Bank has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create bank, try again later", "error");
    },
  });

  const [updateBank] = useMutation(UPDATEBANK, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal("Success!", "Bank has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update bank, try again later", "error");
    },
  });

  const initialValues = {
    bankName: props.isEditing ? props.data.bankName : "",
  };

  const validationSchema = yup.object().shape({
    bankName: yup.string().required("Bank name is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { bankName } = values;
      const companyId = props.isEditing
        ? props.data.companyId
        : parseInt(decodedToken.CompanyId);

      if (props.isEditing) {
        await updateBank({
          variables: {
            id: props.data.id,
            bankName,
            // isActive: true,
            updatedBy: userId,
          },
        });
      } else {
        await createBank({
          variables: {
            bankName,
            // isActive: true,
            createdBy: userId,
          },
        });
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Bank" : "Create Bank"}
        subtitle={
          props.isEditing
            ? "Edit an Existing Bank Name"
            : "Create a New Bank Name"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Bank Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.bankName}
                name="bankName"
                error={touched.bankName && !!errors.bankName}
                helperText={touched.bankName && errors.bankName}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default BankForm;