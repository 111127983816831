import React from "react";
import {
  Box,
} from "@mui/material";
import CustomForm from "./forms";
import { MajorClassificationColumns } from "../../../data/DynamicTable/Collumns";
import { allReportsMajorClassifications } from "../../../data/Axios/queries";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import { setupManagementUrl } from "../../../config";

const base_url = setupManagementUrl.uri;

const MajorClassification = () => {

  const actions = {
    entity: "Reports Major Classification",
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: true,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: true,
      permission: "CreatePermission",
    },
    activateDeactivate: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Deactivate",
      Show_Button: true,
      permission: "ActivatePermission",
    },
    showStatus: true,
  };

  return (
    <Box m="10px">

      <DynamicTable
        title="Reports"
        subtitle="Major Classifications"
        columns={MajorClassificationColumns}
        FormComponent={CustomForm}
        query={allReportsMajorClassifications}
        base_url={base_url}
        actions={actions}

      />
    </Box>
  );
}

export default MajorClassification;