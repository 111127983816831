import React from "react";
import {
  Box,
  Button,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import { CREATE_JUDGE, UPDATE_JUDGE } from "../../../../data/SetupManagementData";

const JudgeForm = (props) => {
  
  const [createJudge] = useMutation(CREATE_JUDGE, {
    onCompleted: (data) => {
      props.onClose()
      swal("Success!", "Judge has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create judge, try again later", "error");
    },
  });

  const [updateJudge] = useMutation(UPDATE_JUDGE, {
    onCompleted: () => {
      props.onClose()
      swal("Success!", "Judge has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update judge, try again later", "error");
    },
  });

  const initialValues = {
    name: props.judgeData ? props.judgeData.name : "",
    
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    
  });
  
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { name, companyId, company } = values;
  
      if (props.isEditing) {
        await updateJudge({
          variables: {
            judgeId: props.judgeData.judgeId,
            name,
            
          },
        });
        console.log("Judge updated successfully!");
      } else {
        await createJudge({
          variables: {
            name,
            
          },
        });
        console.log("Judge created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  
    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Judge" : "Create Judge"}
        subtitle={
          props.isEditing ? "Edit an Existing Judge" : "Create a New Judge"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
              
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default JudgeForm;
