import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {
  CREATE_LEADSTATUS,
  UPDATE_LEADSTATUS,
  ALL_LEADSTATUSES
} from "../../../../data/SetupManagementData";

const LeadStatusForm = (props) => {
  const {
    loading,
    error,
    data,
  } = useQuery(ALL_LEADSTATUSES);

  const [createLeadStatus] = useMutation(CREATE_LEADSTATUS, {
    onCompleted: (data) => {
      props.onClose();
      props.onAction();
      swal("Success!", "Lead Status has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create lead status, try again later", "error");
    },
  });

  const [updateLeadStatus] = useMutation(UPDATE_LEADSTATUS, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal("Success!", "Lead Status has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update lead status, try again later", "error");
    },
  });

  const initialValues = {
    leadStatus: props.data ? props.data.leadStatus : "",
    
  };

  const validationSchema = yup.object().shape({
    leadStatus: yup.string().required("Lead Status is required"),
    
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { leadStatus } = values;

      if (props.isEditing) {
        await updateLeadStatus({
          variables: {
            id: props.data.id,
            leadStatus,
            
          },
        });
        console.log("Lead Status updated successfully!");
      } else {
        await createLeadStatus({
          variables: {
            leadStatus,
            
          },
        });
        console.log("Lead Status created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Lead Status" : "Create Lead Status"}
        subtitle={
          props.isEditing ? "Edit an Existing Lead Status" : "Create a New Lead Status"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Lead Status *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.leadStatus}
                name="leadStatus"
                error={touched.leadStatus && !!errors.leadStatus}
                helperText={touched.leadStatus && errors.leadStatus}
              />
             
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default LeadStatusForm;
