export const usersField = [
  {
    name: "firstName",
    label: "First Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "lastName",
    label: "Last Name *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  { 
    name: "email", 
    label: "Email *", 
    type: "text", 
    gridColumn: "span 2" },
  {
    name: "phone",
    label: "Phone *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
  {
    name: "username",
    label: "Username *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },

  {
    name: "idno",
    label: "National Id *",
    type: "text",
    gridColumn: "span 2",
    required: true,
  },
];
  
