import { Box, Typography, Button } from "@mui/material";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import {
  companyManagementUrl,
  emailService,
  setupManagementUrl,
} from "../../../config";

import { allAddOns, allAdhocEmails } from "../../../data/Axios/queries";

import { EmailsByCompanyColumns } from "../../../data/DynamicTable/AdhocColumns";
import { TextareaAutosize } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { format } from "date-fns";
import { CommunicationComponent, EmailComponent } from "../../../data/DynamicTable/Modal";
import { useState } from "react";


const email_base_url = emailService.uri;
const actions = {
  entity: "Adhoc Emails",
  isSubRight: true,
  edit: {
    key: "EDIT",
    button_name: "Edit",
    Show_Button: false,
    permission: "EditPermission",
  },
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: false,
    permission: "CreatePermission",
  },
  activateDeactivate: {
    key: "ACTIVATEDEACTIVATE",
    button_name: "Deactivate",
    Show_Button: false,
    permission: "ActivatePermission",
  },
  AssignTask: {
    key: "ASSIGN_TASK",
    button_name: "Assign Task",
    Show_Button: false,
    permission: "hasNoPermission",
    roleGroupId: [1, 2],
  },
  showStatus: true,
};
function AdhocEmails() {
  
  const [dialogOpen, setDialogOpen] = useState(false);

      const theme = useTheme();
  const colors = tokens(theme.palette.mode);
     const handleOpenModal = () => {
       
       setDialogOpen(true);
       
     };
  const MultilineTextCell = ({ value }) => {
    return (
      <TextareaAutosize
        aria-label="body content"
        minRows={3}
        style={{
          color: "white",
          width: "100%",
          resize: "none",
          border: "none",
          padding: "8px",
          backgroundColor: `${colors.primary[400]}`,
        }}
        value={value}
        readOnly
      />
    );
  };
  const columns = [
    ...EmailsByCompanyColumns,

    {
      field: "body",
      headerName: "Body",
      flex: 3,
      renderCell: (params) => <MultilineTextCell value={params.value} />,
    },
    { field: "sentDateTime", headerName: "Sent Date Time", flex: 1,
      renderCell:(params)=>{
        const formatDate= format(new Date(params.value), 'MM/dd/yyyy');
        return formatDate;
      }
     },
    { field: "status", headerName: "Status", flex: 1 },
  ];
  return (
    <Box m="2px">
      <>
        <Box
          m="2px"
          sx={{
            justifyContent: "end",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.greenAccent[500],
              borderRadius: "4px",
              m: "10px",
            }}
            onClick={handleOpenModal}
          >
            <Typography style={{ color: colors.primary[400], margin: "0 8px" }}>
              Compose Email
            </Typography>
          </Button>
        </Box>
        <DynamicTable
          title="Adhoc Emails"
          subtitle="Manage adhoc emails"
          columns={columns}
          // FormComponent={AddOnForm}
          query={allAdhocEmails}
          base_url={email_base_url}
          actions={actions}
          getRowId={(row) => row.messageId}
        />
        <CommunicationComponent
          communicationType="email"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        />
      </>
    </Box>
  );
}

export default AdhocEmails;
