import React, { useState, useEffect } from "react";
import { Container, Grid, Typography, Button } from "@mui/material";
import { FaArrowRight } from "react-icons/fa"; // Import the icon from react-icons
import TrackVisibility from "react-on-screen";
import "animate.css";
import image from "../../../Assets/Images/Dataanalytics.jpg";

const Hero = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(200 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [
    "Unlimited System Users",
    "Daily Automatic Data Backup",
    "Customizable Modules",
    "Role-based Privileges",
    "Security and Privacy",
    "Graphical Analysis",
    "Exportation of reports to PDF and Excel",
    "Access from Multiple Branches",
  ];
  const period = 200;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <section
      className="hero-section"
      style={{ height: "100vh", paddingTop: "20px", paddingBottom: "20px" }}
    >
      <Container>
        <Grid
          container
          spacing={4}
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Grid item xs={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{ fontWeight: "bold", fontSize: "3rem" }}
                  >
                    The comprehensive financial analytics tool for progressive FinTechs
                  </Typography>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      fontSize: "1.5rem",
                    }}
                  >
                    Aiming to enhance your practice? Join the numerous FinTechs
                    that trust FinSights to streamline and grow their
                    operations.
                  </Typography>
                  <Typography
                    variant="h4"
                    component="h1"
                    style={{ fontSize: "2.5rem" }}
                  >
                    <span
                      className="txt-rotate"
                      dataPeriod="200"
                      data-rotate='["Unlimited System Users", "Daily Automatic Data Backup", "Customizable Modules", "Role-based Privileges", "Security and Privacy", "Graphical Analysis", "Exportation of reports to PDF and Excel", "Access from Multiple Branches"]'
                    >
                      <span className="wrap">{text}</span>
                    </span>
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    href="#get-started"
                    style={{
                      marginTop: "20px",
                      padding: "12px 36px",
                      fontSize: "1.2rem",
                      transition: "transform 0.2s ease-in-out",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "translateY(-2px)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "translateY(0)")
                    }
                  >
                    Explore{" "}
                    <FaArrowRight size={25} style={{ marginLeft: "8px" }} />
                  </Button>
                </div>
              )}
            </TrackVisibility>
          </Grid>
          <Grid item xs={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <img
                    src={image}
                    alt="Hero"
                    className="hero-image"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              )}
            </TrackVisibility>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Hero;