import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import TrackVisibility from "react-on-screen";
import "animate.css";
import { checklistItems } from "../../../components/LandingPage";
import meeting from "../../../Assets/Images/CoreBusiness.jpg";

const Workflow = () => {
  return (
    <Container className="mt-20" id="workflow">
      <Typography mt={10} variant="h2" textAlign="center" mb={6}>
        Enhance Your Financial AnalyticsWorkflow{" "}
        <Box
          component="span"
          sx={{
            background: "linear-gradient(to right, #FFA726, #FB8C00)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          with Our Financial Analytics System
        </Box>
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TrackVisibility>
            {({ isVisible }) => (
              <div
                className={
                  isVisible ? "animate__animated animate__fadeInLeft" : ""
                }
              >
                <img
                  src={meeting}
                  alt="Financial AnalyticsWorkflow"
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              </div>
            )}
          </TrackVisibility>
        </Grid>
        <Grid item xs={12} md={6}>
          {checklistItems.map((item, index) => (
            <TrackVisibility key={index}>
              {({ isVisible }) => (
                <Box
                  display="flex"
                  alignItems="center"
                  mb={4}
                  className={
                    isVisible ? "animate__animated animate__fadeInRight" : ""
                  }
                >
                  <CheckCircle color="success" fontSize="large" />
                  <Box ml={2}>
                    <Typography variant="h6">{item.title}</Typography>
                    <Typography variant="body1" color="textSecondary">
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              )}
            </TrackVisibility>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Workflow;
