import {
  Grid,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import React from "react";
import { tokens } from "../../../theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const TransactionsTable = ({ data, summary }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const DisplayData = ({ data }) => {
    const dataArray = Object.entries(data);
    const rows = [];

    for (let i = 0; i < dataArray.length; i += 4) {
      rows.push(dataArray.slice(i, i + 4));
    }

    return (
      <List>
        {rows.map((row, rowIndex) => (
          <Grid container spacing={1} key={rowIndex}>
            {row.map(([key, value], index) => (
              <Grid item xs={3} key={index}>
                <ListItem>
                  <ListItemText
                    primary={key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                    secondary={value !== null ? value.toString() : "N/A"}
                  />
                </ListItem>
              </Grid>
            ))}
          </Grid>
        ))}
      </List>
    );
  };

  const tableStyles = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "10px",
    textAlign: "left",
    border: "1px solid #ddd",
  };

  const thStyles = {
    padding: "5px",
    borderBottom: "2px solid #ddd",
    fontWeight: "bold",
    fontSize: "12px", // Reduced font size
    width: "13%",
    color: colors.greenAccent[300],
    backgroundColor: colors.primary[300],
  };

  const tdStyles = {
    padding: "10px",
    border: "1px solid #ddd",
    fontSize: "12px", // Reduced font size
    width: "13%", // Set equal width for each column
  };

  const headerStyles = {
    padding: "10px",
    fontSize: "16px", // Reduced font size
    fontWeight: "bold",
    color: colors.greenAccent[600],
  };

  const noDataStyles = {
    padding: "5px",
    color: colors.redAccent[400],
    textAlign: "center",
  };

  const combinedData = data.map((transactionItem) => {
    const summaryItem = summary.find(
      (summaryItem) => summaryItem.name === transactionItem.name
    );

    return {
      type: transactionItem.name,
      transactions: transactionItem.transactionsData || [],
      summary: summaryItem ? summaryItem.summary : {},
    };
  });

  return (
    <div>
      {combinedData.length > 0 ? (
        combinedData.map(({ type, transactions, summary }, index) => (
          <Accordion
            key={index}
            defaultExpanded={index === 0}
            sx={{
              m: "5px",
              backgroundColor:
                theme.palette.mode === "dark" ? colors.primary[400] : "#fff",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography style={headerStyles}>{type}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
              <Grid item xs={12}>
                  <div
                    style={{
                      marginBottom: "10px",
                      backgroundColor: colors.primary[400],
                      height: "100%",
                    }}
                  >
                    <div className="card-header">
                      <h5 className="card-title" style={headerStyles}>
                        Summary
                      </h5>
                    </div>
                    <div className="card-body">
                      {summary && Object.keys(summary).length > 0 ? (
                        <DisplayData data={summary} />
                      ) : (
                        <div style={noDataStyles}>No data available</div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      marginBottom: "10px",
                      backgroundColor: colors.primary[400],
                      height: "100%",
                    }}
                  >
                    <div className="card-header">
                      <h5
                        className="card-title "
                        style={{
                          color: colors.greenAccent[600],
                          marginBottom: "10px",
                          padding: "10px"
                        }}
                      >
                        Top 10
                      </h5>
                    </div>
                    <div className="card-body">
                      <div style={{ marginBottom: "10px" }}>
                        <table style={tableStyles}>
                          <thead>
                            <tr>
                              <th style={thStyles}>#</th>
                              <th style={thStyles}>Account</th>
                              <th style={thStyles}>Names</th>
                              <th style={thStyles}>Count</th>
                              <th style={thStyles}>Highest</th>
                              <th style={thStyles}>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.length > 0 ? (
                              transactions.map((transaction, idx) => (
                                <tr key={idx}>
                                  <td style={tdStyles}>{idx + 1}</td>
                                  <td style={tdStyles}>
                                    {transaction.Account}
                                  </td>
                                  <td style={tdStyles}>{transaction.Names}</td>
                                  <td style={tdStyles}>{transaction.Count}</td>
                                  <td style={tdStyles}>
                                    {transaction.Highest}
                                  </td>
                                  <td style={tdStyles}>{transaction.Total}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6" style={noDataStyles}>
                                  No transaction data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Grid>
                
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <div style={noDataStyles}>No data available</div>
      )}
    </div>
  );
};

export const SummaryTable = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const tableStyles = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
    textAlign: "left",
    border: "1px solid #ddd",
  };

  const thStyles = {
    padding: "10px",
    borderBottom: "2px solid #ddd",
    fontWeight: "bold",
    color: colors.greenAccent[300],
    backgroundColor: colors.primary[300], // Example color
  };

  const tdStyles = {
    padding: "10px",
    border: "1px solid #ddd",
  };

  const noDataStyles = {
    padding: "10px",
    color: "#a0a0a0",
    textAlign: "center",
  };

  return (
    <div>
      <table style={tableStyles}>
        <thead>
          <tr>
            <th style={thStyles}>Transaction Type</th>
            <th style={thStyles}>Paid In</th>
            <th style={thStyles}>Paid Out</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                <td style={tdStyles}>{item.transactionType}</td>
                <td style={tdStyles}>{item.paidIn}</td>
                <td style={tdStyles}>{item.paidOut}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" style={noDataStyles}>
                No summary data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
