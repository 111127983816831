//import {format} from 'date-fns';

export const Modulecolumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "module", headerName: "Module", flex: 1 },
  { field: "company", headerName: "Company", flex: 1 },
];

export const RightsColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "title", headerName: "Title", flex: 1 },
  { field: "icon", headerName: "Icon", flex: 1 },
  { field: "action", headerName: "Action", flex: 1 },

  { field: "module", headerName: "Module", flex: 1 },

];

export const RoleGroupColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "roleGroup", headerName: "Role Group", flex: 1 },
];

export const CasesColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "clientType", headerName: "Client Type", flex: 1 },
  { field: "caseNumber", headerName: "Case Number", flex: 1 },
  { field: "title", headerName: "Title", flex: 1 },
  { field: "description", headerName: "Description", flex: 1 },
  {
    field: "categoryName",
    headerName: "Category Name",
    flex: 1,
  },
  {
    field: "subcategoryName",
    headerName: "Subcategory Name",
    flex: 1,
  },

  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "caseStatus",
    headerName: "Case Status",
    flex: 1,
  },
];

export const TeamAssignmentsColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "username", headerName: "Username", flex: 1 },
  { field: "firstName", headerName: "First Name", flex: 1 },
  { field: "lastName", headerName: "Last Name", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
  { field: "role", headerName: "Role", flex: 1 },

];

export const FactColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },

  { field: "factDescription", headerName: "Fact Description", flex: 2 },



];
export const CaseTasksColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  {
    field: "dueDate",
    headerName: "Due Date",
    flex: 1,
    type: "date",
    // renderCell: (params) => {
    //   const formattedDate = format(new Date(params.value), 'MM/dd/yyyy');
    //   return formattedDate;
    // }
  },
  // { field: "dueDate", headerName: "Due Date", flex: 1, type: "date" },
  { field: "description", headerName: "Description", flex: 1 },
  { field: "done", headerName: "Completed", flex: 0.5, type: "boolean" },
  // { field: "statusFlag", headerName: "Status Flag", flex: 1 },
];
export const CaseEventColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },

  { field: "description", headerName: "Event Description", flex: 1 },

];

export const JudgeAssignmentColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "name", headerName: "Name", flex: 1 },

];

export const DocumentUploadColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "caseTitle", headerName: "Case Title", flex: 2 },
  { field: "title", headerName: "Title", flex: 1 },
  { field: "caseNumber", headerName: "Case Number", flex: 1 },
  { field: "fileName", headerName: "File Name", flex: 1 },
  // { field: "firstName", headerName: "First Name", flex: 1 },
  // { field: "lastName", headerName: "Last Name", flex: 1 },
  // { field: "roleName", headerName: "Role Name", flex: 1 },
  // { field: "authorId", headerName: "Author ID", flex: 1 },
  // { field: "caseId", headerName: "Case ID", flex: 1 },
  {
    field: "createdDate",
    headerName: "Created Date",
    flex: 1,
    type: "date",
    // renderCell: (params) => {
    //   const formattedDate = format(new Date(params.value), 'MM/dd/yyyy');
    //   return formattedDate;
    // }
  },
  // { field: "lastModifiedDate", headerName: "Last Modified Date", flex: 1 },
  { field: "filePath", headerName: "File Path", flex: 1 },
  // { field: "companyId", headerName: "Company ID", flex: 1 },
  // { field: "deleted", headerName: "Deleted", flex: 1 },
  // { field: "deletedDate", headerName: "Deleted Date", flex: 1 },
  // { field: "company", headerName: "Company", flex: 1 },
];

export const CourtAssignmentsColumns = [
  { field: "id", headerName: "ID", flex: 1 },
  { field: "caseNumber", headerName: "Case Number", flex: 1 },
  { field: "caseTitle", headerName: "Case Title", flex: 1 },
  { field: "caseDescription", headerName: "Case Description", flex: 1 },
  { field: "court", headerName: "Court", flex: 1 },
  { field: "subCounty", headerName: "Sub-County", flex: 1 },
  { field: "county", headerName: "County", flex: 1 },

];

export const CorporateClientsColumns = [
  { field: "id", headerName: "Client ID", flex: 1 },
  { field: "contactNumber", headerName: "Contact Number", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
  { field: "address", headerName: "Address", flex: 1 },
  { field: "companyName", headerName: "Company Name", flex: 1 },
  { field: "industrySector", headerName: "Industry Sector", flex: 1 },
];

export const IndividualClientsColumns = [
  { field: "id", headerName: "Client ID", flex: 1 },
  { field: "firstName", headerName: "First Name", flex: 1 },
  { field: "lastName", headerName: "Last Name", flex: 1 },
  { field: "contactNumber", headerName: "Contact Number", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
];
export const ChargeLinesColumns = [
  { field: "id", headerName: " id", flex: 1 },
  { field: "description", headerName: "Description", flex: 1 },
  { field: "price", headerName: "Price", flex: 1 },
  //{ field: "processWorkFlow", headerName: "Process Work Flow", flex: 1 },
  //{ field: "email", headerName: "Email", flex: 1 },
];

export const AccountTypesColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "accountType", headerName: "Account Type", flex: 1 },
];

export const MajorClassificationColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "majorClassification", headerName: "MajorClassification", flex: 1 },
  // { field: "company", headerName: "Company", flex: 1 },
];
export const MinorClassificationColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "minorClassification", headerName: "Minor Classification", flex: 1 },
  // { field: "company", headerName: "Company", flex: 1 },
];
export const ReportTypesColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "majorClassification", headerName: "Major Classification", flex: 1 },
  { field: "minorClassification", headerName: "Minor Classification", flex: 1 },
  { field: "reportName", headerName: "Report Name", flex: 1 },
];
export const ReportBrowserColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "module", headerName: "Module", flex: 1 },
  { field: "company", headerName: "Company", flex: 1 },
];
export const ApiKeyColumns = [
  { field: "id", headerName: "ID", flex: 0.5 },
  { field: "apiKey", headerName: "Key", flex: 1 },
  // { field: "company", headerName: "Company", flex: 1 },
];