import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  caseManagementUrl,
  documentManagementUrl,
  emailService,
  smsservice,
} from "../../config";
import {
  generateAndExecuteMutation,
  fetchDataEngine,
  graphqlQuery,
  generateAndExecuteBulkMutation,
} from "../Axios/DynamicService";
import { Formik, Field } from "formik";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { CheckBox } from "@mui/icons-material";
import { allCaseStatus } from "../Axios/queries";
import {
  getDocumentAccessPermissions,
  getDocumentAccessPermissionsById,
} from "../Axios/Non-DynamicServices";
import { balanceAdding } from "./DynamicFunctions";

export function CommunicationComponent(props) {
  const { communicationType } = props; // email or sms
  const base_url = emailService.uri;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const validationSchema = yup.object().shape({
    subjectTitle:
      communicationType === "email"
        ? yup.string().required("Subject is required")
        : yup.string(),
    recipientEmail:
      communicationType === "email"
        ? yup
            .string()
            .email("Invalid email format")
            .required("Recipient email is required")
        : yup.string(),
    recipientNumber:
      communicationType === "sms"
        ? yup
            .string()
            .matches(/^[0-9]+$/, "Phone number must be digits only")
            .required("Recipient number is required")
        : yup.string(),
    body: yup.string().required("Body is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const mutationData =
        communicationType === "email"
          ? {
              subject: values.subjectTitle,
              recipientEmail: values.recipientEmail,
              body: values.body,
              messageId: 1,
            }
          : {
              phoneNumber: values.recipientNumber,
              body: values.body,
              messageId: 1,
            };
      const response = await generateAndExecuteMutation(
        communicationType === "email" ? "createEmailMessage" : "createMessage",
        mutationData,
        communicationType === "email" ? "email" : "smsmessage",
        communicationType === "email" ? base_url : smsservice.uri
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        //props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `${
            communicationType.charAt(0).toUpperCase() +
            communicationType.slice(1)
          } has been sent successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ color: colors.grey[100] }}>
          {communicationType === "email" ? "Send Email" : "Send SMS"}
        </DialogTitle>
        <Formik
          initialValues={{
            subjectTitle: "",
            recipientEmail: "",
            recipientNumber: "",
            body: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ color: colors.greenAccent[400] }}>
                  Fill in the details to send a {communicationType}.
                </DialogContentText>
                {communicationType === "email" && (
                  <>
                    <TextField
                      fullWidth
                      label="Subject Title"
                      name="subjectTitle"
                      variant="filled"
                      value={values.subjectTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.subjectTitle && !!errors.subjectTitle}
                      helperText={touched.subjectTitle && errors.subjectTitle}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      label="Recipient Email"
                      name="recipientEmail"
                      variant="filled"
                      value={values.recipientEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.recipientEmail && !!errors.recipientEmail}
                      helperText={
                        touched.recipientEmail && errors.recipientEmail
                      }
                      margin="normal"
                    />
                  </>
                )}
                {communicationType === "sms" && (
                  <TextField
                    fullWidth
                    label="Recipient Number"
                    name="recipientNumber"
                    variant="filled"
                    value={values.recipientNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.recipientNumber && !!errors.recipientNumber}
                    helperText={
                      touched.recipientNumber && errors.recipientNumber
                    }
                    margin="normal"
                  />
                )}
                <TextField
                  fullWidth
                  label="Body"
                  name="body"
                  variant="filled"
                  value={values.body}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.body && !!errors.body}
                  helperText={touched.body && errors.body}
                  margin="normal"
                  multiline
                  rows={4}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting
                    ? "Sending..."
                    : `Send ${
                        communicationType.charAt(0).toUpperCase() +
                        communicationType.slice(1)
                      }`}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export function AssignTaskModal(props) {
  const [usersData, setUsersData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const base_url = caseManagementUrl.uri;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  async function fetchData() {
    try {
      const returnFields = [
        "id",
        "username",
        "userId",
        "statusFlag",
        "role",
        "firstName",
        "lastName",
        "email",

        "assignedDate",
      ];
      const inputFields = {
        caseId: caseId,
      };
      const users = await fetchDataEngine(
        "teamAssignmentViewsByColumnValues",
        "viewModel",
        inputFields,
        returnFields,
        base_url
      );
      setUsersData(users);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const usersOptions = usersData
    ? usersData.map((data) => ({
        parent_key: data.userId,
        value: data.userId,
        label: data.username,
      }))
    : [];

  const validationSchema = yup.object().shape({
    userId: yup.string().required("User is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { userId } = values;
      const userIdFromSession = JSON.parse(
        localStorage.getItem("decodedToken")
      ).Id;

      const mutationData = {
        id: props.data.id,
        description: props.data.description,
        dueDate: props.data.dueDate,
        caseId: caseId,
        updatedBy: parseInt(userIdFromSession),
        asigneeId: userId,
      };

      const response = await generateAndExecuteMutation(
        "updateCaseTask",
        mutationData,
        "newCaseTask",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Task has been assigned successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ color: colors.grey[100] }}>Assign Task</DialogTitle>
        <Formik
          initialValues={{ userId: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ color: colors.greenAccent[400] }}>
                  Select a team member or members and assign the task to them.
                </DialogContentText>
                <Autocomplete
                  options={usersOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Users"
                      variant="filled"
                      onBlur={handleBlur}
                      error={touched.userId && !!errors.userId}
                      helperText={touched.userId && errors.userId}
                    />
                  )}
                  onChange={(event, newValue) => {
                    setFieldValue("userId", newValue ? newValue.value : "");
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting ? "Assigning..." : "Assign Task"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export function UpdateCaseStatusModal(props) {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const base_url = caseManagementUrl.uri;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  async function fetchData() {
    try {
      const response = await graphqlQuery(allCaseStatus, base_url);

      if (response) setData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const usersOptions = data
    ? data.map((data) => ({
        parent_key: data.id,
        value: data.id,
        label: data.status,
      }))
    : [];

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { statusId } = values;
      const userIdFromSession = JSON.parse(
        localStorage.getItem("decodedToken")
      ).Id;

      const mutationData = {
        caseId: props.data.id,
        flag: statusId,
      };

      const response = await generateAndExecuteMutation(
        "updateCaseStatus",
        mutationData,
        "statusParams",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Case status Updated Successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ color: colors.grey[100] }}>
          Update Case Status
        </DialogTitle>
        <Formik
          initialValues={{ statusId: props.data.statusFlag }}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ color: colors.greenAccent[400] }}>
                  Select a case status
                </DialogContentText>
                <Autocomplete
                  options={usersOptions}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="filled"
                      onBlur={handleBlur}
                      error={touched.statusId && !!errors.statusId}
                      helperText={touched.statusId && errors.statusId}
                    />
                  )}
                  onChange={(event, newValue) => {
                    setFieldValue("statusId", newValue ? newValue.value : "");
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting ? "updating..." : "Update Status"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
export function MarkPaid(props) {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const base_url = caseManagementUrl.uri;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const mutationData = {
        caseId: caseId,
        newProcessWorkFlow: 5,
        amount: parseInt(values.amount),
      };

      const response = await generateAndExecuteMutation(
        "updateCaseProcessWorkFlow",
        mutationData,
        "workFlowInput",
        base_url
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Invoice marked as paid successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ color: colors.grey[100] }}>Mark Paid</DialogTitle>
        <Formik
          initialValues={{ amount: "" }}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ color: colors.greenAccent[400] }}>
                  Enter Amount
                </DialogContentText>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Amount *"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  // error={touched.caseNumber && !!errors.caseNumber}
                  // helperText={touched.caseNumber && errors.caseNumber}
                  // sx={{ gridColumn: "span 2" }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting ? "Marking Paid..." : "Mark Paid"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
export function DocumentAccessModal(props) {
  const [documentAccess, setDocumentAccess] = React.useState([]);
  const [documentById, setDocumentById] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const documentUrl = documentManagementUrl.uri;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const cleanJsonString = (jsonString) => {
    const parsedData = JSON.parse(jsonString);
    const cleanUsers = parsedData.users.map((user) => ({
      ...user,
      permissions: JSON.parse(user.permissions),
    }));
    return { ...parsedData, users: cleanUsers };
  };

  const fetchDocumentData = async () => {
    const documentId = props.data.id;
    try {
      const data = await getDocumentAccessPermissions(documentId, documentUrl);
      const cleanedData = JSON.parse(data.replace(/\\/g, ""));
      setDocumentAccess(cleanedData.users);

      const dataById = await getDocumentAccessPermissionsById(
        documentId,
        documentUrl
      );
      const cleanedDataById = cleanJsonString(dataById);
      setDocumentById(cleanedDataById.users);
    } catch (error) {
      console.error("Error fetching document data:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDocumentData();
  }, []);

  const validationSchema = yup.object().shape({
    permissions: yup.object().required("Permissions are required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const mutationData = Object.keys(values.permissions).map((userId) => ({
        documentId: props.data.id,
        userId: parseInt(userId),
        readRight: values.permissions[userId].ReadRight,
        downloadRight: values.permissions[userId].DownloadRight,
        editRight: values.permissions[userId].EditRight,
        deleteRight: values.permissions[userId].DeleteRight,
        companyId: 6,
        active: false,
        company: null,
      }));

      const response = await generateAndExecuteBulkMutation(
        "createAccessBulk",
        "accessList",
        mutationData,

        documentManagementUrl.uri
      );

      if (Object.values(response)[0] != null) {
        props.onClose();
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Document rights have been assigned successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  const initialValues = {
    permissions: documentAccess.reduce((acc, user) => {
      const userDoc = documentById.find((doc) => doc.userId === user.userId);
      acc[user.userId] = userDoc
        ? userDoc.permissions
        : {
            ReadRight: false,
            DownloadRight: false,
            EditRight: false,
            DeleteRight: false,
          };
      return acc;
    }, {}),
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ color: colors.grey[100] }}>
          Assign Permissions
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ color: colors.greenAccent[400] }}>
                  Assign Privileges to team members
                </DialogContentText>
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      m: "auto",
                      width: "fit-content",
                    }}
                  >
                    {documentAccess.map((user) => (
                      <div key={user.userId}>
                        <Typography color={colors.greenAccent[400]}>
                          {user.userId}
                        </Typography>
                        {[
                          "ReadRight",
                          "EditRight",
                          "DeleteRight",
                          "DownloadRight",
                        ].map((right) => (
                          <FormControlLabel
                            key={right}
                            ml={2}
                            control={
                              <Checkbox
                                checked={
                                  values.permissions[user.userId]
                                    ? values.permissions[user.userId][right]
                                    : false
                                }
                                onChange={(event) => {
                                  setFieldValue(
                                    `permissions.${user.userId}.${right}`,
                                    event.target.checked
                                  );
                                }}
                                name={`permissions.${user.userId}.${right}`}
                                color="primary"
                              />
                            }
                            label={`Can ${right.replace("Right", "")}`}
                          />
                        ))}
                      </div>
                    ))}
                  </Box>
                </>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting ? "Assigning..." : "Assign Access"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
export function BalanceTopup(props) {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const phoneInitialValues = parseInt(decodedToken.Phone);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const base_url = "https://fsbe.mcb.co.ke/FinsightsGateway/STKPushAttempt";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await balanceAdding(
        values.amount,
  values.phone.toString()
      );

      if (response.status === 200) {
      
        props.onAction();
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Message sent successfully.`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
    setSubmitting(false);
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle sx={{ color: colors.grey[100] }}>
          Enter phone and amount you wish to pay
        </DialogTitle>
        <Formik
          initialValues={{ amount: "", phone: phoneInitialValues }}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText sx={{ color: colors.greenAccent[400] }}>
                  Enter Phone and Amount
                </DialogContentText>
                <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Amount *"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.amount}
                  name="amount"
                  // error={touched.caseNumber && !!errors.caseNumber}
                  // helperText={touched.caseNumber && errors.caseNumber}
                  sx={{ gridColumn: "span 2", marginBottom: "10px" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Phone *"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  name="phone"
                  // error={touched.caseNumber && !!errors.caseNumber}
                  // helperText={touched.caseNumber && errors.caseNumber}
                  sx={{ gridColumn: "span 2" }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={isSubmitting}
                  color="success"
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
