import React from "react";
import {
  Box,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Edit as EditIcon,
  Block as BlockIcon,
  CheckCircleOutline,
  PlusOne,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { gql, useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import DynamicTable from "../../../data/DynamicTable/dynamicTable";
import {
  ApiKeyColumns,
  RightsColumns,
} from "../../../data/DynamicTable/Collumns";
import { allApiKey } from "../../../data/Axios/queries";
import { userManagementUrl } from "../../../config";

const base_url = userManagementUrl.uri;

const ApiKey = () => {
  const actions = {
    entity: "API Key",
    showStatus: true,
    edit: {
      key: "EDIT",
      button_name: "Edit",
      Show_Button: false,
      permission: "EditPermission",
    },
    add: {
      key: "ADD",
      button_name: "Add",
      Show_Button: false,
      permission: "CreatePermission",
    },
    genericButton: {
      key: "btn",
      button_name: "Generate API Key",
      Show_Button: true,
      permission: "CreatePermission",
      mutation: `mutation GenerateAPiKey {
    generateAPiKey {
        id
        userId
        apiKey
        createdDate
        createdBy
        deleted
        deletedBy
        deletedDate
    }
}
`,
    },
    DeleteApiKey: {
      key: "ACTIVATEDEACTIVATE",
      button_name: "Delete",
      Show_Button: true,
      permission: "hasNoPermission",
      roleGroupId: [1, 2, 3, 4],
    },
  };

  return (
    <Box m="20px">
      <DynamicTable
        title="Api Key Management"
        subtitle="Manage Api Key"
        columns={ApiKeyColumns}
        // FormComponent={ApiKeyForm}
        query={allApiKey}
        base_url={base_url}
        actions={actions}
      />
    </Box>
  );
};

export default ApiKey;
