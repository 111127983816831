import { Box } from "@mui/material";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import DynamicTable from "../../../../data/DynamicTable/dynamicTable";
import { documentManagementUrl } from "../../../../config";
import DocumentUploadForm from "./Form";
import { DocumentUploadColumns } from "../../../../data/DynamicTable/Collumns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataEngineCustomized } from "../../../../data/Axios/DynamicService";
import { PulseLoader } from "react-spinners";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { tokens } from "../../../../theme";
import { useTheme } from "@mui/material";
import { UpdateDataToStore } from "../../../../store/Actions/CustomAction";

const base_url = documentManagementUrl.uri;

const actions = {
  entity: "Document Uploads",
  isSubRight: true,
  add: {
    key: "ADD",
    button_name: "Add",
    Show_Button: true,
    permission: "CreatePermission",
  },
  edit: {
    key: "EDIT",
    button_name: "Edit A",
    Show_Button: false,
    permission: "CreatePermission",
  },

  viewDocument: {
    key: "VIEW_DOCUMENT",
    button_name: "Open Document",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  documentAccess: {
    key: "VIEW_DOCUMENT",
    button_name: "Manage Document Permissions",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
  deleteDocument: {
    key: "DELETE_DOCUMENT",
    button_name: "Delete Document",
    Show_Button: true,
    permission: "hasNoPermission",
    roleGroupId: [1, 2, 3, 4],
  },
 
};

function DocumentUploads() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const caseId = JSON.parse(localStorage.getItem("CaseId"));
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.custom.customData.caseManagementModel.documentUploads);

  const [viewState, setViewState] = useState({
    viewTable: true,
    viewDocument: false,
    docs: [
      {
        uri: "",
        // url: "",
        // fileType: "",
        // fileName: ""
      },
    ],
    loadingState: null,
  });


  useEffect(() => {
    if (storeData.currentDocument) {
      // console.log('store data', storeData)
      setViewState({
        viewTable: false,
        viewDocument: true,
        docs: [
          {
            uri: `${storeData.currentDocument}`,
            // url: `${storeData}`,
            // fileType: "docx",
            // fileName: "Document.docx"
          },
        ],
      });
    } else {
      setViewState({
        viewTable: true,
        viewDocument: false,
        docs: null,
      });
    }
  }, [storeData.currentDocument]);

  const handleClose = () => {
    const actionKey = 'UPDATE_ACTIVE_DOCUMENT'
    dispatch(UpdateDataToStore(actionKey, null));
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchData() {
    try {
      const returnFields = [
        "id",
        "caseTitle",
        "title",
        "caseNumber",
        "fileName",
        "firstName",
        "lastName",
        "roleName",
        "authorId",
        "caseId",
        "createdDate",
        "lastModifiedDate",
        "filePath",
        "companyId",
        "deleted",
        "deletedDate",
       
      ];

      const inputFields = {
        caseId: caseId,
      };

      // Uncomment the following line to fetch data from API
      const documentData = await fetchDataEngineCustomized(
        "DocumentsByUserAndCase",
        "documentsByCase",
        inputFields,
        returnFields,
        base_url
      );

      // // Using static data for demonstration
      if (documentData) {
        setData(documentData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (storeData?.loadingState !== 'error') fetchData().then(setViewState({ ...viewState, loadingState: storeData.loadingState }));
  }, [storeData?.loadingState]);

  const token = localStorage.getItem("token");
  // const formattedToken = `${token?.replace(/"/g, "")}`;
  const formattedToken = `Bearer ${token?.replace(/"/g, "")}`;
  console.log(viewState.loadingState)

  const headers = {
    Authorization: formattedToken,
  };

  return (
    <Box m="2px">
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <PulseLoader size={10} color={"#3f51b5"} />
        </Box>
      ) : viewState.viewTable ? (
        <DynamicTable
          title="Document Uploads"
          subtitle="Manage all documents related to the case"
          columns={DocumentUploadColumns}
          FormComponent={DocumentUploadForm}
          DataFromGetBy={data}
          actions={actions}
          loadingState={viewState.loadingState}
        />
      ) : viewState.viewDocument ? (
        <Box>
          <Box display="flex" justifyContent="end" my="0.15rem">
            <Button
              type="button"
              color="warning"
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </Box>
          <DocViewer
            documents={viewState?.docs}
            pluginRenderers={DocViewerRenderers}
            prefetchMethod="GET"
            requestHeaders={headers}
            style={{ height: 1000, width: "100%" }}
            theme={{
              primary: "#454545",
              secondary: "#7E7E7E",
              tertiary: "#DADADA",
              textPrimary: "#ffffff",
              textSecondary: "#5296d8",
              textTertiary: "#00000099",
              disableThemeScrollbar: false,
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
}

export default DocumentUploads;
