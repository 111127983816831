import React from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
      <title>
        Guaranteed Success with FinSights Credit Score Prediction - Optimize
        Financial Decisions
      </title>
      <meta
        name="description"
        content={
          "Discover how FinSights' Credit Score Prediction tool helps you make better financial decisions when giving out loans. Ensure success with accurate credit assessments."
        }
      />
      <link rel="canonical" href={"https://finsights.mcb.co.ke/"} />
      <meta charSet={"utf-8"} />
      <meta
        name="keywords"
        content={
          "Finsights, credit score prediction, loan decisions, financial analysis, MCB Technologies"
        }
      />

      {/* Additional SEO Meta Tags */}
      <meta name="author" content="MCB Technologies" />
      <meta name="robots" content="index,follow" />
      <meta
        property="og:title"
        content="Guaranteed Success with FinSights Credit Score Prediction"
      />
      <meta
        property="og:description"
        content="Optimize your financial decisions with FinSights' accurate credit score predictions."
      />
      <meta property="og:url" content="https://finsights.mcb.co.ke/" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://finsights.mcb.co.ke/assets/finisights-preview.png"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Guaranteed Success with FinSights Credit Score Prediction"
      />
      <meta
        name="twitter:description"
        content="Optimize your financial decisions with FinSights' accurate credit score predictions."
      />
      <meta
        name="twitter:image"
        content="https://finsights.mcb.co.ke/assets/finsights-preview.png"
      />
    </Helmet>
  );
};

export default Meta;