import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Stack,
  List,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import PieChart from "../../components/PieChart";
import GeographyChart from "../../components/GeographyChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import GavelIcon from "@mui/icons-material/Gavel";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Cases from "../CaseManagement/Case";
import { useState, useEffect } from "react";
import { fetchDataEngine, graphqlQuery } from "../../data/Axios/DynamicService";
import {
  caseTaskForDashboard,
  mainDashboardQuery,
  recentCaseTasks,
  userRecentStatements,
} from "../../data/Axios/queries";
import {
  caseManagementUrl,
  companyManagementUrl,
  reportManagementUrl,
} from "../../config";
import MainCard from "../../components/MainCard";
import OrderTable from "./OrdersTable";
import WorkIcon from "@mui/icons-material/Work";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import LineChart from "../../components/BarChart";
const base_url = reportManagementUrl.uri;

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState({});
  const [task, setTasks] = useState([]);
  const [recentStatements, setRecentStatements] = useState([]);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const base_url = reportManagementUrl.uri;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        const inputFields = { roleGroupId: parseInt(decodedToken.RoleGroupId) };
        const returnFields = [
          "team",
          "numberOfUploadedStatements",
          "numberOfNewUsers",
          "numberOfActiveUsers",
          "numberOfMajorReports",
          "numberOfMinorReports",
          "numberOfReportTypes",
          "numberOfNewUploadedStatements",
          "userGrowthRatePercentage",
          "userChurnRate",
          "uploadedStatementstPercentage",
          "activeUserUploadratePercentage",
          "averageStatementsPeruser",
        ];

        const response = await fetchDataEngine(
          "uSerSummary",
          "functionParams",
          inputFields,
          returnFields,
          base_url
        );

        console.log("Fetched Data:", response); // Check the structure of the response
        setData(response[0]); // Update state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSummaryData();
  }, []);
  
  return (
    <>
      <Grid container rowSpacing={2.5} columnSpacing={2.75} padding={"1rem"}>
        {/* row 1 */}
        <Grid item xs={12} sx={{ mb: -2.25 }}>
          <Header title="Dashboard" subtitle="Welcome to FinSights Dashboard" />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Link
            href="/upload-statements"
            style={{ textDecoration: "none", color: "inherit" }}
            // onclick={() => navigate("/Cases")}
          >
            <Box
              gridColumn="span 4"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="0.5rem"
              padding="15px"
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
              <StatBox
                title={data.numberOfUploadedStatements}
                subtitle="Uploaded Statements"
                progress="0.75"
                increase={`+${data.uploadedStatementstPercentage || 0}%`}
                icon={
                  <WorkHistoryIcon
                    sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                  />
                }
              />
            </Box>
          </Link>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={3}>
          <Link
            href="/users"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="0.5rem"
              padding="15px"
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
              <StatBox
                title={data[0].numberOfNewUsers}
                subtitle="New Users"
                progress="0.50"
                increase={`+${data[0].userGrowthRatePercentage || 0}%`}
                icon={
                  <GroupAddIcon
                    sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                  />
                }
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: isSmallScreen ? "0.75rem" : "1rem",
                  },
                }}
              />
            </Box>
          </Link>
        </Grid> */}
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Link
            href="/users"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              gridColumn="span 4"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="0.5rem"
              padding="15px"
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            >
              <StatBox
                title={data.team}
                subtitle="Users"
                progress="0.30"
                increase={`+${data.userGrowthRatePercentage || 0}%`}
                icon={
                  <GroupAddIcon
                    sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                  />
                }
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: isSmallScreen ? "0.75rem" : "1rem",
                  },
                }}
              />
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Box
            gridColumn="span 4"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="0.5rem"
            padding="15px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <StatBox
              title={`${data.activeUserUploadratePercentage || 0}%`}
              subtitle="Rate of Uploads Today"
              progress="0.80"
              // increase={`+${data.averageStatementsPeruser || 0}%`}
              icon={
                <GroupAddIcon
                  sx={{ color: colors.greenAccent[400], fontSize: "26px" }}
                />
              }
              sx={{
                "& .MuiTypography-root": {
                  fontSize: isSmallScreen ? "0.75rem" : "1rem",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          md={8}
          sx={{ display: { sm: "none", md: "block", lg: "none" } }}
        />
        {/* row 2 */}
        <Grid item xs={12} md={7} lg={8}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Statement Overview</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <Box
            sx={{ mt: 1 }}
            height="400px"
            backgroundColor={colors.primary[400]}
            borderRadius="0.5rem"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          >
            <LineChart />
            {/* <LineChart /> */}
          </Box>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Executive Summary</Typography>
            </Grid>
          </Grid>
          <MainCard sx={{ mt: 1, height: "400px" }} content={false}>
            <Box sx={{ p: 3, pb: 0 }}>
              <List sx={{ p: 0, "& .MuiListItemButton-root": { py: 2 } }}>
                <ListItemButton divider>
                  <ListItemText primary="Number of Major reports" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.numberOfMajorReports}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="Minor Reports" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.numberOfMinorReports}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="Reports Type" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.numberOfReportTypes}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="Active Users" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.numberOfActiveUsers}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="New Users" />
                  <Typography variant="h5" sx={{ marginRight: "1rem" }}>
                    {data.numberOfNewUsers}
                  </Typography>
                </ListItemButton>
                <ListItemButton divider>
                  <ListItemText primary="User Churn Rate" />
                  <Typography sx={{ marginRight: "1rem" }}>
                    {data.userChurnRate}
                  </Typography>
                </ListItemButton>
              </List>
            </Box>
          </MainCard>
        </Grid>

        {/* row 3 */}
        <Grid item xs={12} md={6} lg={6} height="400px">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Recent Statements</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <MainCard sx={{ mt: 2 }} content={false}>
            <OrderTable />
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {/* <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Grid item>
              <Typography variant="h5">Document Storage</Typography>
            </Grid>
            <Grid item />
          </Grid> */}
          {/* <Grid container rowSpacing={2.5} columnSpacing={2.75}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Link
                href="/Cases"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="0.5rem"
                  padding="15px"
                  boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                >
                  <StatBox
                    title={data.numberOfCases}
                    subtitle="Won"
                    progress="0.75"
                    increase={`+${data.courtCasePercentage || 0}%`}
                    icon={
                      <WorkHistoryIcon
                        sx={{
                          color: colors.greenAccent[400],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
              </Link>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Link
                href="/Cases"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="0.5rem"
                  padding="15px"
                  boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                >
                  <StatBox
                    title={data.numberOfCases}
                    subtitle="Lost"
                    progress="0.75"
                    increase={`+${data.courtCasePercentage || 0}%`}
                    icon={
                      <WorkHistoryIcon
                        sx={{
                          color: colors.greenAccent[400],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
              </Link>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Link
                href="/Cases"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box
                  gridColumn="span 3"
                  backgroundColor={colors.primary[400]}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="0.5rem"
                  padding="15px"
                  boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
                >
                  <StatBox
                    title={data.numberOfCases}
                    subtitle="Dropped"
                    progress="0.75"
                    increase={`+${data.courtCasePercentage || 0}%`}
                    icon={
                      <WorkHistoryIcon
                        sx={{
                          color: colors.greenAccent[400],
                          fontSize: "26px",
                        }}
                      />
                    }
                  />
                </Box>
              </Link>
            </Grid>
          </Grid> */}
          <MainCard sx={{ mt: 2 }} content={false}>
            <Box height={"400px"}>
              <PieChart />
              {/* <LineChart /> */}
            </Box>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
