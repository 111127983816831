import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  CircularProgress,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import AnchorTemporaryDrawer from "../../components/Drawer";
import {
  fetchDataEngine,
  generateAndExecuteMutation,
  graphqlFunction,
  graphqlMutation,
  graphqlQuery,
} from "../Axios/DynamicService";
import { useNavigate } from "react-router-dom";
import {
  DocumentAccessModal,
  UpdateCaseStatusModal,
  AssignTaskModal,
} from "./Modal";
import { useDispatch } from "react-redux";
import { UpdateDataToStore } from "../../store/Actions/CustomAction";
import { Card, CardBody } from "react-bootstrap";
import usePermissions from "../RBAC/PermissionsHook";
import useSubRightPermissionsHook from "../RBAC/SubRightPermissionHook";
import { documentManagementUrl } from "../../config";
import { deleteDocument, markTaskDone } from "./DynamicFunctions";
import Swal from "sweetalert2";
import SearchIcon from "@mui/icons-material/Search";

const base_file_path = documentManagementUrl.baseFilePath;

const DynamicTable = ({
  title,
  subtitle,
  columns,
  FormComponent,
  query,
  base_url,
  actions,
  DataFromGetBy,
  searchTitle,
  loadingState,
  getRowId,
  isFunction,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);
  const dispatch = useDispatch();
  const { hasPermission } = usePermissions();
  const { hasSubRightPermission } = useSubRightPermissionsHook();
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const RoleGroupId = parseInt(decodedToken.RoleGroupId);
  const [updateCaseStatus, setUpdateCaseStatusModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [assignDocumentAccessOpen, setAssignDocumentAccessOpen] =
    useState(false);
  const handleMarkTaskDone = async (id) => {
    try {
      const response = await markTaskDone(id);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Task marked as done successfully`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Unable to mark the task as done, try again later.",
        });
      }
      // Example: log the response
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error marking task as done:", error);
    }
  };

  const handleDeleteDocument = async (fileName, documentId) => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this document? This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        const response = await deleteDocument(fileName, documentId);
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `Document deleted successfully.`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Unable to delete the document, try again later.",
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title: "Cancelled",
          text: "The document is safe.",
        });
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };
  const handleActivateDeactivate = async (isActive, id) => {
    try {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: `Do you want to ${
          isActive === true || isActive === 1 ? "deactivate" : "activate"
        }   ${actions.entity}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${
          isActive === true || isActive === 1 ? "deactivate" : "activate"
        } it!`,
        cancelButtonText: "No, keep it",
      });

      if (confirmation.isConfirmed) {
        let mutationData = null;
        if (actions.modelName) {
          mutationData = {
            modelName: actions.modelName,
            isActive:
              isActive === false
                ? true
                : false || isActive === 0
                ? true
                : false,
            id,
          };
        } else {
          mutationData = {
            ids: [id],
            isActive:
              isActive === false
                ? true
                : false || isActive === 0
                ? true
                : false,
            userId: userId,
          };
        }
        const response = await generateAndExecuteMutation(
          actions.activateDeactivate.inputObjectName,
          mutationData,
          actions.activateDeactivate.inputSubObjectName,
          base_url
        );

        if (Object.keys(response)[0]) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: `${actions.entity} ${
              isActive === true ? "deactivated" : "activated"
            } successfully.`,
          });
          // Refresh table after the operation
          setRefreshTable(true);
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  };

  async function fetchData() {
    try {
      const caseIdFromSession = JSON.parse(localStorage.getItem("CaseId"));
      let inputFields = "";
      if (actions.dataObject.inputFields) {
        inputFields = actions.dataObject.inputFields;
      } else {
        inputFields = {
          caseId: caseIdFromSession,
        };
      }

      if (actions.dataObject) {
        const data = await fetchDataEngine(
          actions.dataObject.inputObjectName,
          actions.dataObject.inputSubObjectName,
          inputFields,
          actions.dataObject.returnFields,
          base_url
        );
        if (data) {
          const totalItems = data.length;
          localStorage.setItem("totalItems", JSON.stringify(totalItems));
          setData(data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    let isMounted = true;

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);
  useEffect(() => {
    if (refreshTable) {
      fetchData();
      setRefreshTable(false);
    }
  }, [refreshTable]);
  async function fetchAllData() {
    try {
      if (query && base_url) {
        const data = await graphqlQuery(query, base_url);
        if (data !== null) {
          setData(data);
        }
      } else {
        setData(DataFromGetBy);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
  useEffect(() => {
    let isMounted = true;
    fetchAllData();
    return () => {
      isMounted = false;
    };
  }, [query, base_url]);

  useEffect(() => {
    if (refreshTable) {
      fetchAllData();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  const filterActionsByRole = (action) => {
    if (actions[action].permission === "hasNoPermission") {
      return actions[action].roleGroupId.includes(RoleGroupId);
    } else if (actions.isSubRight) {
      return hasSubRightPermission(actions.entity, actions[action].permission);
    } else {
      return hasPermission(actions.entity, actions[action].permission);
    }
  };

  const columnsWithActions = [
    ...columns.map((column) => {
      if (column.field === "id") {
        return {
          ...column,
          renderCell: (params) => params.api.getRowIndex(params.row.id) + 1,
        };
      }
      return column;
    }),
    ...(actions.showStatus !== true
      ? [
          {
            field: "isActive",
            headerName: "Status",
            flex: 0.5,
            renderCell: ({ row: { isActive } }) => {
              const statusText =
                isActive === true || isActive === 1 ? "Active" : "Inactive";
              const statusColor =
                isActive === true || isActive === 1 ? "green" : "red";
              return (
                <Typography variant="body1" style={{ color: statusColor }}>
                  {statusText}
                </Typography>
              );
            },
          },
        ]
      : []),
    ...(actions.isUploadingData === true
      ? [
          {
            field: "isActive",
            headerName: "Status",
            flex: 0.5,
            renderCell: ({ row: { workDone, totalWork } }) => {
              const percentage = (workDone / totalWork) * 100;
              const statusText = `${percentage.toFixed(2)}%`;
              let statusColor;

              if (percentage > 1 && percentage < 100) {
                statusColor = "yellow";
              } else if (percentage === 100) {
                statusColor = "green";
              } else {
                statusColor = "red";
              }

              return (
                <Typography variant="body1" style={{ color: statusColor }}>
                  {statusText}
                </Typography>
              );
            },
          },
        ]
      : []),

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <Select
              value=""
              displayEmpty
              onChange={(e) => handleAction(e.target.value, row.id, row)}
            >
              <MenuItem value="" disabled>
                Actions
              </MenuItem>
              {Object.keys(actions)
                .filter(
                  (action) =>
                    action !== "add" &&
                    action !== "genericButton" &&
                    actions[action].Show_Button &&
                    filterActionsByRole(action)
                )
                .map((action) => {
                  if (action === "activateDeactivate") {
                    return (
                      <MenuItem
                        key={action}
                        value={action}
                        onClick={() =>
                          handleActivateDeactivate(row.isActive, row.id)
                        }
                      >
                        {row.isActive === true || row.isActive === 1
                          ? "Deactivate"
                          : "Activate"}
                      </MenuItem>
                    );
                  }
                  return (
                    actions[action] && (
                      <MenuItem key={actions[action].key} value={action}>
                        {actions[action].button_name}
                      </MenuItem>
                    )
                  );
                })}
            </Select>
          </Box>
        );
      },
    },
  ];

  const handleAdd = () => {
    setIsEditing(false);
    setEditData(null);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };
  const handleOpenTaskModal = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setTaskModalOpen(true);
    setEditData(itemToEdit);
  };
  const handleOpenUpdateCaseStatusModal = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setUpdateCaseStatusModalOpen(true);
    setEditData(itemToEdit);
  };
  const handleAssignDocumentAccessModal = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setAssignDocumentAccessOpen(true);
    setEditData(itemToEdit);
  };
  const handleEdit = (id) => {
    const itemToEdit = data.find((item) => item.id === id);
    setEditData(itemToEdit);
    setIsEditing(true);
    if (isMobile) {
      setDialogOpen(true);
    } else {
      setDrawerOpen(true);
    }
  };
  const handleGenerateApiKey = async () => {
    if (actions.genericButton) {
      const response = await graphqlMutation(
        actions.genericButton ? actions.genericButton.mutation : "",
        base_url
      );
      if (response.generateAPiKey) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Api Key generated successfully.`,
        });
        setRefreshTable(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Unable to complete your request at the moment, try again later.",
        });
      }
    }
  };
  const handleDeleteApiKey = async (row) => {
    if (actions.DeleteApiKey) {
      const mutationData = {
        apiKey: row.apiKey,
      };

      const response = await generateAndExecuteMutation(
        "deleteAPiKeyByKey",
        mutationData,
        "apiKey",
        base_url
      );
      if (response.deleteAPiKeyByKey) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Api Key has been deleted successfully.`,
        });
        setRefreshTable(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Unable to complete your request at the moment, try again later.",
        });
      }
    }
  };
  const handleAction = async (action, id, row) => {
    switch (action) {
      case "edit":
        handleEdit(id);
        break;
      case "add":
        handleAdd();
        break;
      case "genericButton":
        handleGenerateApiKey();
        break;
      case "DeleteApiKey":
        handleDeleteApiKey(row);
        break;
      case "documentAccess":
        handleAssignDocumentAccessModal(id);
        break;
      case "ViewCorporateClientCases":
        localStorage.setItem("CorporateclientData", JSON.stringify(row));
        navigate("/Corporate-Client-Cases");
        break;
      case "viewReport":
        localStorage.setItem("statementData", JSON.stringify(row));
        navigate("/view-report");
        break;
      case "viewBankReport":
        localStorage.setItem("statementData", JSON.stringify(row));
        navigate("/view-bank-report");
        break;
      case "ViewCase":
        localStorage.setItem("CaseId", JSON.stringify(id));
        navigate("/case-details");
        break;
      case "AssignTask":
        handleOpenTaskModal(id);
        break;
      case "updateCaseStatus":
        handleOpenUpdateCaseStatusModal(id);
        break;
      case "deleteDocument":
        handleDeleteDocument(row.filePath, id);
        break;
      case "markTask":
        await handleMarkTaskDone(id);
        break;
      case "activateDeactivate":
        handleActivateDeactivate(row.isActive, id);
        break;
      case "viewDocument":
        const filePath = `${base_file_path}&documentId=${row?.id}&file=${row?.filePath}`;
        const actionKey = "UPDATE_ACTIVE_DOCUMENT";
        dispatch(UpdateDataToStore(actionKey, filePath));
        break;
      default:
        break;
    }
  };
  const handleSearch = (input) => {
    setSearchTerm(input); // Update searchTerm state

    if (input.trim() !== "") {
      const filteredData = data.filter((row) =>
        columns.some((column) => {
          const cellValue = row[column.field];
          return (
            typeof cellValue === "string" &&
            cellValue.toLowerCase().includes(input.toLowerCase())
          );
        })
      );
      setData(filteredData);
    } else {
      setRefreshTable(true); // Reset the table when the search term is cleared
    }
  };

  return (
    <Box m="21px">
      <Box
        height="75vh"
        m="0px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Header title={title} subtitle={subtitle} />

          <Box display="flex" justifyContent="flex-end" mt={1}>
            <TextField
              variant="outlined"
              size="medium"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)} // Call handleSearch on input change
              placeholder={`Search ${title}`} // Use placeholder instead of label
              sx={{
                mr: 2,
                width: {
                  xs: "100%", // Full width on extra-small screens
                  sm: "100%", // 75% width on small screens
                  md: "100%", // 60% width on medium screens
                  lg: "400px", // 50% width on large screens
                  xl: "400px", // 40% width on extra-large screens
                },
                height: "40px",
                backgroundColor: colors.primary[400],
                borderRadius: "25px", // Add border radius
                "& .MuiOutlinedInput-root": {
                  paddingLeft: "40px", // Add padding to prevent overlap with the icon
                  "& fieldset": {
                    border: "none", // Remove the border
                  },
                },
                "& .MuiInputAdornment-root": {
                  position: "absolute", // Position the icon absolutely
                  left: "10px", // Position icon within the field
                },
                "& .MuiInputBase-input": {
                  textAlign: "left", // Align text to the left
                  padding: "12px 0", // Adjust padding to align the text vertically
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {actions.add &&
            actions.add.Show_Button &&
            (actions.isSubRight
              ? hasSubRightPermission(actions.entity, actions.add.permission)
              : hasPermission(actions.entity, actions.add.permission)) && (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: colors.greenAccent[500],
                    borderRadius: "4px",
                  }}
                  onClick={() => handleAction("add")}
                >
                  <Typography sx={{ color: colors.primary[400], mx: 1 }}>
                    {actions.add.button_name}
                  </Typography>
                </Button>
              </Box>
            )}
          {actions.genericButton &&
            actions.genericButton.Show_Button &&
            (actions.isSubRight
              ? hasSubRightPermission(
                  actions.entity,
                  actions.genericButton.permission
                )
              : hasPermission(
                  actions.entity,
                  actions.genericButton.permission
                )) && (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: colors.greenAccent[500],
                    borderRadius: "4px",
                  }}
                  onClick={() => handleAction("genericButton")}
                >
                  <Typography sx={{ color: colors.primary[400], mx: 1 }}>
                    {actions.genericButton.button_name}
                  </Typography>
                </Button>
              </Box>
            )}
        </Box>

        <DataGrid
          checkboxSelection
          rows={data || DataFromGetBy || []}
          columns={columnsWithActions}
          components={{ Toolbar: GridToolbar }}
          sx={{ minWidth: isMobile ? "auto" : "auto", width: "100%" }}
          getRowId={getRowId}
        />

        {taskModalOpen && (
          <AssignTaskModal
            data={editData}
            open={taskModalOpen}
            onClose={() => setTaskModalOpen(false)}
            onAction={() => setRefreshTable(true)}
          />
        )}
        {assignDocumentAccessOpen && (
          <DocumentAccessModal
            data={editData}
            open={assignDocumentAccessOpen}
            onClose={() => setAssignDocumentAccessOpen(false)}
            onAction={() => setRefreshTable(true)}
          />
        )}
        {updateCaseStatus && (
          <UpdateCaseStatusModal
            data={editData}
            open={updateCaseStatus}
            onClose={() => setUpdateCaseStatusModalOpen(false)}
            onAction={() => setRefreshTable(true)}
          />
        )}

        <AnchorTemporaryDrawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          FormComponent={() => (
            <FormComponent
              isEditing={isEditing}
              data={editData}
              onAction={() => setRefreshTable(true)}
              onClose={() => setDrawerOpen(false)}
              refetchData={() => setRefreshTable(true)}
            />
          )}
        />
        {isMobile && (
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            fullScreen={isMobile}
          >
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setDialogOpen(false)}
                aria-label="close"
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <FormComponent
                onClick={() => setDialogOpen(false)}
                isEditing={isEditing}
                courtData={editData}
              />
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </Box>
  );
};

export default DynamicTable;
