import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
  useTheme,
  Card,
} from "@mui/material";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../../components/Header";
import DynamicForm from "../../../data/Axios/DynamicForm";
import Swal from "sweetalert2";
import {
  allReportMinorClassifications,
  allReportsMajorClassifications,
  allReportTypes,
} from "../../../data/Axios/queries";
import {
  setupManagementUrl,
  reportManagementUrl,
  emailService,
} from "../../../config";
// import { generateAndExecuteMutation } from "../../../data/Axios/DynamicService";
import { generateReport } from "../../../data/DynamicTable/DynamicFunctions";
import { graphqlQuery } from "../../../data/Axios/DynamicService";

const Filter = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const base_url = reportManagementUrl.engine_uri;
  const base_url_2 = reportManagementUrl.fs_engine_uri;
  const base_url_3 = reportManagementUrl.fs2_engine_uri;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [major, setMajor] = useState([]);
  const [minor, setMinor] = useState([]);
  const [reportName, setReportName] = useState([]);
  const [filteredMinorOptions, setFilteredMinorOptions] = useState([]);
  const [filteredReportTypesOptions, setFilteredReportTypesOptions] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [reportTypes, setReportType] = useState([]);
  useEffect(() => {
    async function fetchAllData() {
      try {
        const Response = await graphqlQuery(
          allReportMinorClassifications,
          setupManagementUrl.uri
        );
        if (Response) {
          setData(Response);
        }
        const MajorResponse = await graphqlQuery(
          allReportsMajorClassifications,
          setupManagementUrl.uri
        );
        if (MajorResponse) {
          setMajor(MajorResponse);
        }
        const MinorResponse = await graphqlQuery(
          allReportMinorClassifications,
          setupManagementUrl.uri
        );
        if (MinorResponse) {
          setMinor(MinorResponse);
        }
        const ReportType = await graphqlQuery(
          allReportTypes,
          setupManagementUrl.uri
        );
        if (ReportType) {
          setReportType(ReportType);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchAllData();
  }, []);

  const majorOptions = major
    ? major.map((item) => ({
        parent_key: item.id,
        value: item.id,
        label: item.majorClassification,
      }))
    : [];

  const ReportNames = reportName
    ? reportName.map((item) => ({
        parent_key: item.id,
        value: item.reportName,
        label: item.reportName,
      }))
    : [];

  const fields = [
    {
      name: "majorClassificationID",
      label: "Major Classification",
      type: "select",
      options: majorOptions,
      required: true,
      parent: "main",
      gridColumn: "span 1",
    },
    {
      name: "minorClassificationID",
      label: "Minor Classification",
      type: "select",
      options: filteredMinorOptions,
      required: true,
      gridColumn: "span 1",
      parent: "main",
    },
    {
      name: "reportTypeID",
      label: "Report Type",
      type: "select",
      // type: "select",
      options: filteredReportTypesOptions,
      required: true,
      gridColumn: "span 1",
    },
    // {
    //   name: "statementId",
    //   label: "Statement Id",
    //   type: "text",
    //   required: true,
    //   gridColumn: "span 1",
    // },
  ];

  const initialValues = {
    majorClassificationID: props.data ? props.data.majorClassificationID : "",
    minorClassificationID: props.data ? props.data.minorClassificationID : "",
    reportName: props.data ? props.data.reportName : "",
  };

  const onFieldChange = async (field, value) => {
    let updatedOptions = {};

    if (field.name === "majorClassificationID") {
      // Filter minor classification options based on the selected major classification ID
      const filteredMinorOptions = minor
        .filter((item) => item.majorClassificationID === value)
        .map((item) => ({
          parent_key: item.majorClassificationID,
          value: item.id,
          label: item.minorClassification,
        }));

      // Clear report type options when major classification changes
      setFilteredReportTypesOptions([]);

      // Store filtered options in the updatedOptions object
      updatedOptions = {
        minorClassificationID: filteredMinorOptions,
      };

      // Update the state for filtered minor options
      setFilteredMinorOptions(filteredMinorOptions);
    }
    if (field.name === "minorClassificationID") {
      // Filter report types based on the selected minor classification ID
      const filteredReportTypesOptions = reportTypes
        .filter((item) => item.minorClassificationID === value)
        .map((item) => ({
          parent_key: item.minorClassificationID,
          value: item.id,
          label: item.reportName,
        }));

      // Store filtered options in the updatedOptions object
      updatedOptions = {
        ...updatedOptions,
        reportTypeID: filteredReportTypesOptions,
      };

      // Update the state for filtered report types
      setFilteredReportTypesOptions(filteredReportTypesOptions);
    }
    return updatedOptions;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { reportTypeID } = values;
      const ReportType =
        reportTypes.find((item) => item.id === reportTypeID)?.reportName || "";
  
      const mutationData = {
        ReportTypeId: reportTypeID,
        ReportType,
      };
  
      // Open a new tab immediately, which will not be blocked
      const newTab = window.open("", "_blank");
  
      // Write a simple loading message or animation to the newly opened tab
      newTab.document.write(`
        <html>
          <head>
            <title>Loading...</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
                background-color: #f0f0f0;
              }
              .loading {
                font-size: 24px;
                color: #333;
              }
            </style>
          </head>
          <body>
            <div class="loading">Loading, please wait...</div>
          </body>
        </html>
      `);
      newTab.document.close(); // Necessary to complete the document creation
  
      const response = await generateReport(
        mutationData,
        reportManagementUrl.generate_report_uri,
        setIsLoading
      );
  
      if (response.ok) {
        const htmlContent = await response.text();
  
        // Replace the loading message with the actual content
        newTab.document.open(); // Reopen the document to overwrite it
        newTab.document.write(htmlContent);
        newTab.document.close();
      } else {
        console.error("Error:", response.statusText);
        newTab.close(); // Close the tab if there's an error
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "An error occurred while processing your request. Please try again later.",
      });
    }
  
    setSubmitting(false);
  };
  

  return (
    <Card m="10px" sx={{ background: `${colors.primary[400]} !important` }}>
      <DynamicForm
        fields={fields}
        onSubmit={handleSubmit}
        title="Report Browser"
        subtitle="Please select the report parameters"
        initialValues={initialValues}
        onFieldChange={onFieldChange}
      />
    </Card>
  );
};

export default Filter;
