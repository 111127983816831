import React from "react";
import { Box, Button, TextField, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import Header from "../../../../components/Header";
import {
  CREATE_FACT_CATEGORY,
  UPDATE_FACT_CATEGORY,
  ALL_FACT_CATEGORIES,
} from "../../../../data/SetupManagementData";

const FactCategoryForm = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem("decodedToken"));
  const userId = parseInt(decodedToken.Id);

  const { loading, error, data } = useQuery(ALL_FACT_CATEGORIES);
  const [createFactCategory] = useMutation(CREATE_FACT_CATEGORY, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal("Success!", "Fact Category has been created successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to create fact category, try again later", "error");
    },
  });

  const [updateFactCategory] = useMutation(UPDATE_FACT_CATEGORY, {
    onCompleted: () => {
      props.onClose();
      props.onAction();
      swal("Success!", "Fact Category has been updated successfully", "success");
    },
    onError: () => {
      swal("Error!", "Unable to update fact category, try again later", "error");
    },
  });

  const initialValues = {
    categoryName: props.data ? props.data.categoryName : "",
  };

  const validationSchema = yup.object().shape({
    categoryName: yup.string().required("Category Name is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const { categoryName } = values;

      if (props.isEditing) {
        await updateFactCategory({
          variables: {
            categoryId: props.data.categoryId,
            categoryName,
          },
        });
        console.log("Fact Category updated successfully!");
      } else {
        await createFactCategory({
          variables: {
            categoryName,
          },
        });
        console.log("Fact Category created successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }

    setSubmitting(false);
  };

  return (
    <Box m="20px">
      <Header
        title={props.isEditing ? "Edit Fact Category" : "Create Fact Category"}
        subtitle={
          props.isEditing ? "Edit an Existing Fact Category" : "Create a New Fact Category"
        }
      />

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Category Name *"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.categoryName}
                name="categoryName"
                error={touched.categoryName && !!errors.categoryName}
                helperText={touched.categoryName && errors.categoryName}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default FactCategoryForm;
