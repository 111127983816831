import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners"; // Importing the loader
import { jwtDecode } from "jwt-decode";
import { loginCallApi, MCBSwitchCallApi } from "../../data/userData";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { MenuItem, Select } from "@mui/material";
import { graphqlQuery } from "../../data/Axios/DynamicService";
import { allCompanies } from "../../data/Axios/queries";
import { companyManagementUrl } from "../../config";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

function saveTokenToStorage(decodedToken) {
  localStorage.setItem("decodedToken", JSON.stringify(decodedToken)); // Example: Using local storage
}

async function saveDataToLocalStorage(response) {
  const token = response.data.token;
  localStorage.setItem("token", JSON.stringify(token));
  const expirationTime = new Date().getTime() + 5 * 60 * 60 * 1000;

  const filePath = response.data.companyLogoPath ? `${response.data.companyLogoPath}` : null;
  localStorage.setItem("filePath", filePath);

  localStorage.setItem("tokenExpiration", expirationTime);
  localStorage.setItem("rights", JSON.stringify(response.data.rights));
  const decodedToken = jwtDecode(token);
  console.log("Token", decodedToken);
  saveTokenToStorage(decodedToken);
}

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mcb.co.ke/">
        MCB Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function McbSwitch({ onLogin }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [companyData, setCompanyData] = React.useState([]);

  React.useEffect(() => {
    // Load saved credentials if "Remember Me" was checked
    const savedUsername = sessionStorage.getItem("username");
    const savedPassword = sessionStorage.getItem("password");
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);
  React.useEffect(() => {
    const fetchAllData = async () => {
      try {
        const companyResponse = await graphqlQuery(
          allCompanies,
          companyManagementUrl.uri
        );

        if (companyResponse) {
          setCompanyData(companyResponse || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false); // Adding a loading state

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when the request starts

    try {
      const response = await MCBSwitchCallApi(username);
      if (response.status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        localStorage.clear();
        handlePostLogin(response); // Call a separate function to handle the next steps
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    } finally {
      setLoading(false); // Set loading to false when the request is finished
    }
  };

  const handlePostLogin = (response) => {
    saveDataToLocalStorage(response);
    const rights = response.data.rights;
    const cleanedData = JSON.parse(rights.replace(/\\/g, ""));
    console.log(cleanedData, "cleanedData");
    localStorage.setItem("rightsforrbac", cleanedData);
    navigate("/super-admin-dashboard");
  };
  //backgroundColor: "rgba(255, 255, 255, 0.8)",
  const backgroundImage = require("../../Assets/Images/MenInSuits.jpg");
  const tokenFromSession = JSON.parse(localStorage.getItem("decodedToken"));
  return (
    <ThemeProvider theme={defaultTheme}>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            // backgroundImage: `url(${backgroundImage})`, // Ensure the correct path to your image
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            zIndex: 9999,
          }}
        >
          <PulseLoader size={15} color={"#3f51b5"} />
        </Box>
      )}
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "20px",
          // backgroundColor: "rgba(255, 255, 255, 0.8)",
          backgroundImage: `url(${backgroundImage})`, // Ensure the correct path to your image
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <CssBaseline />
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          elevation={6}
          square
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "20px",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h5">Change Company</Typography>
            <Typography
              fontStyle="italic"
              color={colors.redAccent[400]}
              variant="subtitle"
            >
              Current company is:{" "}
              <Box component="span" color={colors.greenAccent[400]}>
                {tokenFromSession.Company}
              </Box>
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <Select
                fullWidth
                variant="outlined"
                value={username}
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Company
                </MenuItem>
                {companyData?.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.companyName}
                  </MenuItem>
                ))}
              </Select>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading} // Disable the button while loading
              >
                Continue
              </Button>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
