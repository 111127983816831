export const CourtsColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "court", headerName: "Court", flex: 1 },
    ];

export const CourtTypeColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "courtType", headerName: "Court Type", flex: 1 },
    ];

export const GenderColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "gender", headerName: "Gender", flex: 1 },
    ];

export const identificationTypeColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "identificationType", headerName: "identification Type", flex: 1 },
    ];

export const JudgesColumns = [
    { field: "judgeId", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Judge Name", flex: 1 },
    ];

export const PhoneCodeColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "phoneCode", headerName: "Phone Code", flex: 1 },
    ];

export const TitlesColumns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "title", headerName: "Title", flex: 1 },
    ];